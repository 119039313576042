import {
	AdminresidgetReview,
	CheckStaff,
	DeleteBarber,
	DeleteBeauty,
	DeleteBubbletea,
	DeleteCarRepairs,
	DeleteCredit,
	DeleteEntertainment,
	DeleteFitness,
	DeleteMassage,
	DeleteMovie,
	DeleteOpenhour,
	DeletePackage,
	DeleteRestaurant,
	DeleteSupermarket,
	GetOpenHours,
	IdupBarber,
	IdupBeauty,
	IdupBubbletea,
	IdupCarRepairs,
	IdupCredit,
	IdupEntertainment,
	IdupFitness,
	IdupMassage,
	IdupMovie,
	IdupPackage,
	IdupRestaurant,
	IdupSupermarket,
	IdupUserlrs,
	NewCredit,
	NewOpenhour,
	NewPackage,
	ResidgetOrder,
	RsgetCredit,
	RsgetPackage,
	UpdateCreditPre,
	UpdateOpenhour,
	UpdatePackagePre,
	Upload,
} from '../../../../api/api';
import { Badge, Button, Card, Form, InputGroup, Modal, Table } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';

import BootstrapTable from 'react-bootstrap-table-next';
import Chip from '@material-ui/core/Chip';
import { Link } from 'react-router-dom';
import logo from '../../../../../images/blue-logo.jpg';
import paginationFactory from 'react-bootstrap-table2-paginator';

const Products = ({
	product: {
		name,
		address1,
		address2,
		gps,
		accountName,
		onlineTime,
		accountBSB,
		accountNum,
		accountBank,
		telorder,
		style,
		follow,
		state,
		pic,
		pic2,
		pic3,
		pic4,
		pic5,
		pic6,
		pic7,
		pic8,
		pic9,
		pic10,
		menu,
		dinnerMenu,
		drinkMenu,
		veganMenu,
		specialMenu,
		hide_keywords,
		price_level,
		google_rate,
		rate_number,
		promotion,
		// rulestel,
		id,
		tel,
		types,
		rules,
		keywords,
		nameen,
		styleen,
	},
}) => {
	// console.log({ product });
	const [show, setShow] = useState(false);
	const [show1, setShow1] = useState(false);

	const [content, setContent] = useState('');
	// const [keywords, setKeywords] = useState('');
	const [packageKeywords, setPackageKeywords] = useState('');

	const [creditKeywords, setCreditKeywords] = useState('');

	const [price, setPrice] = useState('');
	const [yprice, setYprice] = useState('');
	const [startTime, setStartTime] = useState('');
	const [endTime, setEndTime] = useState('');
	const [buyNumber, setBuyNumber] = useState('');
	const [numberLimit, setNumberLimit] = useState('');
	const [beizhu, setBeizhu] = useState('');
	const [pre_business, setPreBusiness] = useState('');
	const [pre1, setPre1] = useState('');
	const [pre2, setPre2] = useState('');
	const [pre3, setPre3] = useState('');
	const [pre4, setPre4] = useState('');
	const [credit, setCredit] = useState('');
	let path = window.location.pathname;
	path = path.split('/');
	path = path[path.length - 1];
	// console.log(path);
	const user = JSON.parse(localStorage.getItem('current_user') || '{}');

	// Delete store section
	async function handleDelete() {
		switch (types) {
			case '0':
				//console.log('restaurant, delete id', id);
				const removeRestaurant = await DeleteRestaurant(user.access_token, id);
				window.location.reload();

				break;
			case '1':
				//console.log('Beauty, delete id', id);
				const removeBeauty = await DeleteBeauty(user.access_token, id);
				window.location.reload();

				break;
			case '2':
				//console.log('entertainment, delete id', id);
				const removeEntertainment = await DeleteEntertainment(user.access_token, id);
				window.location.reload();

				break;
			case '3':
				//console.log('babble_tea, delete id', id);
				const removeBabbleTea = await DeleteBubbletea(user.access_token, id);
				window.location.reload();

				break;
			case '6':
				//console.log('babble_tea, delete id', id);
				const removeMovie = await DeleteMovie(user.access_token, id);
				window.location.reload();

				break;
			case '7':
				//console.log('babble_tea, delete id', id);
				const removeMassage = await DeleteMassage(user.access_token, id);
				window.location.reload();

				break;
			case '8':
				//console.log('babble_tea, delete id', id);
				const removeBarber = await DeleteBarber(user.access_token, id);
				window.location.reload();

				break;
			case '9':
				//console.log('babble_tea, delete id', id);
				const removeCarRepair = await DeleteCarRepairs(user.access_token, id);
				window.location.reload();

				break;
			case '10':
				//console.log('babble_tea, delete id', id);
				const removeSupermarket = await DeleteSupermarket(user.access_token, id);
				window.location.reload();

				break;
			case '11':
				//console.log('babble_tea, delete id', id);
				const removeFitness = await DeleteFitness(user.access_token, id);
				window.location.reload();

				break;

			// const remove1 = await DeleteBabbletea(user.access_token, id);
		}
	}

	const handleClose = () => setShow(false);
	const handleClose1 = () => setShow1(false);
	const handleShow = () => setShow(true);
	const handleShow1 = () => setShow1(true);
	const [showUpdate, setShowUpdate] = useState(false);
	const [showOpenHour, setShowOpenHour] = useState(false);
	const [showcredit, setShowCredit] = useState(false);

	//credit proportion section
	const [updateCreditPre1, setUpdateCreditPre1] = useState('0');
	const [updateCreditPre2, setUpdateCreditPre2] = useState('0');
	const [updateCreditPre3, setUpdateCreditPre3] = useState('0');
	const [updateCreditPre4, setUpdateCreditPre4] = useState('0');

	//
	//package proportion section
	const [updatePackagePre1, setupdatePackagePre1] = useState('0');
	const [updatePackagePre2, setupdatePackagePre2] = useState('0');
	const [updatePackagePre3, setupdatePackagePre3] = useState('0');
	const [updatePackagePre4, setupdatePackagePre4] = useState('0');

	const [showPackage, setShowPackage] = useState(false);
	// const [rulesEn, setRulesEn] = useState('');
	// const [rulesCn, setRulesCn] = useState('');
	const [openHour, setOpenHour] = useState();

	const handleShowUpdate = () => {
		setShowUpdate(true);

		var rule = JSON.parse(rules);
		if (rule) {
			setRulesChinese(rule[0]);
			setRulesEnglish(rule[1]);
		}
		// //console.log(rule);
	};
	const handleCloseUpdate = () => setShowUpdate(false);

	// Open Hours
	const handleShowOpenHours = () => {
		setShowOpenHour(true);
		async function openHours() {
			const openHour = await GetOpenHours(user.access_token, id, types);
			// //console.log(openHour.data);
			setOpenHour(openHour);
		}
		openHours();
	};
	const handleCloseOpenHours = () => setShowOpenHour(false);
	async function handleOpenHour() {
		const hour = await NewOpenhour(user.access_token, id, mon, tue, wed, thu, fri, sat, sun, other, types);
	}

	async function handleUpdateOpenHour(openHourId) {
		//console.log(openHourId);
		const hour = await UpdateOpenhour(
			user.access_token,
			openHourId,
			id,
			mon,
			tue,
			wed,
			thu,
			fri,
			sat,
			sun,
			other,
			types
		);
		window.location.reload();
	}
	async function handleDeleteOpenhour(id) {
		//console.log(id);
		const reomve = await DeleteOpenhour(user.access_token, id);
		// window.location.reload();
	}

	const [creditList, setCreditList] = useState([]);
	console.log(creditList);

	async function handleShowCredit() {
		const credits = await RsgetCredit(user.access_token, id, types);
		setCreditList(credits.data);
		setShowCredit(true);
	}

	const [packageList, setPackageList] = useState([]);
	console.log(packageList);

	async function handleShowPackage() {
		const credits = await RsgetPackage(user.access_token, id, types);
		setPackageList(credits.data);
		setShowPackage(true);
	}

	const [updateCreditName, setUpdateCreditName] = useState('');
	const [updateCreditPrice, setUpdateCreditPrice] = useState('');
	const [updateCredit, setUpdateCredit] = useState('');
	const [updateCreditContent, setUpdateCreditContent] = useState('');
	const [file, setFile] = useState(null);
	const [usedate, setUsedate] = useState('');

	async function handleUpdateCredit(item) {
		//console.log(item);
		const addPic = await Upload(user.access_token, file);
		console.log(addPic);
		const upCredit = await IdupCredit(
			user.access_token,
			item.id,
			item.resid,
			updateCreditName,
			updateCreditContent,
			item.keywords,
			updateCreditPrice,
			updateCredit,
			item.startTime,
			item.endTime,
			item.numberLimit,
			item.buyNumber,
			item.beizhu,
			//0为餐厅,1为娱乐,2为美容，3为奶茶
			types,
			item.pre_business,
			item.pre1,
			item.pre2,
			item.pre3,
			item.pre4,
			usedate,
			addPic.data.url
		);

		const upCreditPre = await UpdateCreditPre(
			user.access_token,
			item.id,
			updateCreditPre1,
			updateCreditPre2,
			updateCreditPre3,
			updateCreditPre4
		);

		window.location.reload();
	}

	const [updatePackageName, setUpdatePackageName] = useState('');
	const [updatePackageContent, setUpdatePackageContent] = useState('');
	const [updatePackagePrice, setUpdatePackagePrice] = useState('');
	const [updatePackageYprice, setUpdateYprice] = useState('');

	async function handleUpdatePackage(item) {
		//console.log(item);
		const addPic = await Upload(user.access_token, file);
		console.log(addPic);
		const upPackage = await IdupPackage(
			user.access_token,
			item.id,
			item.resid,
			updatePackageName,
			updatePackageContent,
			item.keywords,
			updatePackagePrice,
			updatePackageYprice,
			item.startTime,
			item.endTime,
			item.numberLimit,
			item.buyNumber,
			item.beizhu,
			//0为餐厅,1为娱乐,2为美容，3为奶茶
			types,
			item.pre_business,
			item.pre1,
			item.pre2,
			item.pre3,
			item.pre4,
			usedate,
			addPic.data.url
		);
		const upPackagePre = await UpdatePackagePre(
			user.access_token,
			item.id,
			updatePackagePre1,
			updatePackagePre2,
			updatePackagePre3,
			updatePackagePre4
		);

		window.location.reload();
	}

	const handleCloseCredit = () => {
		setShowCredit(false);
	};
	const handleClosePackage = () => {
		setShowPackage(false);
	};

	async function handleNewPackage() {
		//console.log('new');
		const addPic = await Upload(user.access_token, file);

		const newPackage = await NewPackage(
			user.access_token,
			id,
			packageName,
			content,
			packageKeywords,
			price,
			yprice,
			startTime,
			endTime,
			numberLimit,
			buyNumber,
			beizhu,
			//0为餐厅,1为娱乐,2为美容，3为奶茶
			types,
			pre_business,
			pre1,
			pre2,
			pre3,
			pre4,
			usedate,
			addPic.data.url
		);
		//console.log(newPackage);
		setShow(false);
		window.location.reload();
	}
	async function handleNewCredit() {
		//console.log('new');
		const addPic = await Upload(user.access_token, file);

		const newCredit = await NewCredit(
			user.access_token,
			id,
			creditName,
			content,
			creditKeywords,
			price,
			credit,
			startTime,
			endTime,
			numberLimit,
			buyNumber,
			beizhu,
			//0为餐厅,1为娱乐,2为美容，3为奶茶
			types,
			pre_business,
			pre1,
			pre2,
			pre3,
			pre4,
			usedate,
			addPic.data.url
		);

		//console.log(newCredit);
		setShow1(false);
		window.location.reload();
	}

	const [restaurant, setRestaurant] = useState([]);
	// const [show, setShow] = useState(false);
	const [storeName, setStoreName] = useState('');
	const [store_address1, setAddress1] = useState('');
	const [store_address2, setAddress2] = useState('');
	const [store_gps, setGps] = useState('');
	const [store_onlineTime, setOnlineTime] = useState('');
	const [store_keywords, setStoreKeywords] = useState('');
	const [store_tel1, setTel] = useState('');
	const [store_accountName, setAccountName] = useState('');
	const [store_accountBSB, setAccountBSB] = useState('');
	const [store_accountNum, setAccountNum] = useState('');
	const [store_accountBank, setAccountBank] = useState('');
	const [store_telorder, setTelorder] = useState('');
	const [resStyle, setResStyle] = useState('');
	const [store_follow, setFollow] = useState('');
	const [store_state, setState] = useState('');
	const [store_pic1, setPic1] = useState('');
	const [store_pic2, setPic2] = useState('');
	const [store_pic3, setPic3] = useState('');
	const [store_pic4, setPic4] = useState('');
	const [store_pic5, setPic5] = useState('');
	const [store_pic6, setPic6] = useState('');
	const [store_pic7, setPic7] = useState('');
	const [store_pic8, setPic8] = useState('');
	const [store_pic9, setPic9] = useState('');
	const [store_pic10, setPic10] = useState('');
	const [store_type, setType] = useState('');
	const [store_menu, setMenu] = useState('');
	const [store_dinnerMenu, setDinnerMenu] = useState('');
	const [store_drinkMenu, setDrinkMenu] = useState('');
	const [store_veganMenu, setVeganMenu] = useState('');
	const [store_specialMenu, setSpecialMenu] = useState('');
	const [store_hide_keywords, setHide_keywords] = useState('');
	const [store_promotion, setPromotion] = useState('');
	const [store_rules, setRules] = useState('');
	const [store_priceLevel, setPriceLevel] = useState('');
	const [store_googleRate, setGoogleRate] = useState('');
	const [store_rateNumber, setRateNumber] = useState('');
	const [creditName, setCreditName] = useState('');
	const [packageName, setPackageName] = useState('');
	const [mon, setMonday] = useState('');
	const [tue, setTuesday] = useState('');
	const [wed, setWednesday] = useState('');
	const [thu, setThursday] = useState('');

	const [fri, setFriday] = useState('');
	const [sat, setSaturday] = useState('');
	const [sun, setSunday] = useState('');
	const [other, setOthers] = useState('');

	const [chineseRules, setRulesChinese] = useState('');
	const [englishRules, setRulesEnglish] = useState('');
	const [storeNameEn, setStoreNameEn] = useState('');
	const [styleEn, setStyleEn] = useState('');

	var combine = JSON.stringify([chineseRules].concat([englishRules]));
	// console.log('combine', combine);

	async function handleUpdateStore() {
		console.log('add');
		// console.log(resid);
		switch (types) {
			case '0':
				console.log('restaurant, update id', id);
				const updateRes = await IdupRestaurant(
					user.access_token,
					id,
					storeName,

					store_address1,
					store_address2,
					store_gps,
					store_onlineTime,
					store_keywords,
					store_tel1,
					store_accountName,
					store_accountBSB,
					store_accountNum,
					store_accountBank,
					store_telorder,
					resStyle,
					store_follow,
					store_state,
					store_pic1,
					store_pic2,
					store_pic3,
					store_pic4,
					store_pic5,
					store_pic6,
					store_pic7,
					store_pic8,
					store_pic9,
					store_pic10,
					store_type,
					store_menu,
					store_dinnerMenu,
					store_drinkMenu,
					store_veganMenu,
					store_specialMenu,
					store_hide_keywords,

					store_priceLevel,
					store_googleRate,
					store_rateNumber,
					store_promotion,
					combine,
					storeNameEn,
					styleEn
				);
				setShowUpdate(false);
				window.location.reload();
				break;
			case '1':
				console.log('Beauty, update id', id);
				const updateBeauty = await IdupBeauty(
					user.access_token,
					id,
					storeName,

					store_address1,
					store_address2,
					store_gps,
					store_onlineTime,
					store_keywords,
					store_tel1,
					store_accountName,
					store_accountBSB,
					store_accountNum,
					store_accountBank,
					store_telorder,
					resStyle,
					store_follow,
					store_state,
					store_pic1,
					store_pic2,
					store_pic3,
					store_pic4,
					store_pic5,
					store_pic6,
					store_pic7,
					store_pic8,
					store_pic9,
					store_pic10,
					store_type,
					store_menu,
					store_dinnerMenu,
					store_drinkMenu,
					store_veganMenu,
					store_specialMenu,
					store_hide_keywords,
					store_promotion,
					combine,
					storeNameEn,
					styleEn
				);
				setShowUpdate(false);
				window.location.reload();
				break;
			case '2':
				console.log('entertainment, update id', id);
				const updateEnt = await IdupEntertainment(
					user.access_token,
					id,
					storeName,

					store_address1,
					store_address2,
					store_gps,
					store_onlineTime,
					store_keywords,
					store_tel1,
					store_accountName,
					store_accountBSB,
					store_accountNum,
					store_accountBank,
					store_telorder,
					resStyle,
					store_follow,
					store_state,
					store_pic1,
					store_pic2,
					store_pic3,
					store_pic4,
					store_pic5,
					store_pic6,
					store_pic7,
					store_pic8,
					store_pic9,
					store_pic10,
					store_type,
					store_menu,
					store_dinnerMenu,
					store_drinkMenu,
					store_veganMenu,
					store_specialMenu,
					store_hide_keywords,
					store_promotion,
					combine,
					storeNameEn,
					styleEn
				);
				setShowUpdate(false);
				window.location.reload();
				break;

			// update movie

			case '3':
				console.log('bubble_tea, update id', id);
				console.log(storeNameEn);
				const updateBubble = await IdupBubbletea(
					user.access_token,
					id,
					storeName,

					store_address1,
					store_address2,
					store_gps,
					store_onlineTime,
					store_keywords,
					store_tel1,
					store_accountName,
					store_accountBSB,
					store_accountNum,
					store_accountBank,
					store_telorder,
					resStyle,
					store_follow,
					store_state,
					store_pic1,
					store_pic2,
					store_pic3,
					store_pic4,
					store_pic5,
					store_pic6,
					store_pic7,
					store_pic8,
					store_pic9,
					store_pic10,
					store_type,
					store_menu,
					store_dinnerMenu,
					store_drinkMenu,
					store_veganMenu,
					store_specialMenu,
					store_hide_keywords,
					store_promotion,
					combine,
					storeNameEn,
					styleEn
				);
				setShowUpdate(false);
				window.location.reload();
				break;

			case '6':
				const updateMovie = await IdupMovie(
					user.access_token,
					id,
					storeName,

					store_address1,
					store_address2,
					store_gps,
					store_onlineTime,
					store_keywords,
					store_tel1,
					store_accountName,
					store_accountBSB,
					store_accountNum,
					store_accountBank,
					store_telorder,
					resStyle,
					store_follow,
					store_state,
					store_pic1,
					store_pic2,
					store_pic3,
					store_pic4,
					store_pic5,
					store_pic6,
					store_pic7,
					store_pic8,
					store_pic9,
					store_pic10,
					store_type,
					store_menu,
					store_dinnerMenu,
					store_drinkMenu,
					store_veganMenu,
					store_specialMenu,
					store_hide_keywords,
					store_promotion,
					combine,
					storeNameEn,
					styleEn
				);
				setShowUpdate(false);
				window.location.reload();
				break;
			//update massage
			case '7':
				console.log('Massage, update id', id);
				console.log(storeNameEn);
				const updateMassage = await IdupMassage(
					user.access_token,
					id,
					storeName,

					store_address1,
					store_address2,
					store_gps,
					store_onlineTime,
					store_keywords,
					store_tel1,
					store_accountName,
					store_accountBSB,
					store_accountNum,
					store_accountBank,
					store_telorder,
					resStyle,
					store_follow,
					store_state,
					store_pic1,
					store_pic2,
					store_pic3,
					store_pic4,
					store_pic5,
					store_pic6,
					store_pic7,
					store_pic8,
					store_pic9,
					store_pic10,
					store_type,
					store_menu,
					store_dinnerMenu,
					store_drinkMenu,
					store_veganMenu,
					store_specialMenu,
					store_hide_keywords,
					store_promotion,
					combine,
					storeNameEn,
					styleEn
				);
				setShowUpdate(false);
				window.location.reload();
				break;
			case '8':
				console.log('barber, update id', id);
				console.log(store_address2);
				const updateBarber = await IdupBarber(
					user.access_token,
					id,
					storeName,

					store_address1,
					store_address2,
					store_gps,
					store_onlineTime,
					store_keywords,
					store_tel1,
					store_accountName,
					store_accountBSB,
					store_accountNum,
					store_accountBank,
					store_telorder,
					resStyle,
					store_follow,
					store_state,
					store_pic1,
					store_pic2,
					store_pic3,
					store_pic4,
					store_pic5,
					store_pic6,
					store_pic7,
					store_pic8,
					store_pic9,
					store_pic10,
					store_type,
					store_menu,
					store_dinnerMenu,
					store_drinkMenu,
					store_veganMenu,
					store_specialMenu,
					store_hide_keywords,
					store_promotion,
					combine,
					storeNameEn,
					styleEn
				);
				setShowUpdate(false);
				window.location.reload();
				break;
			//IdupCarRepairs
			case '9':
				console.log('car repair, update id', id);
				console.log(storeNameEn);
				const updateCarRepairt = await IdupCarRepairs(
					user.access_token,
					id,
					storeName,

					store_address1,
					store_address2,
					store_gps,
					store_onlineTime,
					store_keywords,
					store_tel1,
					store_accountName,
					store_accountBSB,
					store_accountNum,
					store_accountBank,
					store_telorder,
					resStyle,
					store_follow,
					store_state,
					store_pic1,
					store_pic2,
					store_pic3,
					store_pic4,
					store_pic5,
					store_pic6,
					store_pic7,
					store_pic8,
					store_pic9,
					store_pic10,
					store_type,
					store_menu,
					store_dinnerMenu,
					store_drinkMenu,
					store_veganMenu,
					store_specialMenu,
					store_hide_keywords,
					store_promotion,
					combine,
					storeNameEn,
					styleEn
				);
				setShowUpdate(false);
				window.location.reload();
				break;
			//IdupSupermarket
			case '10':
				console.log('supermarket, update id', id);
				console.log(storeNameEn);
				const updateSupermarket = await IdupSupermarket(
					user.access_token,
					id,
					storeName,

					store_address1,
					store_address2,
					store_gps,
					store_onlineTime,
					store_keywords,
					store_tel1,
					store_accountName,
					store_accountBSB,
					store_accountNum,
					store_accountBank,
					store_telorder,
					resStyle,
					store_follow,
					store_state,
					store_pic1,
					store_pic2,
					store_pic3,
					store_pic4,
					store_pic5,
					store_pic6,
					store_pic7,
					store_pic8,
					store_pic9,
					store_pic10,
					store_type,
					store_menu,
					store_dinnerMenu,
					store_drinkMenu,
					store_veganMenu,
					store_specialMenu,
					store_hide_keywords,
					store_promotion,
					combine,
					storeNameEn,
					styleEn
				);
				setShowUpdate(false);
				window.location.reload();
				break;

			//Fitness
			case '11':
				console.log('Fitness, update id', id);
				console.log(storeNameEn);
				const updateFitness = await IdupFitness(
					user.access_token,
					id,
					storeName,

					store_address1,
					store_address2,
					store_gps,
					store_onlineTime,
					store_keywords,
					store_tel1,
					store_accountName,
					store_accountBSB,
					store_accountNum,
					store_accountBank,
					store_telorder,
					resStyle,
					store_follow,
					store_state,
					store_pic1,
					store_pic2,
					store_pic3,
					store_pic4,
					store_pic5,
					store_pic6,
					store_pic7,
					store_pic8,
					store_pic9,
					store_pic10,
					store_type,
					store_menu,
					store_dinnerMenu,
					store_drinkMenu,
					store_veganMenu,
					store_specialMenu,
					store_hide_keywords,
					store_promotion,
					combine,
					storeNameEn,
					styleEn
				);
				setShowUpdate(false);
				window.location.reload();
				break;

			// const remove1 = await DeleteBabbletea(user.access_token, id);
		}
	}

	async function handleDeletePackage(item) {
		const removePackage = await DeletePackage(user.access_token, item.id);
	}
	async function handleDeleteCredit(item) {
		const removeCredit = await DeleteCredit(user.access_token, item.id);
	}

	// console.log('-------openHours', openHour);
	//Authority
	const [email, setEmail] = useState('');
	// console.log('email', email);
	const [level, setLevel] = useState('');
	const [showAuthority, setShowAuthority] = useState(false);
	const handleShowAuthority = () => {
		setShowAuthority(true);
		//get staff list
		const getStaff = async () => {
			const data = await CheckStaff(user.access_token, id, null, types);
			console.log(data);
		};
		getStaff();
	};
	const handleCloseAuthority = () => setShowAuthority(false);

	const handleSetting = async () => {
		// console.log(id, types);
		const click = await IdupUserlrs(
			user.access_token,
			// user.id,
			email,
			level,
			id,
			types
		);
		// console.log(level);
		setLevel('');
		// console.log(click);
		if (click.data === 1) {
			window.location.reload();
		}
	};

	const [showReviews, setShowReviews] = useState(false);
	const [reviews, setReviews] = useState([]);
	const handleShowReviews = () => {
		setShowReviews(true);
		//get reviews
		// console.log("id", id, "types", types);
		const getReviews = async () => {
			const data = await AdminresidgetReview(user.access_token, id, types);
			if (Array.isArray(data.data)) {
				setReviews(data.data);
			}
			// console.log(data);
		};
		getReviews();
	};
	const handleCloseReviews = () => setShowReviews(false);

	const columns = [
		{ dataField: 'resid', text: 'Store ID' },
		{ dataField: 'content', text: 'Content' },
		{ dataField: 'timestamp', text: 'Time' },
		{ dataField: 'servicetype', text: 'Service Type' },
		{ dataField: 'userid', text: 'User ID' },
		{ dataField: 'rating', text: 'Rating' },
	];

	const options = {
		paginationSize: 4,
		pageStartIndex: 0,
		// alwaysShowAllBtns: true, // Always show next and previous button
		// withFirstAndLast: false, // Hide the going to First and Last page button
		hideSizePerPage: true, // Hide the sizePerPage dropdown always
		hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
		// firstPageText: "First",
		// prePageText: "Back",
		// nextPageText: "Next",
		// lastPageText: "Last",
		nextPageTitle: 'First page',
		prePageTitle: 'Pre page',
		firstPageTitle: 'Next page',
		lastPageTitle: 'Last page',
		// showTotal: true,
		// paginationTotalRenderer: customTotal,
		disablePageTitle: true,
		sizePerPage: 50,

		// onSizePerPage: 10,
	};
	console.log(file);

	//res get orders
	const [openOrders, setOpenOrders] = useState(false);
	const [resOrders, setResOrders] = useState([]);

	const handleResGetOrders = (id) => {
		setOpenOrders(true);
		const getOrders = async () => {
			const data = await ResidgetOrder(user.access_token, id);
			console.log('=> data', data.data.items);
			setResOrders(data.data.items);
		};
		getOrders();
	};

	const handleCloseOrders = () => setOpenOrders(false);
	console.log('content', resOrders.length > 1 && JSON.parse(resOrders[0].ordercontent));

	return (
		<div className='row w-100 '>
			<div className='card w-100'>
				<div className='card-body'>
					<div className='row m-b-30'>
						{/* <div class="card-header pt-0">
                        <div class="text-right close badge badge-danger">x</div>
                    </div> */}
						<div className='justify-content-left d-flex'>
							{/* <div class="w-100 text-right close">x</div> */}
							{pic && (
								<img
									className='img-fluid'
									style={{ width: '300px', height: '200px' }}
									src={pic}
									alt=''
								/>
							)}
							{!pic && (
								<img
									className='img-fluid'
									style={{ width: '300px', height: '200px' }}
									src={logo}
									alt=''
								/>
							)}

							{/* </div> */}
							<div className='col-md-7 col-xxl-12'>
								<div className='new-arrival-content position-relative mt-3 '>
									<h4>
										{name}
										{/* <Link to="/ecom-product-detail">{name}</Link> */}
									</h4>
									{/* {rating} */}
									{address1 && (
										<div className='new-arrival-content position-relative mt-3 mb-3'>
											<span>
												<p></p>address: {address1}{' '}
											</span>
											{tel && (
												<div className='new-arrival-content position-relative mt-3 mb-3'>
													<span>phone: {tel}</span>
												</div>
											)}
											{!tel && (
												<div className='new-arrival-content position-relative mt-3 mb-3'>
													<span>no phone</span>
												</div>
											)}
										</div>
									)}
									{!address1 && (
										<div className='new-arrival-content position-relative mt-3 mb-3'>
											<span>no address</span>
											{tel && (
												<div className='new-arrival-content position-relative mt-3 mb-3'>
													<span>phone: {tel}</span>
												</div>
											)}
											{!tel && (
												<div className='new-arrival-content position-relative mt-3 mb-3'>
													<span>no phone</span>
												</div>
											)}
										</div>
									)}
									{types == 0 && (
										<>
											<Button onClick={() => handleResGetOrders(id)}>Orders</Button>
											<Modal
												show={openOrders}
												onHide={handleCloseOrders}
												backdrop='static'
												keyboard={false}
												centered>
												<Modal.Header closeButton>
													<Modal.Title>Orders</Modal.Title>
												</Modal.Header>
												<Modal.Body>
													{resOrders.length > 1 &&
														resOrders
															?.filter(
																(x) => x.status === 'Paid' || x.status === 'Rejected'
															)
															?.map((item, index) => (
																<Card key={item + index}>
																	<p>name: {item.name}</p>
																	<div>
																		Order:{' '}
																		<Chip label={item.status} color='primary' />
																		{/* (Status: <strong>{item.status}</strong>) */}
																	</div>
																	<Table responsive>
																		<thead>
																			<tr>
																				<th>Date:{item.timestamp1}</th>
																				<th>
																					Total:
																					{(item.total / 100).toFixed(2)}
																				</th>
																			</tr>
																		</thead>
																		<tbody>
																			{item?.ordercontent &&
																				[
																					JSON.parse(item?.ordercontent) ||
																						{},
																				].map(
																					(i) =>
																						i.length > 0 &&
																						i?.map((x, index) => (
																							<tr key={x + index}>
																								<td>{x.name}</td>
																								<td>
																									$
																									{(
																										x.price1 / 100
																									).toFixed(2)}
																								</td>
																							</tr>
																						))
																				)}
																		</tbody>
																	</Table>
																</Card>
															))}
												</Modal.Body>
												<Modal.Footer>
													<Button variant='secondary' onClick={handleCloseOrders}>
														Close
													</Button>
												</Modal.Footer>
											</Modal>
										</>
									)}
								</div>
							</div>
						</div>
					</div>
					{/* </Link> */}
					<div>
						{/* <Button as='a' href='#' className='btn btn-primary ml-1 mt-4' onClick={handleShow}>
							New Package
						</Button> */}
						<Modal show={show} onHide={handleClose} backdrop='static' keyboard={false} centered>
							<Modal.Header closeButton>
								<Modal.Title>New Package</Modal.Title>
							</Modal.Header>
							<Modal.Body>
								<label htmlFor='name' className='text-black font-w600'>
									Name <span className='required'>*</span>
								</label>
								<input
									type='text'
									label='Name'
									className='form-control'
									name='name'
									placeholder='English name'
									onChange={(e) => setPackageName(e.target.value)}
								/>
								<label htmlFor='content' className='text-black font-w600'>
									content
								</label>
								<input
									type='text'
									label='content'
									className='form-control'
									// defaultValue="content"
									name='content'
									placeholder='Chinese name'
									onChange={(e) => setContent(e.target.value)}
								/>

								<label htmlFor='keywords' className='text-black font-w600'>
									keywords
								</label>
								<input
									type='text'
									label='keywords'
									className='form-control'
									defaultValue='keywords'
									name='keywords'
									onChange={(e) => setPackageKeywords(e.target.value)}
									placeholder='keywords'
								/>
								<label htmlFor='price' className='text-black font-w600'>
									price
								</label>
								<input
									type='text'
									label='price'
									className='form-control'
									// defaultValue="price"
									name='price'
									onChange={(e) => setPrice(e.target.value)}
									placeholder='price'
								/>
								<label htmlFor='yprice' className='text-black font-w600'>
									Original price
								</label>
								<input
									type='text'
									label='yprice'
									className='form-control'
									// defaultValue="yprice"
									name='Original price'
									onChange={(e) => setYprice(e.target.value)}
									placeholder='yprice'
								/>
								<label htmlFor='startTime' className='text-black font-w600'>
									startTime
								</label>
								<input
									type='text'
									label='startTime'
									className='form-control'
									// defaultValue="startTime"
									onChange={(e) => setStartTime(e.target.value)}
									name='startTime'
									placeholder='startTime'
								/>
								<label htmlFor='endTime' className='text-black font-w600'>
									endTime
								</label>
								<input
									type='text'
									label='endTime'
									onChange={(e) => setEndTime(e.target.value)}
									className='form-control'
									// defaultValue="endTime"
									name='endTime'
									placeholder='endTime'
								/>
								<label htmlFor='numberLimit' className='text-black font-w600'>
									numberLimit
								</label>
								<input
									type='text'
									label='numberLimit'
									onChange={(e) => setNumberLimit(e.target.value)}
									className='form-control'
									// defaultValue="numberLimit"
									name='numberLimit'
									placeholder='numberLimit'
								/>
								<label htmlFor='buyNumber' className='text-black font-w600'>
									buyNumber
								</label>
								<input
									type='text'
									label='buyNumber'
									onChange={(e) => setBuyNumber(e.target.value)}
									className='form-control'
									// defaultValue="buyNumber"
									name='buyNumber'
									placeholder='buyNumber'
								/>
								<label htmlFor='beizhu' className='text-black font-w600'>
									beizhu
								</label>
								<input
									type='text'
									label='beizhu'
									className='form-control'
									onChange={(e) => setBeizhu(e.target.value)}
									// defaultValue="beizhu"
									name='beizhu'
									placeholder='beizhu'
								/>
								{/* <label htmlFor="pre_business" className="text-black font-w600">
                                        Precentage of business
                                    </label>
                                    <input
                                        type="text"
                                        label="pre_business"
                                        className="form-control"
                                        onChange={(e) => setPreBusiness(e.target.value)}
                                        // defaultValue="pre_business"
                                        name="pre_business"
                                        placeholder="pre_business"
                                    /> */}
								<label htmlFor='pre1' className='text-black font-w600'>
									precentage 1
								</label>
								<input
									type='text'
									label='pre1'
									className='form-control'
									onChange={(e) => setPre1(e.target.value)}
									// defaultValue="pre1"
									name='pre1'
									placeholder='pre1'
								/>

								<label htmlFor='pre2' className='text-black font-w600'>
									precentage 2
								</label>
								<input
									type='text'
									label='pre2'
									onChange={(e) => setPre2(e.target.value)}
									className='form-control'
									// defaultValue="pre2"
									name='pre2'
									placeholder='pre2'
								/>
								<label htmlFor='pre3' className='text-black font-w600'>
									precentage 3
								</label>
								<input
									type='text'
									label='pre3'
									className='form-control'
									onChange={(e) => setPre3(e.target.value)}
									// defaultValue="pre3"
									name='pre3'
									placeholder='pre3'
								/>
								<label htmlFor='pre4' className='text-black font-w600'>
									precentage 4
								</label>
								<input
									type='text'
									label='pre4'
									className='form-control'
									onChange={(e) => setPre4(e.target.value)}
									// defaultValue="pre4"
									name='pre4'
									placeholder='pre4'
								/>
								<label htmlFor='pic' className='text-black font-w600'>
									pic
								</label>
								<input
									type='file'
									label='pic'
									className='form-control'
									onChange={(e) => setFile(e.target.files[0])}
									// defaultValue="pic"
									name='pic'
									placeholder='pic'
								/>
							</Modal.Body>
							<Modal.Footer>
								<Button variant='secondary' onClick={handleClose}>
									Close
								</Button>
								<Button variant='primary' onClick={handleNewPackage}>
									Add
								</Button>
							</Modal.Footer>
						</Modal>
						{/* <Button as='a' href='#' className='btn btn-primary ml-1 mt-4' onClick={handleShow1}>
							New Credit
						</Button> */}
						<Modal show={show1} onHide={handleClose1} backdrop='static' keyboard={false} centered>
							<Modal.Header closeButton>
								<Modal.Title>New Credit</Modal.Title>
							</Modal.Header>
							<Modal.Body>
								<label htmlFor='name' className='text-black font-w600'>
									Name <span className='required'>*</span>
								</label>
								<input
									type='text'
									label='Name'
									className='form-control'
									name='name'
									placeholder='English name'
									onChange={(e) => setCreditName(e.target.value)}
								/>
								<label htmlFor='content' className='text-black font-w600'>
									content
								</label>
								<input
									type='text'
									label='content'
									className='form-control'
									// defaultValue="content"
									name='content'
									placeholder='Chinese name'
									onChange={(e) => setContent(e.target.value)}
								/>

								<label htmlFor='keywords' className='text-black font-w600'>
									keywords
								</label>
								<input
									type='text'
									label='keywords'
									className='form-control'
									defaultValue='keywords'
									name='keywords'
									onChange={(e) => setCreditKeywords(e.target.value)}
									placeholder='keywords'
								/>
								<label htmlFor='price' className='text-black font-w600'>
									price
								</label>
								<input
									type='text'
									label='price'
									className='form-control'
									// defaultValue="price"
									name='price'
									onChange={(e) => setPrice(e.target.value)}
									placeholder='price'
								/>
								<label htmlFor='credit' className='text-black font-w600'>
									Credit
								</label>
								<input
									type='text'
									label='credit'
									className='form-control'
									// defaultValue="credit"
									name='credit'
									onChange={(e) => setCredit(e.target.value)}
									placeholder='credit'
								/>
								<label htmlFor='startTime' className='text-black font-w600'>
									startTime
								</label>
								<input
									type='text'
									label='startTime'
									className='form-control'
									// defaultValue="startTime"
									onChange={(e) => setStartTime(e.target.value)}
									name='startTime'
									placeholder='startTime'
								/>
								<label htmlFor='endTime' className='text-black font-w600'>
									endTime
								</label>
								<input
									type='text'
									label='endTime'
									onChange={(e) => setEndTime(e.target.value)}
									className='form-control'
									// defaultValue="endTime"
									name='endTime'
									placeholder='endTime'
								/>
								<label htmlFor='numberLimit' className='text-black font-w600'>
									numberLimit
								</label>
								<input
									type='text'
									label='numberLimit'
									onChange={(e) => setNumberLimit(e.target.value)}
									className='form-control'
									// defaultValue="numberLimit"
									name='numberLimit'
									placeholder='numberLimit'
								/>
								<label htmlFor='buyNumber' className='text-black font-w600'>
									buyNumber
								</label>
								<input
									type='text'
									label='buyNumber'
									onChange={(e) => setBuyNumber(e.target.value)}
									className='form-control'
									// defaultValue="buyNumber"
									name='buyNumber'
									placeholder='buyNumber'
								/>
								<label htmlFor='beizhu' className='text-black font-w600'>
									beizhu
								</label>
								<input
									type='text'
									label='beizhu'
									className='form-control'
									onChange={(e) => setBeizhu(e.target.value)}
									// defaultValue="beizhu"
									name='beizhu'
									placeholder='beizhu'
								/>
								{/* <label htmlFor="pre_business" className="text-black font-w600">
                                        Precentage of business
                                    </label>
                                    <input
                                        type="text"
                                        label="pre_business"
                                        className="form-control"
                                        onChange={(e) => setPreBusiness(e.target.value)}
                                        // defaultValue="pre_business"
                                        name="pre_business"
                                        placeholder="pre_business"
                                    /> */}
								<label htmlFor='pre1' className='text-black font-w600'>
									precentage 1
								</label>
								<input
									type='text'
									label='pre1'
									className='form-control'
									onChange={(e) => setPre1(e.target.value)}
									// defaultValue="pre1"
									name='pre1'
									placeholder='pre1'
								/>

								<label htmlFor='pre2' className='text-black font-w600'>
									precentage 2
								</label>
								<input
									type='text'
									label='pre2'
									onChange={(e) => setPre2(e.target.value)}
									className='form-control'
									// defaultValue="pre2"
									name='pre2'
									placeholder='pre2'
								/>
								<label htmlFor='pre3' className='text-black font-w600'>
									precentage 3
								</label>
								<input
									type='text'
									label='pre3'
									className='form-control'
									onChange={(e) => setPre3(e.target.value)}
									// defaultValue="pre3"
									name='pre3'
									placeholder='pre3'
								/>
								<label htmlFor='pre4' className='text-black font-w600'>
									precentage 4
								</label>
								<input
									type='text'
									label='pre4'
									className='form-control'
									onChange={(e) => setPre4(e.target.value)}
									// defaultValue="pre4"
									name='pre4'
									placeholder='pre4'
								/>
								<label htmlFor='pic' className='text-black font-w600'>
									pic
								</label>
								<input
									type='file'
									label='pic'
									className='form-control'
									onChange={(e) => setFile(e.target.files[0])}
									// defaultValue="pic"
									name='pic'
									placeholder='pic'
								/>
							</Modal.Body>
							<Modal.Footer>
								<Button variant='secondary' onClick={handleClose1}>
									Close
								</Button>
								<Button variant='primary' onClick={handleNewCredit}>
									Add
								</Button>
							</Modal.Footer>
						</Modal>

						<Button as='a' href='#' className='btn btn-primary ml-1 mt-4' onClick={handleDelete}>
							Delete
						</Button>
						<Button
							as='a'
							href='#'
							className='btn btn-primary ml-1 mt-4'
							// onClick={() => handleUpdateStore(id)}
							onClick={handleShowUpdate}>
							Update
						</Button>
						<Modal show={showUpdate} onHide={handleCloseUpdate} backdrop='static' keyboard={false} centered>
							<Modal.Header closeButton>
								<Modal.Title>Update Store </Modal.Title>
							</Modal.Header>
							<Modal.Body>
								<label htmlFor='name' className='text-black font-w600'>
									Name <span className='required'>*</span>
								</label>
								<input
									type='text'
									label='Name'
									className='form-control'
									defaultValue={name}
									name='name'
									placeholder='store name'
									onChange={(e) => setStoreName(e.target.value)}
								/>
								<label htmlFor='name' className='text-black font-w600'>
									English Name <span className='required'>*</span>
								</label>
								<input
									type='text'
									label='Name'
									className='form-control'
									defaultValue={nameen}
									name='name'
									placeholder='store name'
									onChange={(e) => setStoreNameEn(e.target.value)}
								/>
								<label htmlFor='address' className='text-black font-w600'>
									address
								</label>
								<input
									type='text'
									label='address'
									className='form-control'
									defaultValue={address1}
									name='address'
									placeholder='address'
									onChange={(e) => setAddress1(e.target.value)}
								/>
								<label htmlFor='address' className='text-black font-w600'>
									address2
								</label>
								<input
									type='text'
									label='address'
									className='form-control'
									defaultValue={address2}
									name='address'
									placeholder='address'
									onChange={(e) => setAddress2(e.target.value)}
								/>
								<label htmlFor='gps' className='text-black font-w600'>
									gps
								</label>
								<input
									type='text'
									label='gps'
									className='form-control'
									defaultValue={gps}
									name='gps'
									onChange={(e) => setGps(e.target.value)}
									placeholder='gps'
								/>
								{/* <label htmlFor="onlineTime" className="text-black font-w600">
                                        onlineTime
                                    </label>
                                    <input
                                        type="text"
                                        label="onlineTime"
                                        className="form-control"
                                        defaultValue={onlineTime}
                                        name="onlineTime"
                                        placeholder="onlineTime"
                                        onChange={(e) => setOnlineTime(e.target.value)}
                                    /> */}
								<label htmlFor='keywords' className='text-black font-w600'>
									keywords
								</label>
								<input
									type='text'
									label='keywords'
									className='form-control'
									defaultValue={keywords}
									name='keywords'
									onChange={(e) => setStoreKeywords(e.target.value)}
									placeholder='keywords'
								/>
								<label htmlFor='mobile' className='text-black font-w600'>
									mobile
								</label>
								<input
									type='text'
									label='mobile'
									className='form-control'
									defaultValue={tel}
									name='mobile'
									onChange={(e) => setTel(e.target.value)}
									placeholder='mobile'
								/>
								<label htmlFor='accountName' className='text-black font-w600'>
									accountName
								</label>
								<input
									type='text'
									label='accountName'
									className='form-control'
									defaultValue={accountName}
									name='accountName'
									onChange={(e) => setAccountName(e.target.value)}
									placeholder='accountName'
								/>
								<label htmlFor='accountBSB' className='text-black font-w600'>
									accountBSB
								</label>
								<input
									type='text'
									label='accountBSB'
									className='form-control'
									defaultValue={accountBSB}
									onChange={(e) => setAccountBSB(e.target.value)}
									name='accountBSB'
									placeholder='accountBSB'
								/>
								<label htmlFor='accountNum' className='text-black font-w600'>
									accountNum
								</label>
								<input
									type='text'
									label='accountNum'
									onChange={(e) => setAccountNum(e.target.value)}
									className='form-control'
									defaultValue={accountNum}
									name='accountNum'
									placeholder='accountNum'
								/>
								<label htmlFor='accountBank' className='text-black font-w600'>
									accountBank
								</label>
								<input
									type='text'
									label='accountBank'
									onChange={(e) => setAccountBank(e.target.value)}
									className='form-control'
									defaultValue={accountBank}
									name='accountBank'
									placeholder='accountBank'
								/>
								<label htmlFor='style' className='text-black font-w600'>
									style
								</label>
								<input
									type='text'
									label='style'
									className='form-control'
									onChange={(e) => setResStyle(e.target.value)}
									defaultValue={style}
									name='style'
									placeholder='style'
								/>
								<label htmlFor='style' className='text-black font-w600'>
									English style
								</label>
								<input
									type='text'
									label='style'
									className='form-control'
									onChange={(e) => setStyleEn(e.target.value)}
									defaultValue={styleen}
									name='style'
									placeholder='style'
								/>
								{/* <label htmlFor="telorder" className="text-black font-w600">
                                        mobile order
                                    </label>
                                    <input
                                        type="text"
                                        label="telorder"
                                        onChange={(e) => setTelorder(e.target.value)}
                                        className="form-control"
                                        defaultValue={telorder}
                                        name="telorder"
                                        placeholder="telorder"
                                    />
                                    <label htmlFor="style" className="text-black font-w600">
                                        style
                                    </label>
                                    <input
                                        type="text"
                                        label="style"
                                        className="form-control"
                                        onChange={(e) => setStyle(e.target.value)}
                                        defaultValue={style}
                                        name="style"
                                        placeholder="style"
                                    />
                                    <label htmlFor="follow" className="text-black font-w600">
                                        follow
                                    </label>
                                    <input
                                        type="text"
                                        label="follow"
                                        className="form-control"
                                        onChange={(e) => setFollow(e.target.value)}
                                        defaultValue={follow}
                                        name="follow"
                                        placeholder="follow"
                                    />
                                    <label htmlFor="state" className="text-black font-w600">
                                        state
                                    </label>
                                    <input
                                        type="text"
                                        label="state"
                                        className="form-control"
                                        onChange={(e) => setState(e.target.value)}
                                        defaultValue={state}
                                        name="state"
                                        placeholder="state"
                                    /> */}
								<label htmlFor='pic' className='text-black font-w600'>
									pic
								</label>
								<input
									type='file'
									label='pic'
									className='form-control'
									onChange={(e) => setPic1(e.target.files[0])}
									// defaultValue={pic}
									name='pic'
									placeholder='pic'
								/>
								<label htmlFor='pic2' className='text-black font-w600'>
									pic2
								</label>
								<input
									type='file'
									label='pic2'
									className='form-control'
									onChange={(e) => setPic2(e.target.files[0])}
									// defaultValue={pic2}
									name='pic2'
									placeholder='pic2'
								/>
								<label htmlFor='pic3' className='text-black font-w600'>
									pic3
								</label>
								<input
									type='file'
									label='pic3'
									className='form-control'
									onChange={(e) => setPic3(e.target.files[0])}
									// defaultValue={pic3}
									name='pic3'
									placeholder='pic3'
								/>
								<label htmlFor='pic4' className='text-black font-w600'>
									pic4
								</label>
								<input
									type='file'
									label='pic4'
									className='form-control'
									onChange={(e) => setPic4(e.target.files[0])}
									// defaultValue={pic4}
									// defaultValue="pic4"
									name='pic4'
									placeholder='pic4'
								/>
								<label htmlFor='pic5' className='text-black font-w600'>
									pic5
								</label>
								<input
									type='file'
									label='pic5'
									className='form-control'
									onChange={(e) => setPic5(e.target.files[0])}
									// defaultValue={pic5}
									// defaultValue="pic5"
									name='pic5'
									placeholder='pic5'
								/>
								<label htmlFor='pic6' className='text-black font-w600'>
									pic6
								</label>
								<input
									type='file'
									label='pic6'
									className='form-control'
									onChange={(e) => setPic6(e.target.files[0])}
									// defaultValue={pic6}
									// defaultValue="pic6"
									name='pic6'
									placeholder='pic6'
								/>
								<label htmlFor='pic7' className='text-black font-w600'>
									pic7
								</label>
								<input
									type='file'
									label='pic7'
									className='form-control'
									onChange={(e) => setPic7(e.target.files[0])}
									// defaultValue={pic7}
									// defaultValue="pic7"
									name='pic7'
									placeholder='pic7'
								/>
								<label htmlFor='pic8' className='text-black font-w600'>
									pic8
								</label>
								<input
									type='file'
									label='pic8'
									className='form-control'
									onChange={(e) => setPic8(e.target.files[0])}
									// defaultValue={pic8}
									// defaultValue="pic8"
									name='pic8'
									placeholder='pic8'
								/>
								<label htmlFor='pic9' className='text-black font-w600'>
									pic9
								</label>
								<input
									type='file'
									label='pic9'
									className='form-control'
									onChange={(e) => setPic9(e.target.files[0])}
									// defaultValue={pic9}
									// defaultValue="pic9"
									name='pic9'
									placeholder='pic9'
								/>
								<label htmlFor='pic10' className='text-black font-w600'>
									pic10
								</label>
								<input
									type='file'
									label='pic10'
									className='form-control'
									onChange={(e) => setPic10(e.target.files[0])}
									// defaultValue="pic10"
									name='pic10'
									placeholder='pic10'
								/>
								{/* <label htmlFor="types" className="text-black font-w600">
                            types
                        </label>
                        <input
                            type="text"
                            label="types"
                            className="form-control"
                            onChange={(e) => setType(e.target.value)}
                            // defaultValue="types"
                            name="types"
                            placeholder="types"
                        /> */}
								<label htmlFor='menu' className='text-black font-w600'>
									menu
								</label>
								<input
									type='file'
									label='menu'
									className='form-control'
									onChange={(e) => setMenu(e.target.files[0])}
									// defaultValue="menu"
									name='menu'
									placeholder='menu'
								/>
								{/* <label htmlFor="dinnerMenu" className="text-black font-w600">
                                        dinnerMenu
                                    </label>
                                    <input
                                        type="file"
                                        label="dinnerMenu"
                                        onChange={(e) => setDinnerMenu(e.target.files[0])}
                                        className="form-control"
                                        // defaultValue="dinnerMenu"
                                        name="dinnerMenu"
                                        placeholder="dinnerMenu"
                                    />
                                    <label htmlFor="drinkMenu" className="text-black font-w600">
                                        drinkMenu
                                    </label>
                                    <input
                                        type="file"
                                        label="drinkMenu"
                                        className="form-control"
                                        onChange={(e) => setDrinkMenu(e.target.files[0])}
                                        // defaultValue="drinkMenu"
                                        name="drinkMenu"
                                        placeholder="drinkMenu"
                                    />
                                    <label htmlFor="veganMenu" className="text-black font-w600">
                                        veganMenu
                                    </label>
                                    <input
                                        type="file"
                                        label="veganMenu"
                                        className="form-control"
                                        onChange={(e) => setVeganMenu(e.target.files[0])}
                                        // defaultValue="veganMenu"
                                        name="veganMenu"
                                        placeholder="veganMenu"
                                    />
                                    <label htmlFor="specialMenu" className="text-black font-w600">
                                        specialMenu
                                    </label>
                                    <input
                                        type="file"
                                        label="specialMenu"
                                        className="form-control"
                                        onChange={(e) => setSpecialMenu(e.target.files[0])}
                                        // defaultValue="specialMenu"
                                        name="specialMenu"
                                        placeholder="specialMenu"
                                    /> */}
								<label htmlFor='hide_keywords' className='text-black font-w600'>
									hide_keywords
								</label>
								<input
									type='text'
									label='hide_keywords'
									onChange={(e) => setHide_keywords(e.target.value)}
									className='form-control'
									defaultValue={hide_keywords}
									name='hide_keywords'
									placeholder='hide_keywords'
								/>
								<label htmlFor='price_level' className='text-black font-w600'>
									price_level
								</label>
								<input
									type='text'
									label='price_level'
									onChange={(e) => setPriceLevel(e.target.value)}
									className='form-control'
									defaultValue={price_level}
									name='price_level'
									placeholder='price_level'
								/>
								<label htmlFor='google_rate' className='text-black font-w600'>
									google_rate
								</label>
								<input
									type='text'
									label='google_rate'
									onChange={(e) => setGoogleRate(e.target.value)}
									className='form-control'
									defaultValue={google_rate}
									name='google_rate'
									placeholder='google_rate'
								/>
								<label htmlFor='rate_number' className='text-black font-w600'>
									rate_number
								</label>
								<input
									type='text'
									label='rate_number'
									onChange={(e) => setRateNumber(e.target.value)}
									className='form-control'
									defaultValue={rate_number}
									name='rate_number'
									placeholder='rate_number'
								/>
								<label htmlFor='promotion' className='text-black font-w600'>
									promotion
								</label>
								<input
									type='text'
									label='promotion'
									className='form-control'
									onChange={(e) => setPromotion(e.target.value)}
									defaultValue={promotion}
									name='promotion'
									placeholder='promotion'
								/>

								<label htmlFor='rules' className='text-black font-w600'>
									Rules English
								</label>
								<textarea
									// type="text"
									label='rules'
									className='form-control'
									onChange={(e) => setRulesEnglish(e.target.value)}
									defaultValue={englishRules}
									name='rules'
									placeholder='rules'
									// col="40"
									rows='5'></textarea>
								<label htmlFor='rules' className='text-black font-w600'>
									Rules Chinese
								</label>
								<textarea
									// type="text"
									label='rules'
									className='form-control'
									onChange={(e) => setRulesChinese(e.target.value)}
									defaultValue={chineseRules}
									name='rules'
									placeholder='rules'
									// col="40"
									rows='5'></textarea>
								{/* <input /> */}
							</Modal.Body>
							<Modal.Footer>
								<Button variant='secondary' onClick={handleCloseUpdate}>
									Close
								</Button>
								<Button variant='primary' onClick={handleUpdateStore}>
									Update
								</Button>
							</Modal.Footer>
						</Modal>
						<Button
							as='a'
							href='#'
							className='btn btn-primary ml-1 mt-4'
							// onClick={() => handleUpdateStore(id)}
							onClick={handleShowOpenHours}>
							Set Openhour
						</Button>
						<Modal
							show={showOpenHour}
							onHide={handleCloseOpenHours}
							backdrop='static'
							keyboard={false}
							centered>
							<Modal.Header closeButton>
								<Modal.Title>Set Openhour</Modal.Title>
							</Modal.Header>
							<Modal.Body>
								{Array.isArray(openHour) ? (
									openHour.map((item) => (
										<div>
											<label htmlFor='mon' className='text-black font-w600'>
												Monday <span className='required'></span>
											</label>
											<input
												type='text'
												label='mon'
												className='form-control'
												name='mon'
												defaultValue={item.mon}
												placeholder='Monday'
												onChange={(e) => setMonday(e.target.value)}
											/>
											<label htmlFor='tue' className='text-black font-w600'>
												Tuesday <span className='required'></span>
											</label>
											<input
												type='text'
												label='tue'
												className='form-control'
												name='tue'
												defaultValue={item.tue}
												placeholder='Tue'
												onChange={(e) => setTuesday(e.target.value)}
											/>
											<label htmlFor='wed' className='text-black font-w600'>
												Wednesday <span className='required'></span>
											</label>
											<input
												type='text'
												label='wed'
												className='form-control'
												defaultValue={item.wed}
												name='wed'
												placeholder='Wednesday'
												onChange={(e) => setWednesday(e.target.value)}
											/>
											<label htmlFor='thu' className='text-black font-w600'>
												Thursday <span className='required'></span>
											</label>
											<input
												type='text'
												label='thu'
												className='form-control'
												defaultValue={item.thu}
												name='thu'
												placeholder='Thursday'
												onChange={(e) => setThursday(e.target.value)}
											/>
											<label htmlFor='fri' className='text-black font-w600'>
												Friday <span className='required'></span>
											</label>
											<input
												type='text'
												label='fri'
												className='form-control'
												name='fri'
												defaultValue={item.fri}
												placeholder='Friday'
												onChange={(e) => setFriday(e.target.value)}
											/>
											<label htmlFor='sat' className='text-black font-w600'>
												Saturday <span className='required'></span>
											</label>
											<input
												type='text'
												label='sat'
												className='form-control'
												name='sat'
												defaultValue={item.sat}
												placeholder='Saturday'
												onChange={(e) => setSaturday(e.target.value)}
											/>
											<label htmlFor='sun' className='text-black font-w600'>
												Sunday <span className='required'></span>
											</label>
											<input
												type='text'
												label='sun'
												className='form-control'
												name='sun'
												placeholder='Sunday'
												defaultValue={item.sun}
												onChange={(e) => setSunday(e.target.value)}
											/>
											<label htmlFor='other' className='text-black font-w600'>
												Other <span className='required'></span>
											</label>
											<input
												type='text'
												label='other'
												className='form-control'
												name='other'
												defaultValue={item.other}
												placeholder='other'
												onChange={(e) => setOthers(e.target.value)}
											/>
										</div>
									))
								) : (
									<div>
										<label htmlFor='mon' className='text-black font-w600'>
											Monday <span className='required'></span>
										</label>
										<input
											type='text'
											label='mon'
											className='form-control'
											name='mon'
											// defaultValue={item.mon}
											placeholder='Monday'
											onChange={(e) => setMonday(e.target.value)}
										/>
										<label htmlFor='tue' className='text-black font-w600'>
											Tuesday <span className='required'></span>
										</label>
										<input
											type='text'
											label='tue'
											className='form-control'
											name='tue'
											// defaultValue={item.tue}
											placeholder='Tue'
											onChange={(e) => setTuesday(e.target.value)}
										/>
										<label htmlFor='wed' className='text-black font-w600'>
											Wednesday <span className='required'></span>
										</label>
										<input
											type='text'
											label='wed'
											className='form-control'
											// defaultValue={item.wed}
											name='wed'
											placeholder='Wednesday'
											onChange={(e) => setWednesday(e.target.value)}
										/>
										<label htmlFor='thu' className='text-black font-w600'>
											Thursday <span className='required'></span>
										</label>
										<input
											type='text'
											label='thu'
											className='form-control'
											// defaultValue={item.thu}
											name='thu'
											placeholder='Thursday'
											onChange={(e) => setThursday(e.target.value)}
										/>
										<label htmlFor='fri' className='text-black font-w600'>
											Friday <span className='required'></span>
										</label>
										<input
											type='text'
											label='fri'
											className='form-control'
											name='fri'
											// defaultValue={item.fri}
											placeholder='Friday'
											onChange={(e) => setFriday(e.target.value)}
										/>
										<label htmlFor='sat' className='text-black font-w600'>
											Saturday <span className='required'></span>
										</label>
										<input
											type='text'
											label='sat'
											className='form-control'
											name='sat'
											// defaultValue={item.sat}
											placeholder='Saturday'
											onChange={(e) => setSaturday(e.target.value)}
										/>
										<label htmlFor='sun' className='text-black font-w600'>
											Sunday <span className='required'></span>
										</label>
										<input
											type='text'
											label='sun'
											className='form-control'
											name='sun'
											placeholder='Sunday'
											// defaultValue={item.sun}
											onChange={(e) => setSunday(e.target.value)}
										/>
										<label htmlFor='other' className='text-black font-w600'>
											Other <span className='required'></span>
										</label>
										<input
											type='text'
											label='other'
											className='form-control'
											name='other'
											// defaultValue={item.other}
											placeholder='other'
											onChange={(e) => setOthers(e.target.value)}
										/>
									</div>
								)}
							</Modal.Body>
							<Modal.Footer>
								<Button variant='secondary' onClick={handleCloseOpenHours}>
									Close
								</Button>
								{!Array.isArray(openHour) && (
									<Button variant='primary' onClick={handleOpenHour}>
										Add
									</Button>
								)}
								{Array.isArray(openHour) && (
									<div>
										<Button
											variant='primary'
											onClick={() => handleUpdateOpenHour(openHour[0].id)}
											style={{ marginRight: '8px' }}>
											Update
										</Button>
										<Button onClick={() => handleDeleteOpenhour(openHour[0].id)}>Delete</Button>
									</div>
								)}
							</Modal.Footer>
						</Modal>
						{/* <Button
							as='a'
							href='#'
							className='btn btn-primary ml-1 mt-4'
							// onClick={() => handleUpdateStore(id)}
							onClick={handleShowCredit}>
							credit
						</Button> */}
						<Modal
							show={showcredit}
							onHide={handleCloseCredit}
							backdrop='static'
							keyboard={false}
							centered
							size='lg'>
							<Modal.Header closeButton>
								<Modal.Title>credit</Modal.Title>
							</Modal.Header>
							<Modal.Body>
								<div className='modal-xl'>
									<div className='table-responsive'>
										<table className='table table-striped'>
											{Array.isArray(creditList) &&
												creditList.length > 0 &&
												creditList.map((item) => (
													<>
														<thead>
															<tr>
																{/* <th className="center">#</th> */}
																<th>Name</th>
																<th>Name(Cn)</th>
																<th>Price</th>
																<th className='right'>Credit</th>
																<th className='center'>Pic</th>
																<th className='center'></th>
															</tr>
														</thead>
														<tbody>
															<tr
																className='justify-content-between'
																style={{
																	backgroundColor: 'white',
																}}>
																{/* <td className="center">1</td> */}
																<td
																	className='left strongd'
																	style={{
																		width: '30%',
																	}}>
																	<input
																		type='text'
																		label='name'
																		className='form-control'
																		defaultValue={item.name}
																		name='name'
																		onChange={(e) =>
																			setUpdateCreditName(e.target.value)
																		}
																		// placeholder="keywords"
																	/>
																	{/* {item.name} */}
																</td>
																<td
																	className='left strongd'
																	style={{
																		width: '30%',
																	}}>
																	<input
																		type='text'
																		label='name'
																		className='form-control'
																		defaultValue={item.content}
																		name='name'
																		onChange={(e) =>
																			setUpdateCreditContent(e.target.value)
																		}
																		// placeholder="keywords"
																	/>
																	{/* {item.name} */}
																</td>
																<td
																	className='left '
																	style={{
																		width: '12.5%',
																	}}>
																	<input
																		type='text'
																		label='price'
																		className='form-control'
																		defaultValue={item.price}
																		name='price'
																		onChange={(e) =>
																			setUpdateCreditPrice(e.target.value)
																		}
																		// placeholder="keywords"
																	/>
																</td>
																<td
																	className='right '
																	style={{
																		width: '12.5%',
																	}}>
																	<input
																		type='text'
																		label='credit'
																		className='form-control'
																		defaultValue={item.credit}
																		name='credit'
																		onChange={(e) =>
																			setUpdateCredit(e.target.value)
																		}
																		// placeholder="keywords"
																	/>
																</td>
																<td
																	className='right inline-block'
																	style={{
																		width: '15%',
																	}}>
																	abc
																</td>
															</tr>
															<tr>
																<td colSpan='5'>
																	<input
																		type='file'
																		label='pic'
																		className='form-control'
																		onChange={(e) => setFile(e.target.files[0])}
																		// defaultValue="pic"
																		name='pic'
																		placeholder='pic'
																	/>
																</td>
															</tr>
															<tr
																style={{
																	backgroundColor: 'white',
																}}>
																<td colspan='5' className='justify-content-center'>
																	<Button
																		className=' btn-primary'
																		style={{
																			marginRight: '10px',
																		}}
																		onClick={(e) => handleUpdateCredit(item)}>
																		Update
																	</Button>
																	<Button
																		className=' btn-primary'
																		onClick={(e) => handleDeleteCredit(item)}>
																		Delete
																	</Button>
																</td>
															</tr>
														</tbody>
													</>
												))}
										</table>
									</div>
								</div>
							</Modal.Body>
							<Modal.Footer>
								<Button variant='secondary' onClick={handleCloseCredit}>
									Close
								</Button>
							</Modal.Footer>
						</Modal>
						{/* <Button
							as='a'
							href='#'
							className='btn btn-primary ml-1 mt-4'
							// onClick={() => handleUpdateStore(id)}
							onClick={handleShowPackage}>
							Package
						</Button> */}
						<Modal
							show={showPackage}
							onHide={handleClosePackage}
							backdrop='static'
							keyboard={false}
							centered
							size='lg'>
							<Modal.Header closeButton>
								<Modal.Title>Package</Modal.Title>
							</Modal.Header>
							<Modal.Body>
								<div className='modal-xl'>
									<div className='table-responsive'>
										<table className='table table-striped'>
											{Array.isArray(packageList) &&
												packageList.length > 0 &&
												packageList.map((item) => (
													<>
														<thead>
															<tr>
																{/* <th className="center">#</th> */}
																<th>Name</th>
																<th>Name(Cn)</th>
																<th>Price</th>
																<th className='right'>Original price</th>
																<th className='center'>Proportion</th>
																{/* <th className='center'>
														Operation
													</th> */}
															</tr>
														</thead>
														<tbody>
															<tr
																className='justify-content-between'
																style={{
																	backgroundColor: 'white',
																}}>
																{/* <td className="center">1</td> */}
																<td
																	className='left strongd'
																	style={{
																		width: '30%',
																	}}>
																	<textarea
																		// type="text"
																		label='name'
																		className='form-control'
																		defaultValue={item.name}
																		name='name'
																		rows={5}
																		onChange={(e) =>
																			setUpdatePackageName(e.target.value)
																		}
																		// placeholder="keywords"
																	/>
																	{/* {item.name} */}
																</td>
																<td
																	className='left strongd'
																	style={{
																		width: '30%',
																	}}>
																	<textarea
																		type='text'
																		label='content'
																		className='form-control'
																		defaultValue={item.content}
																		name='content'
																		rows={5}
																		onChange={(e) =>
																			setUpdatePackageContent(e.target.value)
																		}
																		// placeholder="keywords"
																	/>
																	{/* {item.name} */}
																</td>
																<td
																	className='left '
																	style={{
																		width: '12.5%',
																	}}>
																	<input
																		type='text'
																		label='price'
																		className='form-control'
																		defaultValue={item.price}
																		name='price'
																		onChange={(e) =>
																			setUpdatePackagePrice(e.target.value)
																		}
																		// placeholder="keywords"
																	/>
																</td>
																<td
																	className='right '
																	style={{
																		width: '12.5%',
																	}}>
																	<input
																		type='text'
																		label='credit'
																		className='form-control'
																		defaultValue={item.yprice}
																		name='credit'
																		onChange={(e) =>
																			setUpdateYprice(e.target.value)
																		}
																		// placeholder="keywords"
																	/>
																</td>
																<td
																	className='right inline-block'
																	style={{
																		width: '15%',
																	}}>
																	<form className='form-inline block' role='form'>
																		<div className='form-group'>
																			<label className='form-inline'>
																				Pre 1：
																				<input
																					style={{
																						width: '50px',
																						marginBottom: '3px',
																					}}
																					type='text'
																					label='pre1'
																					className='form-control'
																					defaultValue={item.pre1}
																					name='pre1'
																					onChange={(e) =>
																						setupdatePackagePre1(
																							e.target.value
																						)
																					}
																					placeholder='per 1'
																				/>
																			</label>
																		</div>
																	</form>
																	<form className='form-inline block' role='form'>
																		<div className='form-group'>
																			<label className='form-inline'>
																				Pre 2：
																				<input
																					style={{
																						width: '50px',
																						marginBottom: '3px',
																					}}
																					type='text'
																					label='pre2'
																					className='form-control'
																					defaultValue={item.pre2}
																					name='pre1'
																					// rows={5}
																					onChange={(e) =>
																						setupdatePackagePre2(
																							e.target.value
																						)
																					}
																					placeholder='per 2'
																				/>
																			</label>
																		</div>
																	</form>
																	<form className='form-inline block' role='form'>
																		<div className='form-group'>
																			<label className='form-inline'>
																				Pre 3：
																				<input
																					style={{
																						width: '50px',
																						marginBottom: '3px',
																					}}
																					type='text'
																					label='pre1'
																					className='form-control'
																					defaultValue={item.pre3}
																					name='pre3'
																					onChange={(e) =>
																						setupdatePackagePre3(
																							e.target.value
																						)
																					}
																					placeholder='per 3'
																				/>
																			</label>
																		</div>
																	</form>
																	<form className='form-inline block' role='form'>
																		<div className='form-group'>
																			<label className='form-inline'>
																				Pre 4：
																				<input
																					style={{
																						width: '50px',
																					}}
																					type='text'
																					label='pre4'
																					className='form-control'
																					defaultValue={item.pre4}
																					name='pre4'
																					onChange={(e) =>
																						setupdatePackagePre4(
																							e.target.value
																						)
																					}
																					placeholder='per 4'
																				/>
																			</label>
																		</div>
																	</form>
																</td>
															</tr>
															<tr>
																<td colSpan='5'>
																	<label
																		htmlFor='pic'
																		className='text-black font-w600'>
																		pic
																	</label>
																	<input
																		type='file'
																		label='pic'
																		className='form-control'
																		onChange={(e) => setFile(e.target.files[0])}
																		// defaultValue="pic"
																		name='pic'
																		placeholder='pic'
																	/>
																</td>
															</tr>
															<tr
																style={{
																	backgroundColor: 'white',
																}}>
																<td colSpan='5'>
																	<Button
																		className=' btn-primary '
																		style={{
																			marginRight: '10px',
																		}}
																		onClick={(e) => handleUpdatePackage(item)}>
																		Update
																	</Button>
																	<Button
																		// as='a'
																		// href='#'
																		// style={{
																		// 	width:
																		// 		"80px",
																		// }}
																		className=' btn-primary '
																		onClick={(e) => handleDeletePackage(item)}>
																		Delete
																	</Button>
																</td>
															</tr>
														</tbody>
													</>
												))}
										</table>
									</div>
								</div>
							</Modal.Body>
							<Modal.Footer>
								<Button variant='secondary' onClick={handleClosePackage}>
									Close
								</Button>
							</Modal.Footer>
						</Modal>
						{/* <Button
							as='a'
							href='#'
							className='btn btn-primary ml-1 mt-4'
							// onClick={() => handleUpdateStore(id)}
							onClick={handleShowAuthority}>
							Authority
						</Button> */}
						<Modal
							show={showAuthority}
							onHide={handleCloseAuthority}
							backdrop='static'
							keyboard={false}
							centered
							size='lg'>
							<Modal.Header closeButton>
								<Modal.Title>Authority</Modal.Title>
							</Modal.Header>
							<Modal.Body>
								<div className='modal-xl'>
									<div className='row'>
										<div className='col-xl-12'>
											<div className='card'>
												<div className='card-body'>
													<div className='row'>
														<div className='col-lg order-lg mb-4'>
															<div className='col-lg order-lg-1 justify-content-center'>
																{/* <h4 className="mb-3">Set Staff</h4> */}
																{/* <form
                                                                    className="needs-validation"
                                                                    noValidate=""
                                                                > */}
																<div className='mb-3'>
																	<label htmlFor='email'>
																		Email
																		{/* <span className="text-muted">(Optional)</span> */}
																	</label>
																	<input
																		type='email'
																		className='form-control'
																		id='email'
																		onChange={(e) => setEmail(e.target.value)}
																		// placeholder="you@example.com"
																	/>
																	<div className='invalid-feedback'>
																		Please enter a valid email address for shipping
																		updates.
																	</div>
																</div>

																<div className='row'>
																	<div className='col-md mb-3'>
																		<label htmlFor='state'>Staff/Owner</label>
																		<Form.Control
																			as='select'
																			onChange={(e) => setLevel(e.target.value)}>
																			<option
																				style={{
																					color: 'blue',
																				}}>
																				{''}
																			</option>
																			<option
																				// style={{
																				//     color: 'blue',
																				// }}
																				// onChange={(e) =>
																				//     (e.target.value)
																				// }
																				value={92}>
																				Staff
																			</option>

																			<option value={91}>Owner</option>
																			<option value={0}>Not a staff</option>
																		</Form.Control>

																		<div className='invalid-feedback'>
																			Please provide a valid state.
																		</div>
																	</div>
																</div>

																<hr className='mb-4' />
																<button
																	className='btn btn-primary btn-lg btn-block'
																	// type="submit"
																	onClick={handleSetting}>
																	Set
																</button>
																{/* </form> */}
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</Modal.Body>
							{/* <Modal.Footer>
                                <Button variant="secondary" onClick={handleCloseAuthority}>
                                    Close
                                </Button>
                            </Modal.Footer> */}
						</Modal>

						{/* <Button
							as='a'
							href='#'
							className='btn btn-primary ml-1 mt-4'
							// onClick={() => handleUpdateStore(id)}
							onClick={handleShowReviews}>
							Reviews
						</Button> */}
						<Modal
							show={showReviews}
							onHide={handleCloseReviews}
							backdrop='static'
							keyboard={false}
							centered
							size='lg'>
							<Modal.Header closeButton>
								<Modal.Title>Reviews</Modal.Title>
							</Modal.Header>
							<Modal.Body>
								<div className='modal-xl'>
									<div className='row'>
										<div className='col-xl-12'>
											<div className='card'>
												<div className='card-body'>
													<div className='row'>
														<div className='col-lg order-lg mb-4'>
															<div className='col-lg order-lg-1 justify-content-center'>
																{reviews.length > 0 ? (
																	<BootstrapTable
																		keyField='id'
																		data={reviews}
																		columns={columns}
																		pagination={paginationFactory(options)}
																		// rowEvents={rowEvents}
																		// {...paginationTableProps}
																	/>
																) : (
																	<p className='card-trext'>No Reviews</p>
																)}
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</Modal.Body>
							{/* <Modal.Footer>
                                <Button variant="secondary" onClick={handleCloseAuthority}>
                                    Close
                                </Button>
                            </Modal.Footer> */}
						</Modal>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Products;
