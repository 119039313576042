import { Breadcrumb, Button, Card, Col, Form, Modal, Nav } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import React, { useCallback, useEffect, useState } from 'react';

import BootstrapTable from 'react-bootstrap-table-next';
import { NewCourse } from '../../api/api';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

const NewCourses = () => {
	const user = JSON.parse(localStorage.getItem('current_user') || '{}');
	const history = useHistory();

	const [name, setName] = useState('');
	const [enname, setEnName] = useState('');
	const [type, setType] = useState('');
	const [price, setPrice] = useState('');
	const [price1, setPrice1] = useState('');
	const [tuijian, setTuijian] = useState('');
	const [recommend, setRecommend] = useState('');
	const [content, setContent] = useState('');
	const [pic1, setPic1] = useState('');
	const [pic2, setPic2] = useState('');
	const [pic3, setPic3] = useState('');
	const [pic4, setPic4] = useState('');
	const [pic5, setPic5] = useState('');
	const [pic6, setPic6] = useState('');
	const [order, setOrder] = useState('');
	const [rules, setRules] = useState({
		rule1: '',
		rule1en: '',
		maxchoice1: '',
		rule2: '',
		rule2en: '',
		maxchoice2: '',
		rule3: '',
		rule3en: '',
		maxchoice3: '',
	});
	console.log('=> rules', rules);
	const [rule1CN, setRule1CN] = useState('');
	console.log('=> rule1CN', rule1CN);
	const [rule1EN, setRule1EN] = useState('');
	console.log('=> rule1EN', rule1EN);
	const [rule2CN, setRule2CN] = useState('');
	console.log('=> rule2CN', rule2CN);
	const [rule2EN, setRule2EN] = useState('');
	console.log('=> rule2EN', rule2EN);
	const [rule3CN, setRule3CN] = useState('');
	console.log('=> rule3CN', rule3CN);
	const [rule3EN, setRule3EN] = useState('');
	console.log('=> rule3EN', rule3EN);

	const [courseType, setCourseType] = useState(JSON.parse(localStorage.getItem('coursestype')));
	console.log('🚀 ~ file: NewCourse.js ~ line 21 ~ NewCourse ~ course', courseType);

	const handleNewCourse = async () => {
		console.log('🚀 ~ file: NewCourse.js ~ line 45 ~ handleNewCourse ~ course.resid,', courseType.resid);
		console.log(
			'🚀 ~ file: NewCourse.js ~ line 46 ~ handleNewCourse ~ course.servicetype,',
			courseType.servicetype
		);
		console.log('🚀 ~ file: NewCourse.js ~ line 50 ~ handleNewCourse ~ course.coursetypeid,', courseType.id);

		const data = await NewCourse(
			user.access_token,
			courseType.resid,
			name,
			enname,
			type,
			price,
			price1,
			tuijian,
			content,
			pic1,
			pic2,
			pic3,
			pic4,
			pic5,
			pic6,
			order,
			courseType.servicetype,
			courseType.id,
			recommend,
			JSON.stringify({ cnname: rule1CN, enname: rule1EN }),
			rules.rule1,
			rules.rule1en,
			rules.maxchoice1,
			JSON.stringify({ cnname: rule2CN, enname: rule2EN }),
			rules.rule2,
			rules.rule2en,
			rules.maxchoice2,
			JSON.stringify({ cnname: rule3CN, enname: rule3EN }),
			rules.rule3,
			rules.rule3en,
			rules.maxchoice3
		);
		if (data.ret == 200) {
			alert('Successfull！');
		}
		console.log('🚀 ~ file: NewCourse.js ~ line 81 ~ handleNewCourse ~ data', data);
	};
	const handleRules = (e) => {
		console.log(e);
		setRules({ ...rules, [e.target.name]: e.target.value });
	};

	console.log('new courses');

	return (
		<div className='h-80'>
			<div className='page-titles'>
				<Breadcrumb>
					<Breadcrumb.Item>
						<p onClick={() => history.goBack()}>Back</p>
					</Breadcrumb.Item>
				</Breadcrumb>
			</div>
			<div className='row'>
				<div className='col-lg-12'>
					<div className='card'>
						<div className='card-body '>
							<form>
								<div className='form-row'>
									<div className='form-group col-md-6'>
										<label htmlFor='name'>Name</label>
										<input
											type='text'
											className='form-control'
											id='name'
											placeholder='Name'
											onChange={(e) => setName(e.target.value)}
										/>
									</div>
									<div className='form-group col-md-6'>
										<label htmlFor='name'>English Name</label>
										<input
											type='text'
											className='form-control'
											id='name'
											placeholder='Name'
											onChange={(e) => setEnName(e.target.value)}
										/>
									</div>

									{/* <div className='form-group col-md-6'>
										<label htmlFor='type'>Type</label>
										<input
											type='text'
											className='form-control'
											id='type'
											placeholder='Type'
											onChange={(e) => setType(e.target.value)}
										/>
									</div> */}
									{/* <div className='form-group col-md-6'>
										<label htmlFor='price'>Price(菜品价格)</label>
										<input
											type='text'
											className='form-control'
											id='price'
											placeholder='Original price'
											onChange={(e) => setPrice(e.target.value)}
										/>
									</div> */}
									<div className='form-group col-md-6'>
										<label htmlFor='price1'>Price(价格)</label>
										<input
											type='text'
											className='form-control'
											id='price1'
											placeholder='Enent price'
											onChange={(e) => setPrice1(e.target.value)}
										/>
									</div>
									{/* <div className='form-group col-md-6'>
										<label htmlFor='recommend'>Recommend</label>
										<input
											type='text'
											className='form-control'
											id='recommend'
											placeholder='Recommend'
											onChange={(e) => setTuijian(e.target.value)}
										/>
									</div>
									<div className='form-group col-md-6'>
										<label htmlFor='content'>Content</label>
										<input
											type='text'
											className='form-control'
											placeholder='Course description'
											id='content'
											onChange={(e) => setContent(e.target.value)}
										/>
									</div> */}
									{/* <div className='form-group col-md-6'>
										<label htmlFor='type'>rule1 CNname</label>
										<input
											type='text'
											className='form-control'
											id='rule1'
											onChange={(e) => setRule1CN(e.target.value)}
										/>
									</div>
									<div className='form-group col-md-6'>
										<label htmlFor='type'>rule1 ENname</label>
										<input
											type='text'
											className='form-control'
											id='rule1'
											onChange={(e) => setRule1EN(e.target.value)}
										/>
									</div>
									<div className='form-group col-md-6'>
										<label htmlFor='type'>Rule1(CN)</label>
										<input
											type='text'
											className='form-control'
											id='rule1'
											name='rule1'
											placeholder='规格1'
											onChange={(e) => handleRules(e)}
										/>
									</div>
									<div className='form-group col-md-6'>
										<label htmlFor='type'>Rule1(EN)</label>
										<input
											type='text'
											className='form-control'
											id='rule1'
											name='rule1en'
											placeholder='规格1'
											onChange={(e) => handleRules(e)}
										/>
									</div>
									<div className='form-group col-md-6'>
										<label htmlFor='type'>Maxchoice1</label>
										<input
											type='text'
											className='form-control'
											id='maxchoice1'
											name='maxchoice1'
											placeholder='Maxchoice1'
											onChange={(e) => handleRules(e)}
										/>
									</div>
									<div className='form-group col-md-6'>
										<label htmlFor='type'>rule2 CNname</label>
										<input
											type='text'
											className='form-control'
											id='rule2'
											onChange={(e) => setRule2CN(e.target.value)}
										/>
									</div>
									<div className='form-group col-md-6'>
										<label htmlFor='type'>rule1 ENname</label>
										<input
											type='text'
											className='form-control'
											id='rule1'
											onChange={(e) => setRule2EN(e.target.value)}
										/>
									</div>
									<div className='form-group col-md-6'>
										<label htmlFor='type'>Rule2(CN)</label>
										<input
											type='text'
											className='form-control'
											id='rule2'
											name='rule2'
											placeholder='规格2'
											onChange={(e) => handleRules(e)}
										/>
									</div>
									<div className='form-group col-md-6'>
										<label htmlFor='type'>Rule2(EN)</label>
										<input
											type='text'
											className='form-control'
											id='rule2'
											name='rule2en'
											placeholder='规格2'
											onChange={(e) => handleRules(e)}
										/>
									</div>
									<div className='form-group col-md-6'>
										<label htmlFor='type'>Maxchoice2</label>
										<input
											type='text'
											className='form-control'
											id='maxchoice2'
											name='maxchoice2'
											placeholder='Maxchoice2'
											onChange={(e) => handleRules(e)}
										/>
									</div>
									<div className='form-group col-md-6'>
										<label htmlFor='type'>rule3 CNname</label>
										<input
											type='text'
											className='form-control'
											id='rule3'
											name='rule3'
											onChange={(e) => setRule3CN(e.target.value)}
										/>
									</div>
									<div className='form-group col-md-6'>
										<label htmlFor='type'>rule3 ENname</label>
										<input
											type='text'
											className='form-control'
											id='rule3'
											name='rule3'
											onChange={(e) => setRule3EN(e.target.value)}
										/>
									</div>
									<div className='form-group col-md-6'>
										<label htmlFor='type'>Rule3(CN)</label>
										<input
											type='text'
											className='form-control'
											id='rule3'
											name='rule3'
											placeholder='规格3'
											onChange={(e) => handleRules(e)}
										/>
									</div>
									<div className='form-group col-md-6'>
										<label htmlFor='type'>Rule3(EN)</label>
										<input
											type='text'
											className='form-control'
											id='rule3'
											name='rule3en'
											placeholder='规格3'
											onChange={(e) => handleRules(e)}
										/>
									</div>
									<div className='form-group col-md-6'>
										<label htmlFor='type'>Maxchoice3</label>
										<input
											type='text'
											className='form-control'
											id='maxchoice3'
											name='maxchoice3'
											placeholder='Maxchoice3'
											onChange={(e) => handleRules(e)}
										/>
									</div> */}
									<div className='form-group col-md-6'>
										<label htmlFor='pic1'>Pic</label>
										<input
											type='file'
											className='form-control'
											id='pic1'
											onChange={(e) => setPic1(e.target.files[0])}
										/>
									</div>
									{/* <div className='form-group col-md-6'>
										<label htmlFor='pic2'>Pic2</label>
										<input
											type='file'
											className='form-control'
											id='pic2'
											onChange={(e) => setPic2(e.target.files[0])}
										/>
									</div>
									<div className='form-group col-md-6'>
										<label htmlFor='pic3'>Pic3</label>
										<input
											type='file'
											className='form-control'
											id='pic3'
											onChange={(e) => setPic3(e.target.files[0])}
										/>
									</div>
									<div className='form-group col-md-6'>
										<label htmlFor='pic4'>Pic4</label>
										<input
											type='file'
											className='form-control'
											id='pic4'
											onChange={(e) => setPic4(e.target.files[0])}
										/>
									</div>
									<div className='form-group col-md-6'>
										<label htmlFor='pic5'>Pic5</label>
										<input
											type='file'
											className='form-control'
											id='pic5'
											onChange={(e) => setPic5(e.target.files[0])}
										/>
									</div>
									<div className='form-group col-md-6'>
										<label htmlFor='pic6'>Pic6</label>
										<input
											type='file'
											className='form-control'
											id='pic6'
											onChange={(e) => setPic6(e.target.files[0])}
										/>
									</div>
								</div>
								<div className='form-group col-md-6'>
									<label htmlFor='order'>Order</label>
									<input
										type='text'
										className='form-control'
										id='order'
										onChange={(e) => setOrder(e.target.value)}
									/>
								</div> */}
								</div>
								<div className='d-flex  justify-content-center align-items-center mb-2 mt-2'>
									<Button variant='primary' onClick={handleNewCourse}>
										Add
									</Button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default NewCourses;
