import { Breadcrumb, Button, Card, Col, Modal, Nav, Table } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Switch from '@material-ui/core/Switch';
import { adminUpdateStock } from '../../api/api';

const CourseDetail = ({
	openCourseDetail,
	handleCloseDetail,
	handleUpdateCourse,
	handleDeleteCourse,
	setPrice1,
	// handleChangeStock,
	setEnName,
	setName,
	user,
	detail,
}) => {
	// const detail = JSON.parse(localStorage.getItem('courseDetail'));
	console.log('detail', detail);
	const [stock, setStock] = useState(true);
	const [courseId, setCourseId] = useState(true);
	console.log('stock', stock);

	const handleUpdateStock = async (id, instock) => {
		// 菜品id
		const data = await adminUpdateStock(user.access_token, id, instock);
		setCourseId(id);
		console.log('user.access_token', user.access_token);
		console.log(data);
	};

	const handleChangeStock = (event, item) => {
		console.log('item', item);
		setStock(event.target.checked);
		console.log('event.target.checked', event.target.checked);
		// handleUpdateStock(item.id, event.target.checked ? 1 : 0);
	};

	return (
		<Modal show={openCourseDetail} onHide={handleCloseDetail}>
			<Modal.Header closeButton>
				<Modal.Title>Edit Course (编辑菜品)</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<form>
					<div className='form-row'>
						<div className='justify-content-left d-flex form-row'>
							<div className='form-group col-md-6'>
								<label htmlFor='name'>Name(菜品名)</label>
								<input
									type='text'
									className='form-control'
									id='name'
									defaultValue={detail?.name}
									onChange={(e) => setName(e.target.value)}
								/>
							</div>
							<div className='form-group col-md-6'>
								<label htmlFor='en-name'>English Name</label>
								<input
									type='text'
									className='form-control'
									id='en-name'
									placeholder='English name'
									defaultValue={detail?.enname}
									onChange={(e) => setEnName(e.target.value)}
								/>
							</div>
							{/* <div className='form-group col-md-6'>
								<FormGroup row>
									<FormControlLabel
										control={
											<Switch
												checked={detail?.instock == 1 ? true : false}
												onChange={(e) => handleChangeStock(e, detail)}
												name='stock'
												color='primary'
											/>
										}
										label='In Stock'
									/>
								</FormGroup>
							</div>{' '} */}
							{/* <div className='form-group col-md-6'>
																	<label htmlFor='type'>Type(类型)</label>
																	<input
																		type='text'
																		className='form-control'
																		id='type'
																		defaultValue={item.type}
																		onChange={(e) => setType(e.target.value)}
																	/>
																</div> */}
							{/* <div className='form-group col-md-6'>
																	<label htmlFor='price'>Price(菜品价格)</label>
																	<input
																		type='text'
																		className='form-control'
																		id='price'
																		defaultValue={item.price}
																		onChange={(e) => setPrice(e.target.value)}
																	/>
																</div> */}
							<div className='form-group col-md-6'>
								<label htmlFor='price1'>Price (活动价格)</label>
								<input
									type='text'
									className='form-control'
									id='price1'
									defaultValue={detail?.price1}
									onChange={(e) => setPrice1(e.target.value)}
								/>
							</div>
							{/* <div className='form-group col-md-6'>
																	<label htmlFor='recommend'>Recommend</label>
																	<input
																		type='text'
																		className='form-control'
																		id='recommend'
																		defaultValue={item.tuijian}
																		onChange={(e) => setRecommend(e.target.value)}
																	/>
																</div>
																<div className='form-group col-md-6'>
																	<label htmlFor='content'>Content</label>
																	<input
																		type='text'
																		className='form-control'
																		defaultValue={item.content}
																		id='content'
																		onChange={(e) => setContent(e.target.value)}
																	/>
																</div>
																<div className='form-group col-md-6'>
																	<label htmlFor='type'>rule1 CNname</label>
																	<input
																		type='text'
																		className='form-control'
																		id='rule1'
																		defaultValue={
																			item.rulename1 &&
																			JSON.parse(item.rulename1)?.cnname
																		}
																		onChange={(e) => setRule1CN(e.target.value)}
																	/>
																</div>
																<div className='form-group col-md-6'>
																	<label htmlFor='type'>rule1 ENname</label>
																	<input
																		type='text'
																		className='form-control'
																		id='rule1'
																		defaultValue={
																			item.rulename1 &&
																			JSON.parse(item.rulename1)?.enname
																		}
																		onChange={(e) => setRule1EN(e.target.value)}
																	/>
																</div>
																<div className='form-group col-md-6'>
																	<label htmlFor='type'>Rule1(CN)</label>
																	<input
																		type='text'
																		className='form-control'
																		id='rule1'
																		name='rule1'
																		placeholder='规格1'
																		defaultValue={item.rule1}
																		onChange={(e) => handleRules(e)}
																	/>
																</div>
																<div className='form-group col-md-6'>
																	<label htmlFor='type'>Rule1(EN)</label>
																	<input
																		type='text'
																		className='form-control'
																		id='rule1'
																		name='rule1en'
																		placeholder='规格1'
																		defaultValue={item.rule1en}
																		onChange={(e) => handleRules(e)}
																	/>
																</div>
																<div className='form-group col-md-6'>
																	<label htmlFor='type'>Maxchoice1</label>
																	<input
																		type='text'
																		className='form-control'
																		id='maxchoice1'
																		name='maxchoice1'
																		defaultValue={item.maxchoice1}
																		placeholder='Maxchoice1'
																		onChange={(e) => handleRules(e)}
																	/>
																</div>
																<div className='form-group col-md-6'>
																	<label htmlFor='type'>rule2 CNname</label>
																	<input
																		type='text'
																		className='form-control'
																		id='rule2'
																		defaultValue={
																			item.rulename2 &&
																			JSON.parse(item.rulename2)?.cnname
																		}
																		onChange={(e) => setRule2CN(e.target.value)}
																	/>
																</div>
																<div className='form-group col-md-6'>
																	<label htmlFor='type'>rule2 ENname</label>
																	<input
																		type='text'
																		className='form-control'
																		id='rule1'
																		defaultValue={
																			item.rulename2 &&
																			JSON.parse(item.rulename2)?.enname
																		}
																		onChange={(e) => setRule2EN(e.target.value)}
																	/>
																</div>
																<div className='form-group col-md-6'>
																	<label htmlFor='type'>Rule2(CN)</label>
																	<input
																		type='text'
																		className='form-control'
																		id='rule2'
																		name='rule2'
																		placeholder='规格2'
																		defaultValue={item.rule2}
																		onChange={(e) => handleRules(e)}
																	/>
																</div>
																<div className='form-group col-md-6'>
																	<label htmlFor='type'>Rule2(EN)</label>
																	<input
																		type='text'
																		className='form-control'
																		id='rule2'
																		name='rule2en'
																		placeholder='规格2'
																		defaultValue={item.rule2en}
																		onChange={(e) => handleRules(e)}
																	/>
																</div>
																<div className='form-group col-md-6'>
																	<label htmlFor='type'>Maxchoice2</label>
																	<input
																		type='text'
																		className='form-control'
																		id='maxchoice2'
																		name='maxchoice2'
																		defaultValue={item.maxchoice2}
																		placeholder='Maxchoice2'
																		onChange={(e) => handleRules(e)}
																	/>
																</div>
																<div className='form-group col-md-6'>
																	<label htmlFor='type'>rule3 CNname</label>
																	<input
																		type='text'
																		className='form-control'
																		id='rule3'
																		name='rule3'
																		defaultValue={
																			item.rulename3 &&
																			JSON.parse(item.rulename3)?.cnname
																		}
																		onChange={(e) => setRule3CN(e.target.value)}
																	/>
																</div>
																<div className='form-group col-md-6'>
																	<label htmlFor='type'>rule3 ENname</label>
																	<input
																		type='text'
																		className='form-control'
																		id='rule3'
																		name='rule3'
																		defaultValue={
																			item.rulename3 &&
																			JSON.parse(item.rulename3)?.enname
																		}
																		onChange={(e) => setRule3EN(e.target.value)}
																	/>
																</div>
																<div className='form-group col-md-6'>
																	<label htmlFor='type'>Rule3(CN)</label>
																	<input
																		type='text'
																		className='form-control'
																		id='rule3'
																		name='rule3'
																		placeholder='规格3'
																		defaultValue={item.rule3}
																		onChange={(e) => handleRules(e)}
																	/>
																</div>
																<div className='form-group col-md-6'>
																	<label htmlFor='type'>Rule3(EN)</label>
																	<input
																		type='text'
																		className='form-control'
																		id='rule3'
																		name='rule3en'
																		placeholder='规格3'
																		defaultValue={item.rule3en}
																		onChange={(e) => handleRules(e)}
																	/>
																</div>
																<div className='form-group col-md-6'>
																	<label htmlFor='type'>Maxchoice3</label>
																	<input
																		type='text'
																		className='form-control'
																		id='maxchoice3'
																		name='maxchoice3'
																		defaultValue={item.maxchoice3}
																		placeholder='Maxchoice3'
																		onChange={(e) => handleRules(e)}
																	/>
																</div>
																<div className='form-group col-md-6'>
																	<label htmlFor='pic1'>Picture</label>
																	<input
																		type='file'
																		className='form-control'
																		id='pic1'
																		onChange={(e) => setPic1(e.target.files[0])}
																	/>
																</div> */}
						</div>
						{/* <div className='form-group col-md-6'>
																<label htmlFor='order'>Order</label>
																<input
																	type='text'
																	className='form-control'
																	id='order'
																	defaultValue={item.order}
																	onChange={(e) => setOrder(e.target.value)}
																/>
															</div> */}
					</div>
				</form>
			</Modal.Body>
			<Modal.Footer>
				<Button
					variant='primary'
					onClick={() => handleUpdateCourse(detail)}
					style={{
						marginRight: '10px',
					}}>
					Update(更新菜品)
				</Button>

				<Button variant='primary' onClick={() => handleDeleteCourse(detail.id)}>
					Delete(删除菜品)
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default CourseDetail;
