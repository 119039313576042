// import svg from '../../../images/svg/welcom-card.svg';

import {
	GetdtUser,
	GetdtUserspend,
	GetsyUser,
	IdgetBabbletea,
	IdgetBeauty,
	IdgetEntertainment,
	IdgetRestaurant,
	IdupBarber,
	IdupCarRepairs,
	IdupFitness,
	IdupMassage,
	IdupMovie,
	IdupSupermarket,
	getLastMonthSpend,
	getOneMonthSpend,
	getOrders,
	getTodaySpend,
	getWeekSpend,
} from '../../api/api';
import React, { useEffect, useState } from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';

import { createBrowserHistory } from 'history';

// import { Link } from 'react-router-dom';

const Home = () => {
	const history = createBrowserHistory();
	const [month, setMonth] = useState([]);
	const [lastMonth, setLastMonth] = useState([]);
	const [week, setWeek] = useState([]);
	const [today, setToday] = useState([]);
	const user = JSON.parse(localStorage.getItem('current_user'));
	const [dailyUser, setDailyUser] = useState([]);
	const [dailySpend, setDailySpend] = useState([]);
	const [allUsers, setAllUsers] = useState([]);
	const [paid, setPaid] = useState([]);

	if (!user) {
		history.push('/page-login');
		window.location.reload();
	}
	const [store, setStore] = useState([]);

	useEffect(() => {
		const getRes = async () => {
			const res = await IdgetRestaurant(user.access_token, user.resid);
			console.log('res!!!!!!!!!!!!', res);
			localStorage.setItem('current_store', JSON.stringify(res));
		};
		getRes();
	}, []);
	useEffect(() => {
		const getRes = async () => {
			const res = await IdgetRestaurant(user.access_token, user.resid);
			console.log('res!!!!!!!!!!!!', res);
			localStorage.setItem('current_store', JSON.stringify(res));
		};
		getRes();
		const getData = async () => {
			const todayData = await getTodaySpend(user.access_token, user.resid);

			setToday(todayData.data);
		};
		getData();

		const getDailyUser = async () => {
			const data = await GetdtUser(user.access_token);
			setDailyUser(data.data);
		};
		getDailyUser();
		const getDailySpend = async () => {
			const data = await GetdtUserspend(user.access_token);
			setDailySpend(data.data);
		};
		getDailySpend();
		const getAllUser = async () => {
			const data = await GetsyUser(user.access_token);
			setAllUsers(data.data);
		};
		getAllUser();
		const getDailyPaid = async () => {
			const data = await getOrders(user.access_token);
			setPaid(data.data);
		};
		getDailyPaid();
	}, []);
	useEffect(() => {
		const getData = async () => {
			const lastMonthData = await getLastMonthSpend(user.access_token, user.resid);
			setLastMonth(lastMonthData.data);
		};
		getData();

		async function idGetRes() {
			switch (user.servicetype) {
				case '0':
					const res = await IdgetRestaurant(user.access_token, user.resid);
					localStorage.setItem('current_store', JSON.stringify(res));
					setStore(res); // setStore(store);
					break;
				case '1':
					//  async function idGetBeauty() {
					const bt = await IdgetBeauty(user.access_token, user.resid);
					localStorage.setItem('current_store', JSON.stringify(bt));

					setStore(bt);
					break;
				case '2':
					// async function idGetEnt() {
					const et = await IdgetEntertainment(user.access_token, user.resid);
					localStorage.setItem('current_store', JSON.stringify(et));
					setStore(et);
					break;
				case '3':
					// console.log(user.resid);
					// async function idGetBub() {
					const bub = await IdgetBabbletea(user.access_token, user.resid);
					// console.log('🚀 ~ file: Home.js ~ line 90 ~ idGetBub ~ store', store);
					localStorage.setItem('current_store', JSON.stringify(bub));
					setStore(bub);
					break;
				case '6':
					//console.log('babble_tea, delete id', id);
					const movie = await IdupMovie(user.access_token, user.resid);
					localStorage.setItem('current_store', JSON.stringify(movie));
					setStore(movie);

					break;
				case '7':
					//console.log('babble_tea, delete user.resid', user.resid);
					const Massage = await IdupMassage(user.access_token, user.resid);
					localStorage.setItem('current_store', JSON.stringify(Massage));
					setStore(Massage);

					break;
				case '8':
					//console.log('babble_tea, delete user.resid', user.resid);
					const Barber = await IdupBarber(user.access_token, user.resid);
					localStorage.setItem('current_store', JSON.stringify(Barber));
					setStore(Barber);

					break;
				case '9':
					//console.log('babble_tea, delete user.resid', user.resid);
					const CarRepair = await IdupCarRepairs(user.access_token, user.resid);
					localStorage.setItem('current_store', JSON.stringify(CarRepair));
					setStore(CarRepair);

					break;
				case '10':
					//console.log('babble_tea, delete user.resid', user.resid);
					const Supermarket = await IdupSupermarket(user.access_token, user.resid);
					localStorage.setItem('current_store', JSON.stringify(Supermarket));
					setStore(Supermarket);

					break;
				case '11':
					//console.log('babble_tea, delete user.resid', user.resid);
					const Fitness = await IdupFitness(user.access_token, user.resid);
					localStorage.setItem('current_store', JSON.stringify(Fitness));
					setStore(Fitness);

					break;
			}
		}

		idGetRes();
	}, []);

	useEffect(() => {
		const getData = async () => {
			const monthData = await getOneMonthSpend(user.access_token, user.resid);
			setMonth(monthData.data);
		};
		getData();
	}, []);
	useEffect(() => {
		const getData = async () => {
			const weekData = await getWeekSpend(user.access_token, user.resid);

			setWeek(weekData.data);
		};
		getData();
	}, []);

	function calculate(data) {
		var amount = 0;
		for (var i = 0; i < data.length; i++) {
			amount += data[i].amount;
		}
		return (amount / 100).toFixed(2);
	}
	function calculateMoney(data) {
		var amount = 0;
		for (var i = 0; i < data.length; i++) {
			amount += parseInt(data[i].money);
		}
		return (amount / 100).toFixed(2);
	}
	// console.log(paid);
	console.log(user);

	return (
		<div className='row'>
			<div className='col-xl-12'>
				<div className='welcome-card rounded pl-5 pt-5 pb-4 mt-3 position-relative mb-5'>
					<h4 className='text-warning'>
						Welcome to Outz {user.city && <span>({user.city})</span>}! {store && <span>{store.name}</span>}
					</h4>
				</div>

				{/* <div className='row'>
					{user && user.member_level === 91 ? (
						<div className='col-xl-6 col-xxxl-12 col-lg-6'>
							<div className='card'>
								<div className='card-header border-0 pb-3 d-sm-flex d-block '>
									<h4 className='card-title'>Sales Summary</h4>
									<div className='d-flex mt-3 mt-sm-0'></div>
								</div>
								<div className='card-body'>
									<div className='row mx-0 align-items-center'>
										<div className='col-sm-8 col-md col-xxl d-flex justify-content-between px-0  a mb-3 mb-sm-0'>
											<div className='col px-0'>
												<span className='bg-warning' />
												<div>
													<p className='fs-14'>No. of Sales(Today)</p>
													{Array.isArray(today) ? (
														<h3>{today.length === 0 ? '0' : today.length}</h3>
													) : (
														<h3>0</h3>
													)}
												</div>
											</div>
											<div className='col px-0'>
												<span className='bg-primary' />
												<div>
													<p className='fs-14'>Daily Sales Amount</p>
													<h3>{today.length === 0 ? '$ 0' : '$' + calculate(today)}</h3>
												</div>
											</div>
										</div>
									</div>
									<div className='row mx-0 align-items-center'>
										<div className='col-sm-8 col-md col-xxl d-flex justify-content-between px-0  a mb-3 mb-sm-0'>
											<div className='col px-0'>
												<span className='bg-warning' />
												<div>
													<p className='fs-14'>No. of Sales(This week)</p>
													{Array.isArray(week) ? (
														<h3>{week.length === 0 ? '0' : week.length}</h3>
													) : (
														<h3>0</h3>
													)}
												</div>
											</div>
											<div className='col px-0'>
												<span className='bg-primary' />
												<div>
													<p className='fs-14'>Weekly Sales Amount</p>

													{Array.isArray(week) ? (
														<h3>{week.length === 0 ? '$ 0' : '$' + calculate(week)}</h3>
													) : (
														<h3>$ 0</h3>
													)}
												</div>
											</div>
										</div>
									</div>
									<div className='row mx-0 align-items-center'>
										<div className='col-sm-8 col-md col-xxl d-flex justify-content-between px-0  a mb-3 mb-sm-0'>
											<div className='col px-0'>
												<span className='bg-warning' />
												<div>
													<p className='fs-14'>No. of Sales(This month)</p>
													{Array.isArray(month) ? (
														<h3>{month.length === 0 ? '0' : month.length}</h3>
													) : (
														<h3>0</h3>
													)}
												</div>
											</div>
											<div className='col px-0'>
												<span className='bg-primary' />
												<div>
													<p className='fs-14'>Monthly Sales Amount</p>
													{Array.isArray(month) ? (
														<h3>{month.length === 0 ? '$ 0' : '$' + calculate(month)}</h3>
													) : (
														<h3>$ 0</h3>
													)}
												</div>
											</div>
										</div>
									</div>

									<div className='row mx-0 align-items-center'>
										<div className='col-sm-8 col-md col-xxl d-flex justify-content-between px-0  a mb-3 mb-sm-0'>
											<div className='col px-0'>
												<span className='bg-warning' />
												<div>
													<p className='fs-14'>No. of Sales(Last month)</p>
													{Array.isArray(lastMonth) ? (
														<h3>{lastMonth.length === 0 ? '0' : lastMonth.length}</h3>
													) : (
														<h3>0</h3>
													)}
												</div>
											</div>
											<div className='col px-0'>
												<span className='bg-primary' />
												<div>
													<p className='fs-14'>Last Month Amount</p>
													{Array.isArray(lastMonth) ? (
														<h3>
															{lastMonth.length === 0
																? '$ 0'
																: '$' + calculate(lastMonth)}
														</h3>
													) : (
														<h3>$ 0</h3>
													)}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					) : (
						user.member_level === 92 && (
							<div className='row'>
								<div className='col-xl-12 pl-5 pt-5 pb-4 mt-3 position-relative mb-5 '>
									<h5 style={{ color: 'red' }}>
										You are currently login with a staff account, if you would like to see a summary
										please login with a business owner account!
									</h5>
								</div>
							</div>
						)
					)}
					{user && user.member_level === 255 && (
						<div className='col-xl col-xxxl col-lg'>
							<div className='card'>
								<div className='card-header border-0 pb-3 d-sm-flex d-block '>
									<h4 className='card-title'>Summary</h4>
									<div className='d-flex mt-3 mt-sm-0'></div>
								</div>
								<div className='card-body'>
									<div className='row mx-0 align-items-center'>
										<div className='col-sm-8 col-md col-xxl d-flex justify-content-between px-0  a mb-3 mb-sm-0'>
											<div className='col px-0'>
												<span className='bg-warning' />
												<div>
													<p className='fs-14'>No. of Register User(Today)</p>
													{Array.isArray(dailyUser) ? (
														<h3>{dailyUser.length === 0 ? '0' : dailyUser.length}</h3>
													) : (
														<h3>0</h3>
													)}
												</div>
											</div>
											<div className='col px-0'>
												<span className='bg-primary' />
												<div>
													<p className='fs-14'>Total Users</p>
													<h3>{allUsers.length === 0 ? '0' : allUsers.length}</h3>
												</div>
											</div>
										</div>
									</div>

									<div className='row mx-0 align-items-center'>
										<div className='col-sm-8 col-md col-xxl d-flex justify-content-between px-0  a mb-3 mb-sm-0'>
											<div className='col px-0'>
												<span className='bg-warning' />
												<div>
													<p className='fs-14'>No. of Consumed Order (Today)</p>
													{Array.isArray(dailySpend) ? (
														<h3>{dailySpend.length === 0 ? '0' : dailySpend.length}</h3>
													) : (
														<h3>0</h3>
													)}
												</div>
											</div>
											<div className='col px-0'>
												<span className='bg-primary' />
												<div>
													<p className='fs-14'>Consumed Amount (Today)</p>
													{Array.isArray(dailySpend) ? (
														<h3>
															{dailySpend.length === 0
																? '$ 0'
																: '$' + calculate(dailySpend)}
														</h3>
													) : (
														<h3>$ 0</h3>
													)}
												</div>
											</div>
										</div>
									</div>

									<div className='row mx-0 align-items-center'>
										<div className='col-sm-8 col-md col-xxl d-flex justify-content-between px-0  a mb-3 mb-sm-0'>
											<div className='col px-0'>
												<span className='bg-warning' />
												<div>
													<p className='fs-14'>No. of Paid (Today)</p>
													{Array.isArray(paid) ? (
														<h3>{paid.length === 0 ? '0' : paid.length}</h3>
													) : (
														<h3>0</h3>
													)}
												</div>
											</div>
											<div className='col px-0'>
												<span className='bg-primary' />
												<div>
													<p className='fs-14'>Paid Amount (Today)</p>
													{Array.isArray(paid) ? (
														<h3>
															{paid.length === 0 ? '$ 0' : '$' + calculateMoney(paid)}
														</h3>
													) : (
														<h3>$ 0</h3>
													)}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					)}
					{(user && user.member_level === 91) ||
						(user.member_level === 92 && store.length > 0 && (
							<div className='col-xl-6 col-xxxl-12 col-lg-6'>
								<div className='card'>
									<div className='card-header border-0 pb-3 d-sm-flex d-block '>
										<h4 className='card-title'>Store Information</h4>
										<div className='d-flex mt-3 mt-sm-0'></div>
									</div>
									<div className='card-body'>
										{store.name && (
											<div className='row mx-0 align-items-center'>
												<div className='col-sm-8 col-md col-xxl d-flex justify-content-between px-0  a mb-3 mb-sm-0'>
													<div className='col px-0 mb-4'>
														<div>
															<h1 className='fs-14'>Store Name</h1>
															<span>{store.name}</span>
														</div>
													</div>
												</div>
											</div>
										)}
										{store.tel && (
											<div className='row mx-0 align-items-center'>
												<div className='col-sm-8 col-md col-xxl d-flex justify-content-between px-0  a mb-3 mb-sm-0'>
													<div className='col px-0 mb-4'>
														<div>
															<h1 className='fs-14'>Phone Number</h1>
															<span>{store.tel}</span>
														</div>
													</div>
												</div>
											</div>
										)}
										{store.address1 && store.address2 && (
											<div className='row mx-0 align-items-center'>
												<div className='col-sm-8 col-md col-xxl d-flex justify-content-between px-0  a mb-3 mb-sm-0'>
													<div className='col px-0'>
														<div className='col px-0 mb-4'>
															<div>
																<h1 className='fs-14'>Address</h1>
																<span>
																	{store.address1}
																	{', '}
																	{store.address2}
																</span>
															</div>
														</div>
													</div>
												</div>
											</div>
										)}
									</div>
								</div>
							</div>
						))} 
				</div>*/}
			</div>
		</div>
	);
};

export default Home;
