import { Alert, Badge, Button, Card, Col, Media, Row } from "react-bootstrap";
import { Link, Redirect, useParams } from "react-router-dom";
import {
	RefreshAccessToken,
	confirmOrder,
	getOrderDetail,
	matchVoucher,
} from "../../api/api";
import { useEffect, useState } from "react";

import { Crypto } from "crypto-js";
import React from "react";
import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";
import { useHistory } from "react-router-dom";

const OrderList = () => {
	const [order, setOrder] = useState([]);
	const [match, setMatch] = useState(false);
	const [md5, setMd5] = useState("");
	const [token, setToken] = useState("");
	const [success, setSuccess] = useState(false);
	const [used, setUsed] = useState(false);
	const [clicked, setClicked] = useState(false);
	const history = useHistory();
	const [user, setUser] = useState(
		JSON.parse(localStorage.getItem("current_user"))
	);
	// var user = JSON.parse(localStorage.getItem('current_user'));
	useEffect(() => {
		async function getOrder() {
			const a = window.location.href;
			const u = a.split("?");
			const mid = u[u.length - 1]; //Metric id from the URL
			// setToken(user.access_token);
			if (mid.charAt(0) != "o") {
			} else {
				const orderinfo = mid.split("&");
				var resuserid = orderinfo[0].split("=")[1];
				var resid = orderinfo[1].split("=")[1];
				var userid = orderinfo[2].split("=")[1];
				var typeid = orderinfo[3].split("=")[1];
				var md5 = orderinfo[4].split("=")[1];
				if (orderinfo.length > 5) {
					if (orderinfo[5].split("=")[1]) {
						var token = orderinfo[5].split("=")[1];

						var newuser = await RefreshAccessToken(token);
						// console.log(newuser)
						// console.log(resid)
						setUser(newuser.data);
						const data = await getOrderDetail(
							newuser.data.access_token,
							resuserid
						);
						const orderData = data.data[0];
						if (newuser.data.resid == resid.toString()) {
							// console.log("match");
							if (
								resuserid === orderData.id.toString() &&
								// resid === orderData[0].resid &&
								userid === orderData.userid &&
								typeid === orderData.typeid
							) {
								// console.log(data.data)
								setMatch(true);
								setOrder(data.data);
							}
						} else {
						}
					}
				} else {
					if (resid.toString() === user.resid) {
						const data = await getOrderDetail(
							user.access_token,
							resuserid
						);
						const orderData = data.data[0];

						if (
							resuserid === orderData.id.toString() &&
							// resid === orderData[0].resid &&
							userid === orderData.userid &&
							typeid === orderData.typeid
						) {
							setMatch(true);
							setOrder(data.data);
						}
					} else {
						return null;
					}
				}
				setMd5(md5);
			}
		}
		getOrder();
	}, []);

	async function handleConfirm() {
		setClicked(true);
		// console.log("log submit")
		const click = await confirmOrder(
			user.access_token,
			order[0].id.toString(),
			order[0].resid,
			order[0].userid,
			order[0].type,
			order[0].typeid,
			user.id,
			order[0].usednumber,
			null,
			order[0].rules,
			md5,
			order[0].servicetype,
			order[0].price,
			null,
			order[0].name
		);
		if (click.ret === 200) {
			setSuccess(true);
			setMatch(false);
			// return <Alert>确认成功</Alert>;
		}
	}
	useEffect(() => {
		async function seekVoucher() {
			const a = window.location.href;
			const u = a.split("?");
			const mid = u[u.length - 1]; //Metric id from the URL
			const orderinfo = mid.split("&");
			if (orderinfo.length > 5) {
				var token = orderinfo[5].split("=")[1];
				const newToken = await RefreshAccessToken(token);
				const officialuse = await matchVoucher(
					newToken.access_token,
					md5
				);
				if (Array.isArray(officialuse.data)) {
					setUsed(true);
				}
			} else {
				const officialuse = await matchVoucher(user.access_token, md5);
				if (Array.isArray(officialuse.data)) {
					setUsed(true);
				}
			}
		}
		seekVoucher();
	}, []);

	return (
		<>
			<div className='page-titles'>
				<ol className='breadcrumb'>
					{/* <li className="breadcrumb-item">
                        <Link to="/analytics">Dashboard</Link>
                    </li> */}
					<li className='breadcrumb-item active'>
						<Link to='/analytics'>Order</Link>
					</li>
				</ol>
			</div>
			<div className='row'>
				<div className='col-xl-12 col-xxl-12 col-lg-12 col-md-12 col-sm-12'>
					<div className='row'>
						<div className='col-xl-12'>
							<div className='card'>
								{match && (
									<div>
										<div className='card-header border-0 pb-0 d-sm-flex d-block'>
											<h4 className='card-title mb-1'>
												Order details
											</h4>
										</div>
										{Array.isArray(order) &&
											order.length > 0 &&
											order.map((item) => (
												<div className='card-body pt-0 p-0'>
													{/* {item.resname && (
                                                        <div className="align-items-center row mx-0 border-bottom p-4">
                                                            restrurant name：{item.resname}
                                                        </div>
                                                    )} */}
													{/* {item.content && (
                                                        <div className="align-items-center row mx-0 border-bottom p-4">
                                                            Name：{item.content}
                                                        </div>
                                                    )} */}
													{/* {item.money && (
                                                        <div className="align-items-center row mx-0 border-bottom p-4">
                                                            Money：$
                                                            {item.money / 100 > 1
                                                                ? item.money / 100 + '.00'
                                                                : item.money / 100}
                                                        </div>
                                                    )} */}
													{item.name && (
														<div className='align-items-center row mx-0 border-bottom p-4'>
															Name：{item.name}
														</div>
													)}
													{/* {item.id && (
                                                        <div className="align-items-center row mx-0 border-bottom p-4">
                                                            Paid：
                                                            {item.paid === 1 ? 'Paid' : 'Unpaid'}
                                                        </div>
                                                    )} */}
													{/* {item.paid === 1 && (
                                                        <div className="align-items-center row mx-0 border-bottom p-4">
                                                            Paid time：{item.paidtime}
                                                        </div>
                                                    )} */}
													{/* {item.pic && (
                                                        <div className="align-items-center justify-content-center border-bottom d-flex">
                                                            <img
                                                                className="align-items-center row mx-0 border-bottom p-4 img-thumbnail"
                                                                src={item.pic}
                                                                alt="pic"
                                                                style={{
                                                                    width: '300px',
                                                                    height: '300px',
                                                                }}
                                                            />
                                                        </div>
                                                    )}
                                                    {item.pic2 && (
                                                        <div className="align-items-center row mx-0 border-bottom p-4">
                                                            Pic2：{item.pic2}
                                                        </div>
                                                    )}
                                                    {item.pic3 && (
                                                        <div className="align-items-center row mx-0 border-bottom p-4">
                                                            Pic3：{item.pic3}
                                                        </div>
                                                    )}
                                                    {item.pic4 && (
                                                        <div className="align-items-center row mx-0 border-bottom p-4">
                                                            Pic4：{item.pic4}
                                                        </div>
                                                    )}
                                                    {item.pic5 && (
                                                        <div className="align-items-center row mx-0 border-bottom p-4">
                                                            Pic5：{item.pic5}
                                                        </div>
                                                    )}
                                                    {item.pic6 && (
                                                        <div className="align-items-center row mx-0 border-bottom p-4">
                                                            Pic6：{item.pic6}
                                                        </div>
                                                    )}
                                                    {item.pic7 && (
                                                        <div className="align-items-center row mx-0 border-bottom p-4">
                                                            Pic7：{item.pic7}
                                                        </div>
                                                    )}
                                                    {item.pic8 && (
                                                        <div className="align-items-center row mx-0 border-bottom p-4">
                                                            Pic8：{item.pic8}
                                                        </div>
                                                    )}
                                                    {item.pic9 && (
                                                        <div className="align-items-center row mx-0 border-bottom p-4">
                                                            Pic9：{item.pic9}
                                                        </div>
                                                    )}
                                                    {item.pic10 && (
                                                        <div className="align-items-center row mx-0 border-bottom p-4">
                                                            Pic 10：{item.pic10}
                                                        </div>
                                                    )} */}
													{item.price && (
														<div className='align-items-center row mx-0 border-bottom p-4'>
															Price：$
															{item.price / 100}
														</div>
													)}
													{item.credit && (
														<div className='align-items-center row mx-0 border-bottom p-4'>
															Credit：$
															{item.credit / 100}
														</div>
													)}
													{/* {item.resid && (
                                                        <div className="align-items-center row mx-0 border-bottom p-4">
                                                            restrurant Id：{item.resid}
                                                        </div>
                                                    )} */}

													{!used &&
													!(
														item.usednumber >=
														item.buynumber
													) ? (
														<div className='row col align-items-center d-flex justify-content-center'>
															<button
																className='btn btn-primary items-center'
																onClick={
																	handleConfirm
																}
																disable={
																	clicked
																}
																style={{
																	marginTop:
																		"10px",
																}}
															>
																Confirm
															</button>
														</div>
													) : (
														<div
															className='row col align-items-center d-flex justify-content-center'
															style={{
																marginTop:
																	"10px",
															}}
														>
															<h4>
																The Voucher has
																been used!
															</h4>
														</div>
													)}
													{item.rules && (
														<div
															className='align-items-center row mx-0 border-bottom p-4'
															style={{
																whiteSpace:
																	"pre-wrap",
															}}
														>
															Rules:{"\n"}
															{JSON.parse(
																item.rules
															)
																.toString()
																.replace(
																	",",
																	"\n"
																)
																.split("\\")}
														</div>
													)}
												</div>
											))}
									</div>
								)}
								{!match && !success && (
									<div
										className='card-header border-0 pb-0 d-sm-flex d-block align-items-center justify-content-center '
										style={{ height: "100px" }}
									>
										<h4 className='card-title mb-1'>
											No Match Order
										</h4>
									</div>
								)}
								{success && (
									<div
										className='card-header border-0 pb-0 d-sm-flex d-block align-items-center justify-content-center '
										style={{ height: "100px" }}
									>
										<h4 className='card-title mb-1'>
											Order Confirmed!{" "}
										</h4>
										{/* <Redirect to="/" /> */}
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default OrderList;
