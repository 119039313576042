import { Alert, Button, Card, Col, Form, Modal, Nav, Row } from 'react-bootstrap';
import { CityidgetRestaurant, ClWorkorder, DeleteWorkorder, GetRestaurant, ShWorkorder } from '../../api/api';
import { Link, useHistory } from 'react-router-dom';
import React, { useCallback, useEffect, useState } from 'react';

import BootstrapTable from 'react-bootstrap-table-next';
import QRCode from 'qrcode.react';
import paginationFactory from 'react-bootstrap-table2-paginator';

// import productData from "../productData";

const QRcode = () => {
	//** Dynamic Key Single Place */
	const user = JSON.parse(localStorage.getItem('current_user') || '{}');
	const history = useHistory();
	const [adminID, setAdminID] = useState();
	const [admin2ID, setAdmin2ID] = useState();
	const [admin3ID, setAdmin3ID] = useState();
	const [businesses, setBusinesses] = useState([]);
	const [store, setStore] = useState('');
	const [table, setTable] = useState('');
	console.log('=> table', table);
	console.log('=> store', store);

	const handleGetRes = async () => {
		const getRestaurant = await GetRestaurant(user.access_token);
		setBusinesses(getRestaurant.data);
	};

	const onImageDownload = () => {
		const canvas = document.getElementById('QRCode');
		const pngUrl = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream');
		let downloadLink = document.createElement('a');
		downloadLink.href = pngUrl;
		downloadLink.download = `r${store}-t${table}.png`;
		document.body.appendChild(downloadLink);
		downloadLink.click();
		document.body.removeChild(downloadLink);
	};

	useEffect(() => {
		if (user.cityid <= 1) {
			handleGetRes();
		} else {
			cityGetRes();
		}
	}, []);
	const cityGetRes = async () => {
		const storeData = CityidgetRestaurant(user.access_token, user.cityid);
		setBusinesses(storeData.data);
	};
	let url = `http://res.outz.com.au/r/:res=${store}&type=0?t=${table}`;

	return (
		<div className='h-80'>
			<div className='row'>
				<div className='col-lg-12'>
					<div className='card'>
						<div className='card-body'>
							<label htmlFor='store'>Select Business(选择商家)</label>

							<Form.Control
								as='select'
								id='store'
								// custom
								onChange={(e) => setStore(e.target.value)}>
								<option></option>
								{Array.isArray(businesses) &&
									businesses.length > 0 &&
									businesses.map((item) => (
										<option key={item.id} value={item.id}>
											{item.name}
										</option>
									))}
							</Form.Control>
							<label htmlFor='table-number'>Table number (填写桌号)</label>
							<input
								type='text'
								label='table-number'
								className='form-control'
								name='table-number'
								placeholder='Table number'
								onChange={(e) => setTable(e.target.value)}
							/>
							{/* 'http://res.outz.com.au/r/:res=80&type=0?t=10' */}
							<QRCode id='QRCode' style={{ marginTop: '20px' }} value={url} />
							<br />
							<Button onClick={onImageDownload}>download</Button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default QRcode;
