import {
	AdmingetWorkorder,
	ClWorkorder,
	DeleteWorkorder,
	ShWorkorder,
} from "../../api/api";
import {
	Alert,
	Button,
	Card,
	Col,
	Form,
	Modal,
	Nav,
	Row,
} from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import React, { useCallback, useEffect, useState } from "react";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";

// import productData from "../productData";

const WorkOrder = () => {
	//** Dynamic Key Single Place */
	const user = JSON.parse(localStorage.getItem("current_user") || "{}");
	const history = useHistory();
	const [adminID, setAdminID] = useState();
	const [admin2ID, setAdmin2ID] = useState();
	const [admin3ID, setAdmin3ID] = useState();

	const [content1, setContent1] = useState();
	const [content2, setContent2] = useState();

	const [content3, setContent3] = useState();

	const [workOrder, setWorkorder] = useState([]);

	console.log("workorder", workOrder);
	console.log(user);

	useEffect(() => {
		async function getData() {
			const data = await AdmingetWorkorder(user.access_token);
			console.log(data);
			Array.isArray(data.data) && setWorkorder(data.data);
		}
		getData();
	}, []);

	const handleSecondCheck = async (workOrderID) => {
		const data = await ShWorkorder(
			user.access_token,
			workOrderID,
			user.id,
			content2
		);
		console.log(data);
	};

	const handleFinalCheck = async (workOrderID) => {
		const data = await ClWorkorder(
			user.access_token,
			workOrderID,
			user.id,
			content3
		);
		console.log(data);
	};

	const handleSubmit = async (item) => {
		console.log("111");
		if (item.content1 && item.content1 != "undefined") {
			console.log("222");

			if (content2) {
				handleSecondCheck(item.id);
				setAdmin2ID();
				setContent2();
				window.location.reload();
			} else {
				if (item.content2 && item.content2 != "undefined") {
					console.log("333");
					if (content3) {
						handleFinalCheck(item.id);
						window.location.reload();
					} else {
						handleAlert();
					}
				}
			}
		}
	};

	const handleAlert = () => {
		alert("Please enter content first!");
	};

	const handleDelete = async (id) => {
		const data = await DeleteWorkorder(user.access_token, id);
		console.log(data);
		window.location.reload();
	};

	return (
		<div className='h-80'>
			<div className='row'>
				<div className='col-lg-12'>
					<div className='card'>
						<div className='card-body'>
							{workOrder.length > 0 ? (
								workOrder.map((item, i) => (
									<div className='card-body'>
										<Form>
											<Row>
												<Form.Group as={Col}>
													<h3>
														Work Order ID: {item.id}
													</h3>
												</Form.Group>
												<Form.Group as={Col}>
													<h3
														style={{ color: "red" }}
													>
														Status:{item.status}
													</h3>
												</Form.Group>
											</Row>
											<Row>
												<Form.Group as={Col}>
													<h5
														style={{
															color: "#ff0000",
														}}
													>
														Only white area can
														edit!
													</h5>
												</Form.Group>
											</Row>
											<Row>
												<Form.Group as={Col}>
													<Form.Label>
														User ID
													</Form.Label>
													<Form.Control
														placeholder='Non-editable'
														disabled
														style={{
															backgroundColor:
																"#eceaea",
														}}
														type='text'
														value={item.userid}
													/>
												</Form.Group>
												<Form.Group as={Col}>
													<Form.Label>
														Feedback ID
													</Form.Label>
													<Form.Control
														placeholder='Non-editable'
														disabled
														style={{
															backgroundColor:
																"#eceaea",
														}}
														type='text'
														value={item.feedbackid}
													/>
												</Form.Group>
												<Form.Group as={Col}>
													<Form.Label>
														Order ID
													</Form.Label>
													<Form.Control
														placeholder='Non-editable'
														disabled
														style={{
															backgroundColor:
																"#eceaea",
														}}
														value={item.resuserid}
													/>
												</Form.Group>
											</Row>
											<Form.Group className='mb-3'>
												<Form.Label>
													Feedback Content
												</Form.Label>
												<Form.Control
													as='textarea'
													placeholder='Non-editable'
													style={{ height: "80px" }}
													disabled
													style={{
														backgroundColor:
															"#eceaea",
													}}
													value={item.feedbackcontent}
												/>
											</Form.Group>

											<Row className='mb-3'>
												<Form.Group as={Col}>
													<Form.Label>
														Admin 1 ID
													</Form.Label>
													<Form.Control
														placeholder='Enter admin ID'
														value={
															item.admin1id
																? item.admin1id
																: user.id
														}
														disabled={item.admin1id}
														style={{
															backgroundColor:
																"#eceaea",
														}}
													/>
												</Form.Group>

												<Form.Group as={Col}>
													<Form.Label>
														Date 1
													</Form.Label>
													<Form.Control
														placeholder='Non-editable'
														disabled
														type='text'
														style={{
															backgroundColor:
																"#eceaea",
														}}
														value={item.timestamp1}
													/>
												</Form.Group>
											</Row>

											<Form.Group className='mb-3'>
												<Form.Label>
													Content 1
												</Form.Label>
												<Form.Control
													as='textarea'
													placeholder='Leave a comment here'
													style={{ height: "80px" }}
													value={item.content1}
													disabled={item.content1}
													style={{
														backgroundColor:
															"#eceaea",
													}}
												/>
											</Form.Group>

											{item.admin1id &&
												item.content1 !==
													"undefined" && (
													<>
														<Row className='mb-3'>
															<Form.Group
																as={Col}
															>
																<Form.Label>
																	Admin 2 ID
																</Form.Label>
																<Form.Control
																	placeholder='Enter admin ID'
																	value={
																		item.admin2id
																			? item.admin2id
																			: user.id
																	}
																	disabled
																	style={{
																		backgroundColor:
																			"#eceaea",
																	}}
																/>
															</Form.Group>

															<Form.Group
																as={Col}
															>
																<Form.Label>
																	Date 2
																</Form.Label>
																<Form.Control
																	placeholder='Non-editable'
																	disabled
																	type='text'
																	style={{
																		backgroundColor:
																			"#eceaea",
																	}}
																	value={
																		item.timestamp2
																			? item.timestamp2
																			: ""
																	}
																/>
															</Form.Group>
														</Row>

														<Form.Group className='mb-3'>
															<Form.Label>
																Content 2
															</Form.Label>
															<Form.Control
																as='textarea'
																placeholder='Leave a comment here'
																style={{
																	height: "80px",
																}}
																onChange={(e) =>
																	setContent2(
																		e.target
																			.value
																	)
																}
																value={
																	item.content2 &&
																	item.content2 !=
																		"undefined"
																		? item.content2
																		: content2
																}
																disabled={
																	item.content2 &&
																	item.content2 !=
																		"undefined"
																}
																style={{
																	backgroundColor:
																		item.content2 &&
																		item.content2 !=
																			"undefined"
																			? "#eceaea"
																			: "",
																}}
															/>
														</Form.Group>
													</>
												)}
											{item.admin2id && (
												<>
													<Row className='mb-3'>
														<Form.Group as={Col}>
															<Form.Label>
																Admin 3 ID
															</Form.Label>
															<Form.Control
																placeholder='Enter admin ID'
																value={
																	item.admin3id
																		? item.admin3id
																		: user.id
																}
																disabled
																style={{
																	backgroundColor:
																		"#eceaea",
																}}
															/>
														</Form.Group>

														<Form.Group as={Col}>
															<Form.Label>
																Date 3
															</Form.Label>
															<Form.Control
																placeholder='Non-editable'
																disabled
																type='text'
																style={{
																	backgroundColor:
																		"#eceaea",
																}}
																value={
																	item.timestamp3
																		? item.timestamp3
																		: ""
																}
															/>
														</Form.Group>
													</Row>

													<Form.Group className='mb-3'>
														<Form.Label>
															Content 3
														</Form.Label>
														<Form.Control
															as='textarea'
															placeholder='Leave a comment here'
															style={{
																height: "80px",
															}}
															value={
																item.content3 &&
																item.content3 !=
																	"undefined"
																	? item.content3
																	: content3
															}
															disabled={
																item.content3 &&
																item.content3 !=
																	"undefined"
															}
															style={{
																backgroundColor:
																	item.content3 &&
																	item.content3 !=
																		"undefined"
																		? "#eceaea"
																		: "",
															}}
															onChange={(e) =>
																setContent3(
																	e.target
																		.value
																)
															}
														/>
													</Form.Group>
												</>
											)}
											<Row
												style={{
													justifyContent: "center",
												}}
											>
												<Button
													variant='primary'
													onClick={() =>
														handleSubmit(item)
													}
													style={{
														marginRight: "10px",
													}}
												>
													Submit
												</Button>
												<Button
													variant='primary'
													onClick={() =>
														handleDelete(item.id)
													}
												>
													Delete
												</Button>
											</Row>
										</Form>
										<hr />
									</div>
								))
							) : (
								<div
									style={{
										justifyContent: "center",
										alignItems: "center",
										display: "flex",
									}}
								>
									<h2>Please Add Work Order First!</h2>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default WorkOrder;
