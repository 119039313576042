import React, { Component } from 'react';

import { Link } from 'react-router-dom';
import MetisMenu from 'metismenujs';
import PerfectScrollbar from 'react-perfect-scrollbar';

/// Link

/// Menu

/// Scroll

///
// import drump from "../../../images/card/drump.png";

class MM extends Component {
	componentDidMount() {
		this.$el = this.el;
		this.mm = new MetisMenu(this.$el);
	}
	componentWillUnmount() {
		//  this.mm("dispose");
		// //console.log(this.mm);
	}
	render() {
		return (
			<div className='mm-wrapper'>
				<ul className='metismenu' ref={(el) => (this.el = el)}>
					{this.props.children}
				</ul>
			</div>
		);
	}
}

class SideBar extends Component {
	constructor(props) {
		super(props);
		this.state = {
			member: '',
		};
	}
	/// Open menu
	componentDidMount() {
		// sidebar open/close
		var btn = document.querySelector('.nav-control');
		var aaa = document.querySelector('#main-wrapper');

		function toggleFunc() {
			return aaa.classList.toggle('menu-toggle');
		}

		btn.addEventListener('click', toggleFunc);

		const user = JSON.parse(localStorage.getItem('current_user') || '{}');
		//console.log('--------current_user', user);
		// const parseUser = JSON.parse(user);
		this.setState({
			member: user,
		});
	}
	render() {
		/// Path
		let path = window.location.pathname;
		path = path.split('/');
		path = path[path.length - 1];
		const { member } = this.state;

		/// Active menu
		let deshBoard = ['', 'analytics', 'statement', 'events', 'order-list', 'customer-list', 'reviews', 'setting'],
			app = [
				'app-profile',
				'app-calender',
				'email-compose',
				'email-inbox',
				'email-read',
				'ecom-product-grid',
				'ecom-product-list',
				'ecom-product-list',
				'ecom-product-order',
				'ecom-checkout',
				'ecom-invoice',
				'ecom-customers',
				'post-details',
				'ecom-product-detail',
			],
			email = ['email-compose', 'email-inbox', 'email-read'],
			management = [
				'bubble-tea',
				'beauty',
				'entertainment',
				'restaurant',
				'supermarket',
				'movie-coupon',
				'movie',
				'massage',
				'barber',
				'fitness',
				'car-repairs',
				// "customers",
			],
			orders = ['statistics', 'orders', 'not-consumed', 'rank'],
			ad = ['advertisement'],
			charts = [
				'chart-rechart',
				'chart-flot',
				'chart-chartjs',
				'chart-chartist',
				'chart-sparkline',
				'chart-apexchart',
			],
			users = ['feedback', 'customers'],
			bootstrap = [
				'ui-accordion',
				'ui-badge',
				'ui-alert',
				'ui-button',
				'ui-modal',
				'ui-button-group',
				'ui-list-group',
				'ui-media-object',
				'ui-card',
				'ui-carousel',
				'ui-dropdown',
				'ui-popover',
				'ui-progressbar',
				'ui-tab',
				'ui-typography',
				'ui-pagination',
				'ui-grid',
			],
			plugins = ['uc-select2', 'uc-nestable', 'uc-sweetalert', 'uc-toastr', 'uc-noui-slider', 'map-jqvmap'],
			widget = ['widget-basic'],
			forms = ['form-element', 'form-wizard', 'form-editor-summernote', 'form-pickers', 'form-validation-jquery'],
			table = ['table-bootstrap-basic', 'table-datatable-basic'],
			pages = [
				'page-register',
				'page-login',
				'page-lock-screen',
				'page-error-400',
				'page-error-403',
				'page-error-404',
				'page-error-500',
				'page-error-503',
			],
			error = ['page-error-400', 'page-error-403', 'page-error-404', 'page-error-500', 'page-error-503'];
		console.log('aaaaaa', management.includes(path));

		return (
			<div className='deznav'>
				<PerfectScrollbar className='deznav-scroll'>
					<MM className='metismenu' id='menu'>
						<li className={`${deshBoard.includes(path) ? 'mm-active' : ''}`}>
							<Link className='has-arrow ai-icon' to='#' aria-expanded='false'>
								<i className='flaticon-381-networking'></i>
								<span className='nav-text'>Dashboard</span>
							</Link>
							<ul>
								<li>
									<Link
										className={`${path === '' ? 'mm-active' : ''}`}
										to='/'
										onClick={() => this.props.onClick3()}>
										Dashboard
									</Link>
								</li>
								{/* {member.member_level === 91 && (
									<li>
										<Link
											className={`${path === 'statement' ? 'mm-active' : ''}`}
											onClick={() => this.props.onClick()}
											to='/statement'>
											Statement
										</Link>
									</li>
								)} */}
								{member.member_level < 200 && (
									<li>
										<Link
											className={`${path === 'course-management' ? 'mm-active' : ''}`}
											onClick={() => this.props.onClick()}
											to='/course-management'>
											Course Management
										</Link>
									</li>
								)}
								{/* {member.member_level < 200 && (
									<li>
										<Link
											className={`${path === 'record' ? 'mm-active' : ''}`}
											onClick={() => this.props.onClick()}
											to='/record'>
											Record
										</Link>
									</li>
								)} */}
								{/* {(member.member_level == 91 || member.member_level == 92) && (
									<li>
										<Link
											className={`${path === 'order-management' ? 'mm-active' : ''}`}
											onClick={() => this.props.onClick()}
											to='/order-management'>
											Order Management
										</Link>
									</li>
								)} */}
								{/* {member.member_level === 91 && (
									<li>
										<Link
											className={`${path === 'setting' ? 'mm-active' : ''}`}
											onClick={() => this.props.onClick()}
											to='/setting'>
											Setting
										</Link>
									</li>
								)} */}
								{/* {member.member_level === 92 && (
									<li>
										<Link
											className={`${path === 'confirmed-list' ? 'mm-active' : ''}`}
											onClick={() => this.props.onClick()}
											to='/confirmed-list'>
											Confirmed Order
										</Link>
									</li>
								)} */}
							</ul>
						</li>
						{member.member_level >= 200 && (
							<li className={`${management.includes(path) ? 'mm-active' : ''}`}>
								<Link className='has-arrow ai-icon' to='#' aria-expanded='false'>
									<i className='flaticon-381-television'></i>
									<span className='nav-text'>Management</span>
								</Link>
								<ul aria-expanded='false'>
									{/* {member.member_level >= 200 && member.cityid <= 1 && (
										<li>
											<Link
												className={`${path === 'bubble-tea' ? 'mm-active' : ''}`}
												to='/bubble-tea'
												onClick={() => this.props.onClick()}>
												Bubble tea
											</Link>
										</li>
									)} */}
									{member.member_level >= 200 && (
										<>
											<li>
												<Link
													className={`${path === 'restaurant' ? 'mm-active' : ''}`}
													to='/restaurant'
													onClick={() => this.props.onClick()}>
													Restaurant
												</Link>
											</li>
											<li>
												<Link
													className={`${path === 'course-management' ? 'mm-active' : ''}`}
													onClick={() => this.props.onClick()}
													to='/course-management'>
													Course Management
												</Link>
											</li>
											<li>
												<Link
													className={`${path === 'course-management' ? 'mm-active' : ''}`}
													onClick={() => this.props.onClick()}
													to='/qr-code'>
													QR code
												</Link>
											</li>
										</>
									)}
									{/* {member.member_level >= 200 && member.cityid <= 1 && (
										<li>
											<Link
												className={`${path === 'beauty' ? 'mm-active' : ''}`}
												to='/beauty'
												onClick={() => this.props.onClick()}>
												Beauty
											</Link>
										</li>
									)}
									{member.member_level >= 200 && member.cityid <= 1 && (
										<li>
											<Link
												className={`${path === 'entertainment' ? 'mm-active' : ''}`}
												to='/entertainment'
												onClick={() => this.props.onClick()}>
												Entertainment
											</Link>
										</li>
									)}
									{member.member_level >= 200 && member.cityid <= 1 && (
										<li>
											<Link
												className={`${path === 'supermarket' ? 'mm-active' : ''}`}
												to='/supermarket'
												onClick={() => this.props.onClick()}>
												Supermarket
											</Link>
										</li>
									)}
									{member.member_level >= 200 && member.cityid <= 1 && (
										<li>
											<Link
												className={`${path === 'movie-coupon' ? 'mm-active' : ''}`}
												to='/movie-coupon'
												onClick={() => this.props.onClick()}>
												MovieCoupon
											</Link>
										</li>
									)}
									{member.member_level >= 200 && member.cityid <= 1 && (
										<li>
											<Link
												className={`${path === 'movie' ? 'mm-active' : ''}`}
												to='/movie'
												onClick={() => this.props.onClick()}>
												Movie
											</Link>
										</li>
									)}
									{member.member_level >= 200 && member.cityid <= 1 && (
										<li>
											<Link
												className={`${path === 'massage' ? 'mm-active' : ''}`}
												to='/massage'
												onClick={() => this.props.onClick()}>
												Massage
											</Link>
										</li>
									)}
									{member.member_level >= 200 && member.cityid <= 1 && (
										<li>
											<Link
												className={`${path === 'barber' ? 'mm-active' : ''}`}
												to='/barber'
												onClick={() => this.props.onClick()}>
												Barber
											</Link>
										</li>
									)}
									{member.member_level >= 200 && member.cityid <= 1 && (
										<li>
											<Link
												className={`${path === 'fitness' ? 'mm-active' : ''}`}
												to='/fitness'
												onClick={() => this.props.onClick()}>
												Fitness
											</Link>
										</li>
									)}
									{member.member_level >= 200 && member.cityid <= 1 && (
										<li>
											<Link
												className={`${path === 'car-repairs' ? 'mm-active' : ''}`}
												to='/car-repairs'
												onClick={() => this.props.onClick()}>
												Car Repair
											</Link>
										</li>
									)} */}
								</ul>
							</li>
						)}
						{/* {member.member_level === 255 && (
							<li className={`${orders.includes(path) ? 'mm-active' : ''}`}>
								<Link className='has-arrow ai-icon' to='#' aria-expanded='false'>
									<i className='flaticon-381-internet'></i>
									<span className='nav-text'>orders</span>
								</Link>
								<ul aria-expanded='false'>
									<li>
										<Link
											className={`${path === 'statistics' ? 'mm-active' : ''}`}
											to='/statistics'
											onClick={() => this.props.onClick()}>
											Statistics
										</Link>
									</li>

									<li>
										<Link
											className={`${path === 'not-consumed' ? 'mm-active' : ''}`}
											onClick={() => this.props.onClick()}
											to='/not-consumed'>
											Not Consumed
										</Link>
									</li>
									<li>
										<Link
											className={`${path === 'orders' ? 'mm-active' : ''}`}
											onClick={() => this.props.onClick()}
											to='/orders'>
											Orders
										</Link>
									</li>
									<li>
										<Link
											className={`${path === 'order-management' ? 'mm-active' : ''}`}
											onClick={() => this.props.onClick()}
											to='/order-management'>
											Order Management
										</Link>
									</li>
									<li>
										<Link
											className={`${path === 'rank' ? 'mm-active' : ''}`}
											onClick={() => this.props.onClick()}
											to='/rank'>
											Rank
										</Link>
									</li>
								</ul>
							</li>
						)} */}
						{/* {member.member_level === 255 && (
							<li
								className={`${
									member.member_level === 255 ? (users.includes(path) ? 'mm-active' : '') : ''
								}`}>
								<>
									<Link className='has-arrow ai-icon' to='#' aria-expanded='false'>
										<i className='flaticon-381-controls-3'></i>
										<span className='nav-text'>Users</span>
									</Link>
									<ul aria-expanded='false'>
										<li>
											<Link
												className={`${path === 'customers' ? 'mm-active' : ''}`}
												onClick={() => this.props.onClick()}
												to='/customers'>
												Users
											</Link>
										</li>
										<li>
											<Link
												className={`${path === 'search-ip' ? 'mm-active' : ''}`}
												onClick={() => this.props.onClick()}
												to='/search-ip'>
												Search IP
											</Link>
										</li>
										<li>
											<Link
												className={`${path === 'feedback' ? 'mm-active' : ''}`}
												to='/feedback'
												onClick={() => this.props.onClick3()}>
												Feedback
											</Link>
										</li>
										<li>
											<Link
												className={`${path === 'reviews' ? 'mm-active' : ''}`}
												to='/reviews'
												onClick={() => this.props.onClick3()}>
												Reviews
											</Link>
										</li>
									</ul>
								</>
							</li>
						)}{' '} */}
						{/* {member.member_level >= 200 && (
							<li
								className={`${
									member.member_level >= 200 ? (ad.includes(path) ? 'mm-active' : '') : ''
								}`}>
								<>
									<Link className='has-arrow ai-icon' to='#' aria-expanded='false'>
										<i className='flaticon-381-heart'></i>
										<span className='nav-text'>Utilization</span>
									</Link>
									<ul aria-expanded='false'>
										{member.cityid <= 1 && (
											<li>
												<Link
													className={`${path === 'advertisement' ? 'mm-active' : ''}`}
													onClick={() => this.props.onClick()}
													to='/advertisement'>
													Advertisement
												</Link>
											</li>
										)}
										{member.cityid <= 1 && (
											<li>
												<Link
													className={`${path === 'winning-pool' ? 'mm-active' : ''}`}
													onClick={() => this.props.onClick()}
													to='/winning-pool'>
													WinningPool
												</Link>
											</li>
										)}
										{member.cityid <= 1 && (
											<li>
												<Link
													className={`${path === 'work-order' ? 'mm-active' : ''}`}
													onClick={() => this.props.onClick()}
													to='/work-order'>
													WorkOrder
												</Link>
											</li>
										)}
										<li>
											<Link
												className={`${path === 'course-management' ? 'mm-active' : ''}`}
												onClick={() => this.props.onClick()}
												to='/course-management'>
												Course Management
											</Link>
										</li>
										<li>
											<Link
												className={`${path === 'course-management' ? 'mm-active' : ''}`}
												onClick={() => this.props.onClick()}
												to='/qr-code'>
												QR code
											</Link>
										</li>
									</ul>
								</>
							</li>
						)} */}
						<li className={`${charts.includes(path) ? 'mm-active' : ''}`}>
							{/* <Link
								className='has-arrow ai-icon'
								to='#'
								aria-expanded='false'
							>
								<i className='flaticon-381-controls-3'></i>
								<span className='nav-text'>Charts</span>
							</Link>
							<ul aria-expanded='false'>
								<li>
									<Link
										className={`${
											path === "chart-rechart"
												? "mm-active"
												: ""
										}`}
										onClick={() => this.props.onClick()}
										to='/chart-rechart'
									>
										RechartJs
									</Link>
								</li>
								<li>
									<Link
										className={`${
											path === "chart-chartjs"
												? "mm-active"
												: ""
										}`}
										onClick={() => this.props.onClick()}
										to='/chart-chartjs'
									>
										Chartjs
									</Link>
								</li>
								<li>
									<Link
										className={`${
											path === "chart-chartist"
												? "mm-active"
												: ""
										}`}
										onClick={() => this.props.onClick()}
										to='/chart-chartist'
									>
										Chartist
									</Link>
								</li>
								<li>
									<Link
										className={`${
											path === "chart-sparkline"
												? "mm-active"
												: ""
										}`}
										onClick={() => this.props.onClick()}
										to='/chart-sparkline'
									>
										Sparkline
									</Link>
								</li>
								<li>
									<Link
										className={`${
											path === "chart-apexchart"
												? "mm-active"
												: ""
										}`}
										onClick={() => this.props.onClick()}
										to='/chart-apexchart'
									>
										Apexchart
									</Link>
								</li>
							</ul> */}
						</li>
						{/* <li className={`${bootstrap.includes(path) ? 'mm-active' : ''}`}>
							<Link className='has-arrow ai-icon' to='#' aria-expanded='false'>
								<i className='flaticon-381-internet'></i>
								<span className='nav-text'>Bootstrap</span>
							</Link>
							<ul aria-expanded='false'>
								<li>
									<Link
										className={`${path === 'ui-accordion' ? 'mm-active' : ''}`}
										onClick={() => this.props.onClick()}
										to='/ui-accordion'>
										Accordion
									</Link>
								</li>
								<li>
									<Link
										className={`${path === 'ui-alert' ? 'mm-active' : ''}`}
										onClick={() => this.props.onClick()}
										to='/ui-alert'>
										Alert
									</Link>
								</li>
								<li>
									<Link
										className={`${path === 'ui-badge' ? 'mm-active' : ''}`}
										onClick={() => this.props.onClick()}
										to='/ui-badge'>
										Badge
									</Link>
								</li>
								<li>
									<Link
										className={`${path === 'ui-button' ? 'mm-active' : ''}`}
										onClick={() => this.props.onClick()}
										to='/ui-button'>
										Button
									</Link>
								</li>
								<li>
									<Link
										className={`${path === 'ui-modal' ? 'mm-active' : ''}`}
										onClick={() => this.props.onClick()}
										to='/ui-modal'>
										Modal
									</Link>
								</li>
								<li>
									<Link
										className={`${path === 'ui-button-group' ? 'mm-active' : ''}`}
										onClick={() => this.props.onClick()}
										to='/ui-button-group'>
										Button Group
									</Link>
								</li>
								<li>
									<Link
										className={`${path === 'ui-list-group' ? 'mm-active' : ''}`}
										onClick={() => this.props.onClick()}
										to='/ui-list-group'>
										List Group
									</Link>
								</li>
								<li>
									<Link
										className={`${path === 'ui-media-object' ? 'mm-active' : ''}`}
										onClick={() => this.props.onClick()}
										to='/ui-media-object'>
										Media Object
									</Link>
								</li>
								<li>
									<Link
										className={`${path === 'ui-card' ? 'mm-active' : ''}`}
										onClick={() => this.props.onClick()}
										to='/ui-card'>
										Cards
									</Link>
								</li>
								<li>
									<Link
										className={`${path === 'ui-carousel' ? 'mm-active' : ''}`}
										onClick={() => this.props.onClick()}
										to='/ui-carousel'>
										Carousel
									</Link>
								</li>
								<li>
									<Link
										className={`${path === 'ui-dropdown' ? 'mm-active' : ''}`}
										onClick={() => this.props.onClick()}
										to='/ui-dropdown'>
										Dropdown
									</Link>
								</li>
								<li>
									<Link
										className={`${path === 'ui-popover' ? 'mm-active' : ''}`}
										onClick={() => this.props.onClick()}
										to='/ui-popover'>
										Popover
									</Link>
								</li>
								<li>
									<Link
										className={`${path === 'ui-progressbar' ? 'mm-active' : ''}`}
										onClick={() => this.props.onClick()}
										to='/ui-progressbar'>
										Progressbar
									</Link>
								</li>
								<li>
									<Link
										className={`${path === 'ui-tab' ? 'mm-active' : ''}`}
										onClick={() => this.props.onClick()}
										to='/ui-tab'>
										Tab
									</Link>
								</li>
								<li>
									<Link
										className={`${path === 'ui-typography' ? 'mm-active' : ''}`}
										onClick={() => this.props.onClick()}
										to='/ui-typography'>
										Typography
									</Link>
								</li>
								<li>
									<Link
										className={`${path === 'ui-pagination' ? 'mm-active' : ''}`}
										onClick={() => this.props.onClick()}
										to='/ui-pagination'>
										Pagination
									</Link>
								</li>
								<li>
									<Link
										className={`${path === 'ui-grid' ? 'mm-active' : ''}`}
										onClick={() => this.props.onClick()}
										to='/ui-grid'>
										Grid
									</Link>
								</li>
							</ul>
						</li> */}
						{/* <li
							className={`${
								plugins.includes(path) ? "mm-active" : ""
							}`}
						>
							<Link
								className='has-arrow ai-icon'
								to='#'
								aria-expanded='false'
							>
								<i className='flaticon-381-heart'></i>
								<span className='nav-text'>Plugins</span>
							</Link>
							<ul aria-expanded='false'>
								<li>
									<Link
										className={`${
											path === "uc-select2"
												? "mm-active"
												: ""
										}`}
										onClick={() => this.props.onClick()}
										to='/uc-select2'
									>
										Select 2
									</Link>
								</li>
								<li>
									<Link
										className={`${
											path === "uc-nestable"
												? "mm-active"
												: ""
										}`}
										onClick={() => this.props.onClick()}
										to='/uc-nestable'
									>
										Nestedable
									</Link>
								</li>
								<li>
									<Link
										className={`${
											path === "uc-noui-slider"
												? "mm-active"
												: ""
										}`}
										onClick={() => this.props.onClick()}
										to='/uc-noui-slider'
									>
										Noui Slider
									</Link>
								</li>
								<li>
									<Link
										className={`${
											path === "uc-sweetalert"
												? "mm-active"
												: ""
										}`}
										onClick={() => this.props.onClick()}
										to='/uc-sweetalert'
									>
										Sweet Alert
									</Link>
								</li>
								<li>
									<Link
										className={`${
											path === "uc-toastr"
												? "mm-active"
												: ""
										}`}
										onClick={() => this.props.onClick()}
										to='/uc-toastr'
									>
										Toastr
									</Link>
								</li>
								<li>
									<Link
										className={`${
											path === "map-jqvmap"
												? "mm-active"
												: ""
										}`}
										onClick={() => this.props.onClick()}
										to='/map-jqvmap'
									>
										Jqv Map
									</Link>
								</li>
								<li>
									<Link
										className={`${
											path === "uc-lightgallery"
												? "mm-active"
												: ""
										}`}
										onClick={() => this.props.onClick()}
										to='/uc-lightgallery'
									>
										Light Gallery
									</Link>
								</li>
							</ul>
						</li> */}
						{/* <li
							className={`${
								widget.includes(path) ? "mm-active" : ""
							}`}
						>
							<Link
								to='widget-basic'
								className='ai-icon'
								aria-expanded='false'
							>
								<i className='flaticon-381-settings-2'></i>
								<span className='nav-text'>Widget</span>
							</Link>
						</li>
						<li
							className={`${
								forms.includes(path) ? "mm-active" : ""
							}`}
						>
							<Link
								className='has-arrow ai-icon'
								to='#'
								aria-expanded='false'
							>
								<i className='flaticon-381-notepad'></i>
								<span className='nav-text forms'>Forms</span>
							</Link>
							<ul aria-expanded='false'>
								<li>
									<Link
										className={`${
											path === "form-element"
												? "mm-active"
												: ""
										}`}
										onClick={() => this.props.onClick()}
										to='/form-element'
									>
										Form Elements
									</Link>
								</li>
								<li>
									<Link
										className={`${
											path === "form-wizard"
												? "mm-active"
												: ""
										}`}
										onClick={() => this.props.onClick()}
										to='/form-wizard'
									>
										Wizard
									</Link>
								</li>
								<li>
									<Link
										className={`${
											path === "form-editor-summernote"
												? "mm-active"
												: ""
										}`}
										onClick={() => this.props.onClick()}
										to='/form-editor-summernote'
									>
										Summernote
									</Link>
								</li>
								<li>
									<Link
										className={`${
											path === "form-pickers"
												? "mm-active"
												: ""
										}`}
										onClick={() => this.props.onClick()}
										to='/form-pickers'
									>
										Pickers
									</Link>
								</li>
								<li>
									<Link
										className={`${
											path === "form-validation-jquery"
												? "mm-active"
												: ""
										}`}
										onClick={() => this.props.onClick()}
										to='/form-validation-jquery'
									>
										Jquery Validate
									</Link>
								</li>
							</ul>
						</li> */}
						{/* <li
							className={`${
								table.includes(path) ? "mm-active" : ""
							}`}
						>
							<Link
								className='has-arrow ai-icon'
								to='#'
								aria-expanded='false'
							>
								<i className='flaticon-381-network'></i>
								<span className='nav-text'>Table</span>
							</Link>
							<ul aria-expanded='false'>
								<li>
									<Link
										className={`${
											path === "table-bootstrap-basic"
												? "mm-active"
												: ""
										}`}
										onClick={() => this.props.onClick()}
										to='/table-bootstrap-basic'
									>
										Bootstrap
									</Link>
								</li>
								<li>
									<Link
										className={`${
											path === "table-datatable-basic"
												? "mm-active"
												: ""
										}`}
										onClick={() => this.props.onClick()}
										to='/table-datatable-basic'
									>
										Datatable
									</Link>
								</li>
							</ul>
						</li> */}
						{/*}                  <li className={`${pages.includes(path) ? "mm-active" : ""}`}>
                     <Link
                        className="has-arrow ai-icon"
                        to="#"
                        aria-expanded="false"
                     >
                        <i className="flaticon-381-layer-1"></i>
                        <span className="nav-text">Pages</span>
                     </Link>
                     <ul aria-expanded="false">
                        <li>
                           <Link
                              to="/page-register"
                              onClick={() => this.props.onClick3()}
                           >
                              Register
                           </Link>
                        </li>
                        <li>
                           <Link
                              to="/page-login"
                              onClick={() => this.props.onClick3()}
                           >
                              Login
                           </Link>
                        </li>
                        <li
                           className={`${
                              error.includes(path) ? "mm-active" : ""
                           }`}
                        >
                           <Link
                              className="has-arrow"
                              to="#"
                              aria-expanded="false"
                           >
                              Error
                           </Link>
                           <ul aria-expanded="false">
                              <li>
                                 <Link
                                    className={`${
                                       path === "page-error-400"
                                          ? "mm-active"
                                          : ""
                                    }`}
                                    to="/page-error-400"
                                    onClick={() => this.props.onClick3()}
                                 >
                                    Error 400
                                 </Link>
                              </li>
                              <li>
                                 <Link
                                    className={`${
                                       path === "page-error-403"
                                          ? "mm-active"
                                          : ""
                                    }`}
                                    to="/page-error-403"
                                    onClick={() => this.props.onClick3()}
                                 >
                                    Error 403
                                 </Link>
                              </li>
                              <li>
                                 <Link
                                    className={`${
                                       path === "page-error-404"
                                          ? "mm-active"
                                          : ""
                                    }`}
                                    to="/page-error-404"
                                    onClick={() => this.props.onClick3()}
                                 >
                                    Error 404
                                 </Link>
                              </li>
                              <li>
                                 <Link
                                    className={`${
                                       path === "page-error-500"
                                          ? "mm-active"
                                          : ""
                                    }`}
                                    to="/page-error-500"
                                    onClick={() => this.props.onClick3()}
                                 >
                                    Error 500
                                 </Link>
                              </li>
                              <li>
                                 <Link
                                    className={`${
                                       path === "page-error-503"
                                          ? "mm-active"
                                          : ""
                                    }`}
                                    to="/page-error-503"
                                    onClick={() => this.props.onClick3()}
                                 >
                                    Error 503
                                 </Link>
                              </li>
                           </ul>
                        </li>
                        <li>
                           <Link
                              className={`${
                                 path === "page-lock-screen" ? "mm-active" : ""
                              }`}
                              to="/page-lock-screen"
                              onClick={() => this.props.onClick3()}
                           >
                              Lock Screen
                           </Link>
                        </li> */}
						{/* </ul> */}
						{/* </li> */}
					</MM>

					<div className='copyright'>
						<p className='fs-14 font-w200'>
							<strong className='font-w400'>Outz Management Dashboard</strong> Dashboard © 2021 All Rights
							Reserved
						</p>
					</div>
				</PerfectScrollbar>
			</div>
		);
	}
}

export default SideBar;
