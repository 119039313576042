import { Alert, Badge, Button, Card, Col, Media, Row } from "react-bootstrap";
import {
	IconButton,
	InputBase,
	TextField,
	Typography,
} from "@material-ui/core";
import { Link, useParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";

import BootstrapTable from "react-bootstrap-table-next";
import { Crypto } from "crypto-js";
import { IpgetRequests } from "../../api/api";
import React from "react";
import { date } from "yup";
import loadable from "@loadable/component";
import moment from "moment";
import pMinDelay from "p-min-delay";
import paginationFactory from "react-bootstrap-table2-paginator";
import { useHistory } from "react-router-dom";

// import { getOrderDetail } from '../../api/api';

const SearchIP = () => {
	const [ip, setIP] = useState([]);
	console.log("🚀 ~ file: SearchIP.js ~ line 31 ~ SearchIP ~ ip", ip);
	const history = useHistory();
	const [clicked, setClicked] = useState(false);
	const [order, setOrder] = useState([]);
	const [success, setSuccess] = useState(false);
	const [used, setUsed] = useState(false);
	const [searchText, setSearchText] = useState("");
	const [match, setMatch] = useState(false);
	const [msg, setMsg] = useState("");
	const [md5, setMd5] = useState("");

	// console.log(searchText);

	// const [confirmed, setConfirmed] = useState([]);

	const user = JSON.parse(localStorage.getItem("current_user"));
	// useEffect(() => {
	// 	async function getCostList() {
	// 		const list = await ResidgetUserspend(
	// 			user.access_token,
	// 			user.resid,
	// 			user.servicetype
	// 		);
	// 		setCostList(list.data);
	// 	}
	// 	getCostList();
	// }, []);

	const handleSearch = async () => {
		// setOrder([]);
		const data = await IpgetRequests(user.access_token, searchText);
		setIP(data.data);
	};

	const columns = [
		{ dataField: "service", text: "service" },
		// { dataField: "avatar", text: "", formatter: imgFormatter },
		{ dataField: "request_time", text: "Time" },
		{ dataField: "ret", text: "Ret" },
		{ dataField: "msg", text: "Msg" },
	];

	const options = {
		paginationSize: 4,
		pageStartIndex: 0,
		// alwaysShowAllBtns: true, // Always show next and previous button
		// withFirstAndLast: false, // Hide the going to First and Last page button
		hideSizePerPage: true, // Hide the sizePerPage dropdown always
		hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
		// firstPageText: "First",
		// prePageText: "Back",
		// nextPageText: "Next",
		// lastPageText: "Last",
		nextPageTitle: "First page",
		prePageTitle: "Pre page",
		firstPageTitle: "Next page",
		lastPageTitle: "Last page",
		// showTotal: true,
		// paginationTotalRenderer: customTotal,
		disablePageTitle: true,
		sizePerPage: 50,

		// onSizePerPage: 10,
	};

	return (
		<>
			<div className='row mb-5 align-items-center'>
				<div className='col-xl align-self-start mb-4'>
					<div
						// to='app-profile'
						className=' mb-3 mb-xl-0 d-flex align-items-center justify-content-center light btn-lg btn-block rounded shadow px-4'
					>
						{/* <div className='search_bar dropdown show'> */}
						{/* <Form inline>
								<Form.Group> */}
						<TextField
							type='text'
							style={{
								marginRight: "8px",
							}}
							placeholder='Search'
							// className='mr-sm-2'
							onKeyPress={(e) =>
								e.key === "Enter" && handleSearch()
							}
							onChange={(e) =>
								setSearchText(e.target.value.toUpperCase())
							}
						/>
						<Button variant='primary' onClick={handleSearch}>
							Search
						</Button>
						{/* </Form.Group>
							</Form> */}

						{/* <Link
								to='#'
								onClick={handleToday}
								className='btn mb-0 font-w600 float-right'
								style={{ float: "right" }}
								// variant='primary'
							>
								<Button variant='primary'>
									{" "}
									New Users (Today)
								</Button>
							</Link> */}
					</div>
					{/* </Link> */}
				</div>
			</div>
			<div className='row'>
				<div className='col-xl-12 col-xxl-12 col-lg-12 col-md-12 col-sm-12'>
					<div className='row'>
						<div className='col-xl-12'>
							<div className='card'>
								{Array.isArray(ip) && ip.length > 0 && (
									<BootstrapTable
										keyField='id'
										data={ip}
										columns={columns}
										pagination={paginationFactory(options)}
										// rowEvents={rowEvents}
										// {...paginationTableProps}
									/>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default SearchIP;
