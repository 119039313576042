import { Alert, Badge, Button, Card, Col, Media, Row } from "react-bootstrap";
import {
	IconButton,
	InputBase,
	TextField,
	Typography,
} from "@material-ui/core";
import { Link, useParams } from "react-router-dom";
import {
	Piid6get30Resuser,
	ResidgetUserspend,
	confirmOrder,
} from "../../api/api";
import { useEffect, useRef, useState } from "react";

import { Crypto } from "crypto-js";
import React from "react";
import { date } from "yup";
import loadable from "@loadable/component";
import moment from "moment";
import pMinDelay from "p-min-delay";
import { useHistory } from "react-router-dom";

// import { getOrderDetail } from '../../api/api';

const OrderManagement = () => {
	const [costList, setCostList] = useState([]);
	const history = useHistory();
	const [clicked, setClicked] = useState(false);
	const [order, setOrder] = useState([]);
	const [success, setSuccess] = useState(false);
	const [used, setUsed] = useState(false);
	const [searchText, setSearchText] = useState("");
	const [match, setMatch] = useState(false);
	const [msg, setMsg] = useState("");
	const [md5, setMd5] = useState("");

	// console.log(searchText);

	// const [confirmed, setConfirmed] = useState([]);

	const user = JSON.parse(localStorage.getItem("current_user"));
	useEffect(() => {
		async function getCostList() {
			const list = await ResidgetUserspend(
				user.access_token,
				user.resid,
				user.servicetype
			);
			setCostList(list.data);
		}
		getCostList();
	}, []);

	const handleSearch = async () => {
		setOrder([]);
		const order = await Piid6get30Resuser(user.access_token, searchText);
		// console.log(order);
		if (order.ret == 200) {
			// console.log(order.data[0].resid);
			if (user.resid == order.data[0].resid) {
				setOrder(order.data);
				setMatch(true);
			} else {
				if (user.member_level > 92) {
					setOrder(order.data);
					setMatch(true);
				} else {
					setMatch(false);
					setMsg("No match order!");
				}
			}
		}
		if (order.ret === 400) {
			setMatch(false);
			setMsg(order.msg);
		}
	};

	// console.log("match", match);

	async function handleConfirm() {
		setClicked(true);
		// console.log("log submit")
		const click = await confirmOrder(
			user.access_token,
			order[0].id.toString(),
			order[0].resid,
			order[0].userid,
			order[0].type,
			order[0].typeid,
			user.id,
			order[0].usednumber,
			null,
			order[0].rules,
			md5,
			order[0].servicetype,
			order[0].price,
			null,
			order[0].name
		);
		if (click.ret === 200) {
			setSuccess(true);
			// setMatch(false);
			// return <Alert>确认成功</Alert>;
		}
	}

	return (
		<>
			<div className='row mb-5 align-items-center'>
				<div className='col-xl align-self-start mb-4'>
					<div
						// to='app-profile'
						className=' mb-3 mb-xl-0 d-flex align-items-center justify-content-center light btn-lg btn-block rounded shadow px-4'
					>
						{/* <div className='search_bar dropdown show'> */}
						{/* <Form inline>
								<Form.Group> */}
						<TextField
							type='text'
							style={{
								marginRight: "8px",
							}}
							placeholder='Search'
							// className='mr-sm-2'
							onKeyPress={(e) =>
								e.key === "Enter" && handleSearch()
							}
							onChange={(e) =>
								setSearchText(e.target.value.toUpperCase())
							}
						/>
						<Button variant='primary' onClick={handleSearch}>
							Search
						</Button>
						{/* </Form.Group>
							</Form> */}

						{/* <Link
								to='#'
								onClick={handleToday}
								className='btn mb-0 font-w600 float-right'
								style={{ float: "right" }}
								// variant='primary'
							>
								<Button variant='primary'>
									{" "}
									New Users (Today)
								</Button>
							</Link> */}
					</div>
					{/* </Link> */}
				</div>
			</div>
			<div className='row'>
				<div className='col-xl-12 col-xxl-12 col-lg-12 col-md-12 col-sm-12'>
					<div className='row'>
						<div className='col-xl-12'>
							<div className='card'>
								<div>
									{Array.isArray(order) &&
										order.length > 0 &&
										!success &&
										order.map((item) => (
											<>
												<div className='card-header border-0 pb-0 d-sm-flex d-block'>
													<h4 className='card-title mb-1'>
														Order details
													</h4>
												</div>
												<div className='card-body pt-0 p-0'>
													{/* {item.resname && (
                                                        <div className="align-items-center row mx-0 border-bottom p-4">
                                                            restrurant name：{item.resname}
                                                        </div>
                                                    )} */}
													{/* {item.content && (
                                                        <div className="align-items-center row mx-0 border-bottom p-4">
                                                            Name：{item.content}
                                                        </div>
                                                    )} */}
													{/* {item.money && (
                                                        <div className="align-items-center row mx-0 border-bottom p-4">
                                                            Money：$
                                                            {item.money / 100 > 1
                                                                ? item.money / 100 + '.00'
                                                                : item.money / 100}
                                                        </div>
                                                    )} */}
													{item.name && (
														<div className='align-items-center row mx-0 border-bottom p-4'>
															Name：{item.name}
														</div>
													)}
													{/* {item.id && (
                                                        <div className="align-items-center row mx-0 border-bottom p-4">
                                                            Paid：
                                                            {item.paid === 1 ? 'Paid' : 'Unpaid'}
                                                        </div>
                                                    )} */}
													{/* {item.paid === 1 && (
                                                        <div className="align-items-center row mx-0 border-bottom p-4">
                                                            Paid time：{item.paidtime}
                                                        </div>
                                                    )} */}
													{/* {item.pic && (
                                                        <div className="align-items-center justify-content-center border-bottom d-flex">
                                                            <img
                                                                className="align-items-center row mx-0 border-bottom p-4 img-thumbnail"
                                                                src={item.pic}
                                                                alt="pic"
                                                                style={{
                                                                    width: '300px',
                                                                    height: '300px',
                                                                }}
                                                            />
                                                        </div>
                                                    )}
                                                    {item.pic2 && (
                                                        <div className="align-items-center row mx-0 border-bottom p-4">
                                                            Pic2：{item.pic2}
                                                        </div>
                                                    )}
                                                    {item.pic3 && (
                                                        <div className="align-items-center row mx-0 border-bottom p-4">
                                                            Pic3：{item.pic3}
                                                        </div>
                                                    )}
                                                    {item.pic4 && (
                                                        <div className="align-items-center row mx-0 border-bottom p-4">
                                                            Pic4：{item.pic4}
                                                        </div>
                                                    )}
                                                    {item.pic5 && (
                                                        <div className="align-items-center row mx-0 border-bottom p-4">
                                                            Pic5：{item.pic5}
                                                        </div>
                                                    )}
                                                    {item.pic6 && (
                                                        <div className="align-items-center row mx-0 border-bottom p-4">
                                                            Pic6：{item.pic6}
                                                        </div>
                                                    )}
                                                    {item.pic7 && (
                                                        <div className="align-items-center row mx-0 border-bottom p-4">
                                                            Pic7：{item.pic7}
                                                        </div>
                                                    )}
                                                    {item.pic8 && (
                                                        <div className="align-items-center row mx-0 border-bottom p-4">
                                                            Pic8：{item.pic8}
                                                        </div>
                                                    )}
                                                    {item.pic9 && (
                                                        <div className="align-items-center row mx-0 border-bottom p-4">
                                                            Pic9：{item.pic9}
                                                        </div>
                                                    )}
                                                    {item.pic10 && (
                                                        <div className="align-items-center row mx-0 border-bottom p-4">
                                                            Pic 10：{item.pic10}
                                                        </div>
                                                    )} */}
													{item.price && (
														<div className='align-items-center row mx-0 border-bottom p-4'>
															Price：$
															{item.price / 100}
														</div>
													)}
													{item.credit && (
														<div className='align-items-center row mx-0 border-bottom p-4'>
															Credit：$
															{item.credit / 100}
														</div>
													)}
													{/* {item.resid && (
                                                        <div className="align-items-center row mx-0 border-bottom p-4">
                                                            restrurant Id：{item.resid}
                                                        </div>
                                                    )} */}

													{!used &&
													!(
														item.usednumber >=
														item.buynumber
													) ? (
														<div className='row col align-items-center d-flex justify-content-center'>
															<button
																className='btn btn-primary items-center'
																onClick={
																	handleConfirm
																}
																disable={
																	clicked
																}
																style={{
																	marginTop:
																		"10px",
																}}
															>
																Confirm
															</button>
														</div>
													) : (
														<div
															className='row col align-items-center d-flex justify-content-center '
															style={{
																marginTop:
																	"10px",
															}}
														>
															<h4>
																The Voucher has
																been used!
															</h4>
														</div>
													)}
													{item.rules && (
														<div
															className='align-items-center row mx-0 border-bottom p-4'
															style={{
																whiteSpace:
																	"pre-wrap",
															}}
														>
															Rules:{"\n"}
															{JSON.parse(
																item.rules
															)
																.toString()
																.replace(
																	",",
																	"\n"
																)
																.split("\\")}
														</div>
													)}
												</div>
											</>
										))}
								</div>
								{!match && msg.length > 0 && (
									<div
										className='card-header border-0 pb-0 d-sm-flex d-block align-items-center justify-content-center '
										style={{ height: "100px" }}
									>
										<h4 className='card-title mb-1'>
											No Match Order
										</h4>
									</div>
								)}

								{success && (
									<div
										className='card-header border-0 pb-0 d-sm-flex d-block align-items-center justify-content-center '
										style={{ height: "100px" }}
									>
										<h4 className='card-title mb-1'>
											Order Confirmed!{" "}
										</h4>
										{/* <Redirect to="/" /> */}
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default OrderManagement;
