import {
	Breadcrumb,
	Button,
	Card,
	Col,
	Form,
	Modal,
	Nav,
} from "react-bootstrap";
import { GetRestaurant, NewCourse, UpCourse } from "../../api/api";
import { Link, useHistory } from "react-router-dom";
import React, { useCallback, useEffect, useState } from "react";

import BootstrapTable from "react-bootstrap-table-next";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";

const Courses = () => {
	const user = JSON.parse(localStorage.getItem("current_user") || "{}");
	const history = useHistory();

	// const [name, setName] = useState();
	// const [type, setType] = useState();
	// const [price, setPrice] = useState();
	// const [price1, setPrice1] = useState();
	// const [recommend, setRecommend] = useState();
	// const [content, setContent] = useState("");
	// const [pic1, setPic1] = useState("");
	// const [pic2, setPic2] = useState("");
	// const [pic3, setPic3] = useState("");
	// const [pic4, setPic4] = useState("");
	// const [pic5, setPic5] = useState("");
	// const [pic6, setPic6] = useState("");
	// const [order, setOrder] = useState("");
	// const [courseType, setCourseType] = useState(
	// 	JSON.parse(localStorage.getItem("coursestype"))
	// );
	// console.log(
	// 	"🚀 ~ file: NewCourse.js ~ line 21 ~ NewCourse ~ course",
	// 	courseType
	// );

	// const handleNewCourse = async () => {
	// 	// console.log(
	// 	// 	"🚀 ~ file: NewCourse.js ~ line 45 ~ handleNewCourse ~ course.resid,",
	// 	// 	courseType.resid
	// 	// );
	// 	// console.log(
	// 	// 	"🚀 ~ file: NewCourse.js ~ line 46 ~ handleNewCourse ~ course.servicetype,",
	// 	// 	courseType.servicetype
	// 	// );
	// 	// console.log(
	// 	// 	"🚀 ~ file: NewCourse.js ~ line 50 ~ handleNewCourse ~ course.coursetypeid,",
	// 	// 	courseType.id
	// 	// );

	// 	const data = await NewCourse(
	// 		user.access_token,
	// 		courseType.resid,
	// 		name,
	// 		type,
	// 		price,
	// 		price1,
	// 		recommend,
	// 		content,
	// 		pic1,
	// 		pic2,
	// 		pic3,
	// 		pic4,
	// 		pic5,
	// 		pic6,
	// 		order,
	// 		courseType.servicetype,
	// 		courseType.id
	// 	);
	// 	console.log(
	// 		"🚀 ~ file: NewCourse.js ~ line 81 ~ handleNewCourse ~ data",
	// 		data
	// 	);
	// };
	// // const handleIdGetCourses = async (item) => {
	// //     const data = JSON.parse(item);
	// //     setStore(data.id);
	// //     setStoreName(data.name);
	// //     const idGetCoursesType = await ResidgetCoursetype(
	// //         user.access_token,
	// //         data.id,
	// //         servicetype
	// //     );

	// //     setCoursesType(idGetCoursesType.data);
	// // }

	// console.log("new courses");
	// const courses = JSON.parse(localStorage.getItem("getCourses"));
	// console.log("=> courses", courses);
	// const handleNewCourserule = () => {
	// 	// localStorage.setItem("coursestype", JSON.stringify(coursestype));
	// 	history.push("./new-courserule");
	// };
	// const handleDeleteCourse = async (id) => {
	// 	const data = await DeleteCourse(user.access_token, id);
	// 	window.location.reload();
	// };
	// const handleUpdateCourse = async (item) => {
	// 	console.log(item);
	// 	const data = await UpCourse(
	// 		user.access_token,
	// 		item.id,
	// 		item.resid,
	// 		name ? name : item.name,
	// 		type ? type : item.type,
	// 		price ? price : item.price,
	// 		price1 ? price1 : item.price1,
	// 		recommend ? recommend : item.tuijian,
	// 		content ? content : item.content,
	// 		pic1 ? pic1 : item.pic1,
	// 		pic2 ? pic2 : item.pic2,
	// 		pic3 ? pic3 : item.pic3,
	// 		pic4 ? pic4 : item.pic4,
	// 		pic5 ? pic5 : item.pic5,
	// 		pic6 ? pic6 : item.pic6,
	// 		order ? order : item.order,
	// 		item.servicetype,
	// 		item.coursetypeid
	// 	);
	// 	if (data.data === 1) {
	// 		alert("updated");
	// 	} else {
	// 		alert("fail");
	// 	}
	// 	console.log(
	// 		"🚀 ~ file: CourseManagement.js ~ line 207 ~ handleUpdateCourse ~ data",
	// 		data
	// 	);
	// };
	const [businesses, setBusinesses] = useState([]);
	const handleGetRes = async () => {
		const getRestaurant = await GetRestaurant(user.access_token);
		setBusinesses(getRestaurant.data);
	};
	return (
		<div className='h-80'>
			{/* <div className='page-titles'>
				<Breadcrumb>
					<Breadcrumb.Item>
						<p onClick={() => history.goBack()}>Back</p>
					</Breadcrumb.Item>
				</Breadcrumb>
			</div> */}
			<div className='row'>
				<div className='col-lg-12'>
					<div className='card'>
						<div className='card-body '></div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Courses;
