import { Button, Form, FormControl, Nav, Row, Tab } from "react-bootstrap";
import { GetdtUser, GetsyUser, UserConsumption } from "../../../../api/api";
import {
	IconButton,
	InputBase,
	TextField,
	Typography,
} from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import paginationFactory, {
	PaginationListStandalone,
	PaginationProvider,
	PaginationTotalStandalone,
	SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";

import BootstrapTable from "react-bootstrap-table-next";
import FuzzySearch from "fuzzy-search";
import PageTitle from "../../../../layouts/PageTitle";
import avartar1 from "../../../../../images/avatar/1.png";
import avartar5 from "../../../../../images/avatar/5.png";
import { createBrowserHistory } from "history";

// import Fuse from "fuse.js";

// import Consumption from "./Consumption";

/// images

const Customers = () => {
	// const history = createBrowserHistory();

	const [customers, setCustomers] = useState([]);
	const [today, setToday] = useState(false);
	const [customerToday, setCustomerToday] = useState([]);
	const [openCon, setOpenCon] = useState(false);
	const [openPay, setOpenPay] = useState(false);
	const [searchText, setSearchText] = useState("");

	const user = JSON.parse(localStorage.getItem("current_user") || "{}");
	// console.log(user.access_token);
	useEffect(() => {
		const getUsers = async () => {
			const data = await GetsyUser(user.access_token);
			setCustomers(data.data);
			// console.log(data.data);
		};
		getUsers();
	}, []);
	// console.log(customers);

	const handleAllUsers = async () => {
		const data = await GetsyUser(user.access_token);
		setToday(false);
		setCustomers(data.data);
	};

	const handleToday = async () => {
		const today = await GetdtUser(user.access_token);
		console.log(today);
		setToday(true);
		setCustomerToday(today.data);
	};
	const history = useHistory();
	// const handleOnClick = useCallback(() => history.push("/sample"), [history]);

	const handleOnClick = useCallback(
		(item) => {
			// console.log("click", item);
			history.push({
				pathname: `/consumption-detail/`,
				data: { id: item.id },
			});
			// console.log("aaaa", item);
		},
		[history]
	);
	// console.log("searchText", searchText);

	const fuzzySearch = async () => {
		console.log("search");
		const searcher = new FuzzySearch(
			customers,
			["id", "nickname", "email"],
			{
				caseSensitive: false,
			}
		);
		const result = searcher.search(searchText);
		setCustomers(result);
	};
	console.log(customers);

	const columns = [
		{ dataField: "id", text: "ID" },
		// { dataField: "avatar", text: "", formatter: imgFormatter },
		{ dataField: "nickname", text: "Name" },
		{ dataField: "email", text: "Email" },
		{ dataField: "source", text: "Source" },
	];

	const options = {
		paginationSize: 4,
		pageStartIndex: 0,
		// alwaysShowAllBtns: true, // Always show next and previous button
		// withFirstAndLast: false, // Hide the going to First and Last page button
		hideSizePerPage: true, // Hide the sizePerPage dropdown always
		hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
		// firstPageText: "First",
		// prePageText: "Back",
		// nextPageText: "Next",
		// lastPageText: "Last",
		nextPageTitle: "First page",
		prePageTitle: "Pre page",
		firstPageTitle: "Next page",
		lastPageTitle: "Last page",
		// showTotal: true,
		// paginationTotalRenderer: customTotal,
		disablePageTitle: true,
		// onSizePerPage: 10,
	};

	const rowEvents = {
		onClick: (e, row) => handleOnClick(row),
	};

	return (
		<Fragment>
			{customers.length > 0 && (
				<>
					<div className='col-xl align-self-start mb-4'>
						<div
							// to='app-profile'
							className=' mb-3 mb-xl-0 d-flex align-items-center justify-content-center light btn-lg btn-block rounded shadow px-4'
						>
							{/* <div className='search_bar dropdown show'> */}
							{/* <Form inline>
								<Form.Group> */}
							<TextField
								type='text'
								style={{
									marginRight: "8px",
								}}
								placeholder='Search'
								// className='mr-sm-2'
								onKeyPress={(e) =>
									e.key === "Enter" && fuzzySearch()
								}
								onChange={(e) => setSearchText(e.target.value)}
							/>
							<Button variant='primary' onClick={fuzzySearch}>
								Search
							</Button>
							{/* </Form.Group>
							</Form> */}

							{/* <Link
								to='#'
								onClick={handleToday}
								className='btn mb-0 font-w600 float-right'
								style={{ float: "right" }}
								// variant='primary'
							>
								<Button variant='primary'>
									{" "}
									New Users (Today)
								</Button>
							</Link> */}
						</div>
						{/* </Link> */}
					</div>
					<div className='row'>
						<div className='col-lg-12'>
							<div className='card'>
								<div className='card-body'>
									<Tab.Container
									// defaultActiveKey={tabData[0].name.toLowerCase()}
									>
										<Nav as='ul' className='nav-tabs'>
											<Nav.Item as='li'>
												<Nav.Link
													onClick={handleAllUsers}
													// eventKey={data.name.toLowerCase()}
												>
													All Users
												</Nav.Link>
											</Nav.Item>
											<Nav.Item as='li'>
												<Nav.Link
													onClick={handleToday}
													// eventKey={data.name.toLowerCase()}
												>
													New Users (Today)
												</Nav.Link>
											</Nav.Item>
										</Nav>
										<Tab.Content className='pt-4'>
											{Array.isArray(customers) &&
											customers.length > 0 &&
											!today ? (
												<BootstrapTable
													keyField='id'
													data={customers}
													columns={columns}
													pagination={paginationFactory(
														options
													)}
													rowEvents={rowEvents}
													// {...paginationTableProps}
												/>
											) : (
												Array.isArray(customerToday) &&
												customerToday.length > 0 && (
													<BootstrapTable
														keyField='id'
														data={customerToday}
														columns={columns}
														pagination={paginationFactory(
															options
														)}
														rowEvents={rowEvents}
														// {...paginationTableProps}
													/>
												)
											)}
										</Tab.Content>
									</Tab.Container>
								</div>
							</div>
						</div>
					</div>
				</>
			)}
		</Fragment>
	);
};

export default Customers;
