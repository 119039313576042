import {
	AdmingetWinningpool,
	DeleteWinningpool,
	GetBarber,
	GetBeauty,
	GetBubbletea,
	GetCarRepairs,
	GetEntertainment,
	GetFitness,
	GetMassage,
	GetMovie,
	GetRestaurant,
	GetSupermarket,
	NewWinningpool,
	RsgetCredit,
	RsgetPackage,
	Upratio,
} from "../../api/api";
import { Button, Card, Col, Form, Modal, Nav, Row } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import React, { useCallback, useEffect, useState } from "react";

import BootstrapTable from "react-bootstrap-table-next";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";

const WinningPool = () => {
	//** Dynamic Key Single Place */
	const user = JSON.parse(localStorage.getItem("current_user") || "{}");
	const history = useHistory();
	const [userID, setUserID] = useState("");
	const [show, setShow] = useState(false);

	const [resID, setResID] = useState("");
	// const [type, setType] = useState("");
	const [money, seMoney] = useState("");
	const [resid, setResid] = useState("");
	const [type, setType] = useState("");
	const [poolId, setPoolId] = useState("");
	const [ratio, setRatio] = useState("");
	const [adminid, setAdminID] = useState("");
	const [businesses, setBusinesses] = useState([]);
	const [store, setStore] = useState("");
	const [creditPackage, setCreditPackage] = useState([]);
	const [servicetype, setServicetype] = useState("");
	const [cp, setCP] = useState();

	const [winningpool, setWinningpool] = useState([]);

	const handleShow = (id) => {
		setPoolId(id);
		setShow(true);
	};
	const handleClose = () => setShow(false);
	useEffect(() => {
		async function getData() {
			const data = await AdmingetWinningpool(user.access_token);
			console.log(data);
			setWinningpool(data.data);
		}
		getData();
	}, []);

	const handleSelectServicetype = async (item) => {
		setServicetype(item);
		setBusinesses([]);
		setStore("");
		setCreditPackage([]);
		setCP();
		setType();

		switch (item) {
			case "0":
				//console.log('restaurant, Get id', id);
				const getRestaurant = await GetRestaurant(user.access_token);
				setBusinesses(getRestaurant.data);

				break;
			case "2":
				//console.log('Beauty, Get id', id);
				const getBeauty = await GetBeauty(user.access_token);
				setBusinesses(getBeauty.data);

				break;
			case "1":
				//console.log('entertainment, Get id', id);
				const getEntertainment = await GetEntertainment(
					user.access_token
				);
				setBusinesses(getEntertainment.data);

				break;
			case "3":
				//console.log('babble_tea, Get id', id);
				const getBabbleTea = await GetBubbletea(user.access_token);
				setBusinesses(getBabbleTea.data);

				break;
			case "6":
				//console.log('babble_tea, Get id', id);
				const getMovie = await GetMovie(user.access_token);
				setBusinesses(getMovie.data);

				break;
			case "7":
				//console.log('babble_tea, Get id', id);
				const getMassage = await GetMassage(user.access_token);
				setBusinesses(getMassage.data);

				break;
			case "8":
				//console.log('babble_tea, Get id', id);
				const getBarber = await GetBarber(user.access_token);
				setBusinesses(getBarber.data);

				break;
			case "9":
				//console.log('babble_tea, Get id', id);
				const getCarRepair = await GetCarRepairs(user.access_token);
				setBusinesses(getCarRepair.data);

				break;
			case "10":
				//console.log('babble_tea, Get id', id);
				const getSupermarket = await GetSupermarket(user.access_token);
				setBusinesses(getSupermarket.data);

				break;
			case "11":
				//console.log('babble_tea, Get id', id);
				const getFitness = await GetFitness(user.access_token);
				setBusinesses(getFitness.data);

				break;
		}
	};

	const handleSelectStore = async (type) => {
		if (type == "0") {
			console.log("credit");

			const c = await RsgetCredit(
				user.access_token,
				store,
				servicetype == "1"
					? "2"
					: servicetype == "2"
					? "1"
					: servicetype
			);
			console.log(c);
			Array.isArray(c.data) &&
				c.data.length > 0 &&
				setCreditPackage(c.data);
		}
		if (type == "1") {
			console.log("package");

			const p = await RsgetPackage(
				user.access_token,
				store,
				servicetype == "1"
					? "2"
					: servicetype == "2"
					? "1"
					: servicetype
			);

			console.log(p);
			Array.isArray(p.data) &&
				p.data.length > 0 &&
				setCreditPackage(p.data);
		}
	};

	console.log("cp", cp);
	console.log("type", type);

	const handleNewPool = async () => {
		const cpData = JSON.parse(cp);
		const data = await NewWinningpool(
			user.access_token,
			userID,
			cpData.resid,
			cpData.price,
			type,
			cpData.id,
			cpData.name,
			ratio,
			adminid
		);
		console.log(data);
		window.location.reload();
	};

	const handleUpdateRatio = async (id, ratio) => {
		const data = await Upratio(user.access_token, id, ratio);
		console.log(data);
		window.location.reload();
	};

	const handleDeleteWinningpool = async (id) => {
		console.log(id);
		const data = await DeleteWinningpool(user.access_token, id);
		console.log(data);
		window.location.reload();
	};

	const servicetypeOptions = [
		{
			value: "0",
			label: "Resturant",
		},
		{
			value: "1",
			label: "Entertainment",
		},
		{
			value: "2",
			label: "Beauty",
		},
		{
			value: "3",
			label: "Bubble Tea",
		},
		{
			value: "4",
			label: "coffee",
		},
		{
			value: "5",
			label: "dessert",
		},
		{
			value: "6",
			label: "Movie",
		},
		{
			value: "7",
			label: "Massage",
		},
		{
			value: "8",
			label: "Barber",
		},
		{
			value: "9",
			label: "Car Repair",
		},
		{
			value: "10",
			label: "Supermarket",
		},
		{
			value: "11",
			label: "Fitness",
		},
	];
	const typeids = [
		{
			value: "0",
			key: "credit",
		},
		{
			value: "1",
			key: "package",
		},
	];
	console.log(winningpool);

	return (
		<div className='h-80'>
			<div className='row'>
				<div className='col-lg-12'>
					<div className='card'>
						<div className='card-body '>
							<div className='basic-form'>
								<label
									htmlFor='address'
									// className='text-black font-w600'
								>
									UserID
								</label>
								<input
									type='text'
									label='userID'
									className='form-control'
									// defaultValue="userID"
									name='userID'
									placeholder='userID'
									onChange={(e) => setUserID(e.target.value)}
								/>
								<label htmlFor='state'>
									Select Servicetype
								</label>

								<Form.Control
									as='select'
									// custom
									onChange={(e) =>
										handleSelectServicetype(e.target.value)
									}
								>
									<option></option>
									{servicetypeOptions.map((item) => (
										<option
											key={item.value}
											value={item.value}
										>
											{item.label}
										</option>
									))}
								</Form.Control>
							</div>

							<div className='basic-form'>
								<label htmlFor='state'>Select Business</label>

								<Form.Control
									as='select'
									// custom
									onChange={(e) => setStore(e.target.value)}
								>
									<option></option>
									{Array.isArray(businesses) &&
										businesses.length > 0 &&
										businesses.map((item) => (
											<option
												key={item.id}
												value={item.id}
											>
												{item.name}
											</option>
										))}
								</Form.Control>
							</div>

							<div className='basic-form'>
								<label htmlFor='state'>Select type</label>

								<Form.Control
									as='select'
									// custom
									onChange={(e) =>
										handleSelectStore(e.target.value)
									}
								>
									<option></option>
									{typeids.map((item) => (
										<option
											key={item.value}
											value={item.value}
										>
											{item.key}
										</option>
									))}
								</Form.Control>
							</div>
							<div className='basic-form'>
								<label htmlFor='state'>
									Select Credit/Package
								</label>

								<Form.Control
									as='select'
									// custom
									onChange={(e) => setCP(e.target.value)}
								>
									<option></option>
									{creditPackage.length > 0 &&
										creditPackage.map((item) => (
											<option
												key={item.id}
												value={JSON.stringify(item)}
											>
												{item.name}
											</option>
										))}
								</Form.Control>
							</div>
							<label
								htmlFor='address'
								// className='text-black font-w600'
							>
								Ratio
							</label>
							<input
								type='text'
								label='ratio'
								className='form-control'
								// defaultValue="ratio"
								name='ratio'
								placeholder='ratio'
								onChange={(e) => setRatio(e.target.value)}
							/>
							<label
								htmlFor='address'
								// className='text-black font-w600'
							>
								AdminID
							</label>
							<input
								type='text'
								label='adminID'
								className='form-control'
								// defaultValue="adminID"
								name='adminID'
								placeholder='adminID'
								onChange={(e) => setAdminID(e.target.value)}
							/>

							<div className='d-flex  justify-content-center align-items-center mb-2 mt-2'>
								<Button
									variant='primary'
									onClick={handleNewPool}
								>
									Add
								</Button>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className='col-lg-12'>
				{winningpool &&
					Array.isArray(winningpool) &&
					winningpool.length > 0 &&
					winningpool.map((item) => (
						<div className='card'>
							<div className='card-body '>
								{item.id && (
									<h4 style={{ marginBottom: "20px" }}>
										ID: {item.id}
									</h4>
								)}
								{item.userID && <p>UserID: {item.userid}</p>}
								{item.resid && <p>ResID: {item.resid}</p>}
								{item.money && <p>Money: {item.money}</p>}
								{item.typename && (
									<p>Typename: {item.typename}</p>
								)}
								{item.ratio && <p>Ratio:{item.ratio}</p>}

								<div className='d-flex  justify-content-center align-items-center mb-2 '>
									<Button
										style={{ marginRight: "6px" }}
										variant='primary'
										onClick={() => handleShow(item.id)}
									>
										UpdateRatio
									</Button>
									<Modal show={show} onHide={handleClose}>
										<Modal.Header closeButton>
											<Modal.Title>
												Update Ratio
											</Modal.Title>
										</Modal.Header>
										<Modal.Body>
											<label
												htmlFor='address'
												// className='text-black font-w600'
											>
												Ratio
											</label>
											<input
												type='text'
												label='ratio'
												className='form-control'
												// defaultValue="ratio"
												name='ratio'
												placeholder='ratio'
												onChange={(e) =>
													setRatio(e.target.value)
												}
											/>
										</Modal.Body>
										<Modal.Footer>
											<Button
												variant='primary'
												onClick={() =>
													handleUpdateRatio(
														poolId,
														ratio
													)
												}
											>
												Update
											</Button>
										</Modal.Footer>
									</Modal>
									<Button
										variant='primary'
										onClick={() =>
											handleDeleteWinningpool(item.id)
										}
									>
										Delete
									</Button>
								</div>
							</div>
						</div>
					))}
			</div>
		</div>
	);
};

export default WinningPool;
