import { Breadcrumb, Button, Card, Col, Modal, Nav, Table } from 'react-bootstrap';
import { DeleteCourse, IdgetCourse, ResidgetCourse, UpCourse, adminUpdateStock } from '../../api/api';
import { Link, useHistory } from 'react-router-dom';
import React, { useCallback, useEffect, useState } from 'react';

import BootstrapTable from 'react-bootstrap-table-next';
import CourseDetail from './CourseDetail';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Switch from '@material-ui/core/Switch';
// import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

const Courses = ({ showCourseList, handleCloseCourseList, courseList, triggerUpdate, setTriggerUpdate }) => {
	console.log('courseList', courseList);
	const user = JSON.parse(localStorage.getItem('current_user') || '{}');
	console.log('user', user);
	localStorage.removeItem('courseDetail');
	const history = useHistory();
	// const [courses, setCourses] = useState([]);
	const [name, setName] = useState();
	const [type, setType] = useState();
	const [enname, setEnName] = useState();

	const [price, setPrice] = useState();
	const [price1, setPrice1] = useState();
	const [recommend, setRecommend] = useState();
	const [content, setContent] = useState('');
	const [pic1, setPic1] = useState('');
	const [pic2, setPic2] = useState('');
	const [pic3, setPic3] = useState('');
	const [pic4, setPic4] = useState('');
	const [pic5, setPic5] = useState('');
	const [pic6, setPic6] = useState('');
	const [order, setOrder] = useState('');
	const [rules, setRules] = useState({
		rule1: '',
		rule1en: '',
		maxchoice1: '',
		rule2: '',
		rule2en: '',
		maxchoice2: '',
		rule3: '',
		rule3en: '',
		maxchoice3: '',
	});
	const [rule1CN, setRule1CN] = useState('');
	const [rule1EN, setRule1EN] = useState('');
	const [rule2CN, setRule2CN] = useState('');
	const [rule2EN, setRule2EN] = useState('');
	const [rule3CN, setRule3CN] = useState('');
	const [rule3EN, setRule3EN] = useState('');
	const [courseType, setCourseType] = useState(JSON?.parse(localStorage.getItem('coursestype')));

	const courses = courseList;
	console.log('courses', courses);

	const handleDeleteCourse = async (id) => {
		const data = await DeleteCourse(user.access_token, id);
		window.location.reload();
	};
	const handleUpdateCourse = async (item) => {
		console.log(item);
		const data = await UpCourse(
			user.access_token,
			item.id,
			item.resid,
			name ? name : item.name,
			enname ? enname : item.enname,
			type ? type : item.type,
			price ? price : item.price,
			price1 ? price1 : item.price1,
			recommend ? recommend : item.tuijian,
			content ? content : item.content,
			pic1 ? pic1 : item.pic1,
			pic2 ? pic2 : item.pic2,
			pic3 ? pic3 : item.pic3,
			pic4 ? pic4 : item.pic4,
			pic5 ? pic5 : item.pic5,
			pic6 ? pic6 : item.pic6,
			order ? order : item.order,
			item.servicetype,
			item.coursetypeid,
			recommend,
			rule1CN || rule1EN
				? JSON.stringify({
						cnname: rule1CN ? rule1CN : JSON.parse(item.rulename1)?.cnname,
						enname: rule1EN ? rule1EN : JSON.parse(item.rulename1)?.enname,
				  })
				: item.rulename1,
			rules.rule1,
			rules.rule1en,
			rules.maxchoice1,
			rule2CN || rule2EN
				? JSON.stringify({
						cnname: rule2CN ? rule2CN : JSON.parse(item.rulename2)?.cnname,
						enname: rule2EN ? rule2EN : JSON.parse(item.rulename2)?.enname,
				  })
				: item.rulename1,
			rules.rule2,
			rules.rule2en,
			rules.maxchoice2,
			rule3CN || rule3EN
				? JSON.stringify({
						cnname: rule3CN ? rule3CN : JSON.parse(item.rulename3)?.cnname,
						enname: rule3EN ? rule3EN : JSON.parse(item.rulename3)?.enname,
				  })
				: item.rulename1,
			rules.rule3,
			rules.rule3en,
			rules.maxchoice3
		);
		if (data.data === 1) {
			alert('updated');
		} else {
			alert('fail');
		}
		console.log('🚀 ~ file: CourseManagement.js ~ line 207 ~ handleUpdateCourse ~ data', data);
	};

	const handleRules = (e) => {
		console.log(e);
		setRules({ ...rules, [e.target.name]: e.target.value });
	};

	const [stock, setStock] = useState();
	const [courseId, setCourseId] = useState(true);
	console.log('stock', stock);

	const handleUpdateStock = async (id, instock) => {
		// 菜品id
		const data = await adminUpdateStock(user.access_token, id, instock);
		setCourseId(id);
		console.log('user.access_token', user.access_token);
		console.log(data);
		setTriggerUpdate(!triggerUpdate);
		setStock();
	};

	const handleChangeStock = (event, item) => {
		console.log('item', item);
		setStock(event.target.checked);
		console.log('event.target.checked', event.target.checked);
		handleUpdateStock(item.id, event.target.checked ? 1 : 0);
	};

	// ID get course Detail
	const [detail, setDetail] = useState();
	// const idGetCourse = async (item) => {
	// 	const data = await IdgetCourse(user.access_token, item.id);
	// 	console.log('data', data);

	// 	localStorage.setItem('courseDetail', JSON.stringify(data.data));
	// };

	const [openCourseDetail, setOpenCourseDetail] = useState(false);

	const handleCourseDetail = (item) => {
		console.log('item', item);
		setOpenCourseDetail(true);
		setDetail(item);
	};

	const handleCloseDetail = () => {
		setOpenCourseDetail(false);
	};

	// console.log(courses[0]?.rulename1.constructor == {}.constructor ? JSON.parse(courses[0]?.rulename1) : '');
	// console.log(JSON.parse(courses[0]?.rulename1));
	console.log('item', courses);
	return (
		<Modal
			show={showCourseList}
			onHide={handleCloseCourseList}
			backdrop='static'
			keyboard={false}
			centered
			size='lg'>
			<Modal.Header closeButton>
				<Modal.Title>{courses && courses.length > 0 && <h3>Courses List(菜品列表) </h3>} </Modal.Title>
			</Modal.Header>
			<Modal.Body style={{ paddingTop: '10px' }}>
				<div style={{ width: '100%', display: 'flex' }}>
					<div className='d-flex align-items-center'>
						<FormGroup row>
							<FormControlLabel
								control={<Switch checked={true} name='instock' color='primary' />}
								label={'In Stock'}
							/>
						</FormGroup>
					</div>
					<div className='d-flex align-items-center'>
						<FormGroup row>
							<FormControlLabel
								control={<Switch checked={false} name='outstock' color='primary' />}
								label='Out of Stock'
							/>
						</FormGroup>
					</div>
				</div>
				<Table striped>
					<thead>
						<tr>
							<th>No.</th>
							<th>Name(菜品名)</th>
							<th>English Name</th>
							<th>Price(价格)</th>
							<th>Stock</th>
						</tr>
					</thead>
					{courses &&
						courses.length > 0 &&
						courses.map((item, index) => (
							<tbody key={item + index}>
								<tr>
									<td className='cursor-pointer' onClick={() => handleCourseDetail(item)}>
										{index + 1}
									</td>
									<td className='cursor-pointer' onClick={() => handleCourseDetail(item)}>
										{item.name}
									</td>
									<td className='cursor-pointer' onClick={() => handleCourseDetail(item)}>
										{item.enname}
									</td>
									<td className='cursor-pointer' onClick={() => handleCourseDetail(item)}>
										{item.price1}
									</td>
									<td onClick={(e) => e.stopPropagation()}>
										<div className='d-flex align-items-center'>
											<FormGroup row>
												<FormControlLabel
													control={
														<Switch
															defaultChecked={item.instock == 1 ? true : false}
															checked={stock}
															onChange={(e) => handleChangeStock(e, item)}
															name='stock'
															color='primary'
														/>
													}
													// label={item.instock == 1 ? 'In Stock' : 'Out of Stock'}
												/>
											</FormGroup>
										</div>
									</td>
								</tr>
							</tbody>
						))}
					<CourseDetail
						openCourseDetail={openCourseDetail}
						handleCloseDetail={handleCloseDetail}
						handleUpdateCourse={handleUpdateCourse}
						handleDeleteCourse={handleDeleteCourse}
						detail={detail}
						setPrice1={setPrice1}
						handleChangeStock={handleChangeStock}
						setEnName={setEnName}
						setName={setName}
						user={user}
					/>
				</Table>
			</Modal.Body>
		</Modal>
	);
};

export default Courses;
