import React from 'react';
import TimePicker from 'react-bootstrap-time-picker';

const SelectTime = ({ handleTimeTo, handleTimeFrom, timeFrom, timeTo }) => {
	return (
		<TimePicker
			onChange={handleTimeTo || handleTimeFrom}
			value={timeFrom || timeTo}
			start='8:00'
			end='23:00'
			step={30}
		/>
	);
};

export default SelectTime;
