import { Button, Col, Form, Modal, Nav, Row, Table } from "react-bootstrap";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import React, { useState } from "react";

import DateFnsUtils from "@date-io/date-fns";
import { NewWorkorder } from "../../api/api";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import moment from "moment";

const PopWorkOrder = ({
	show,
	handleClose,
	item,
	orderID,
	userID,
	feedbackID,
	feedbackContent,
}) => {
	// if (show) {
	console.log("item", item);
	const user = JSON.parse(localStorage.getItem("current_user") || "{}");
	const [adminID, setAdminID] = useState();
	const [content1, setContent1] = useState();
	const [resuerid, setResuserid] = useState(0);
	const [feedbackid, setFeedbackid] = useState();
	const [feedbackcontent, setFeedbackContent] = useState();

	const handleSubmit = async () => {
		console.log("id", adminID, "content", content1);
		const data = await NewWorkorder(
			user.access_token,
			userID ? userID : item.id,
			orderID ? orderID : resuerid,
			feedbackID,
			feedbackContent,
			user.id,
			content1
		);
		window.location.reload();

		console.log(data);
	};
	return (
		<Modal
			show={show}
			onHide={handleClose}
			backdrop='static'
			keyboard={false}
			centered
			size='lg'
		>
			<Modal.Header closeButton>
				<Modal.Title>Work Order</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<h5 style={{ color: "#ff0000" }}>Only white area can edit!</h5>
				<Form>
					<Row>
						<Form.Group as={Col}>
							<Form.Label>User ID</Form.Label>
							<Form.Control
								placeholder='Non-editable'
								disabled
								style={{
									backgroundColor: "#eceaea",
								}}
								type='text'
								value={userID ? userID : item && item.id}
							/>
						</Form.Group>
						<Form.Group as={Col}>
							<Form.Label>Feedback ID</Form.Label>
							<Form.Control
								placeholder='Non-editable'
								disabled
								style={{
									backgroundColor: "#eceaea",
								}}
								type='text'
								value={feedbackID ? feedbackID : ""}
							/>
						</Form.Group>
						<Form.Group as={Col}>
							<Form.Label>Order ID</Form.Label>
							<Form.Control
								placeholder='Non-editable'
								disabled
								style={{
									backgroundColor: "#eceaea",
								}}
								value={orderID ? orderID : ""}
							/>
						</Form.Group>
					</Row>
					<Form.Group className='mb-3'>
						<Form.Label>Feedback Content</Form.Label>
						<Form.Control
							as='textarea'
							placeholder='Non-editable'
							style={{ height: "80px" }}
							disabled
							style={{
								backgroundColor: "#eceaea",
							}}
							value={feedbackContent ? feedbackContent : ""}
						/>
					</Form.Group>

					<Row className='mb-3'>
						<Form.Group as={Col}>
							<Form.Label>Admin 1 ID</Form.Label>
							<Form.Control
								placeholder='Enter admin ID'
								value={user.id}
								disabled
								style={{
									backgroundColor: "#eceaea",
								}}
							/>
						</Form.Group>

						<Form.Group as={Col}>
							<Form.Label>Date 1</Form.Label>
							<Form.Control
								placeholder='Non-editable'
								disabled
								type='text'
								style={{
									backgroundColor: "#eceaea",
								}}
								// value={item.timestamp1}
							/>
						</Form.Group>
					</Row>

					<Form.Group className='mb-3'>
						<Form.Label>Content 1</Form.Label>
						<Form.Control
							as='textarea'
							placeholder='Leave a comment here'
							style={{ height: "80px" }}
							value={content1}
							onChange={(e) => setContent1(e.target.value)}
						/>
					</Form.Group>
					<Row style={{ justifyContent: "center" }}>
						<Button
							variant='primary'
							onClick={() => handleSubmit()}
							style={{ marginRight: "10px" }}
						>
							Submit
						</Button>
					</Row>
				</Form>
			</Modal.Body>
			{/* <Modal.Footer></Modal.Footer> */}
		</Modal>
	);
};

export default PopWorkOrder;
