import {
	Breadcrumb,
	Button,
	Card,
	Col,
	Form,
	Modal,
	Nav,
} from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import React, { useCallback, useEffect, useState } from "react";

import BootstrapTable from "react-bootstrap-table-next";
import { NewCourserule } from "../../api/api";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";

const NewCourseRule = () => {
	const user = JSON.parse(localStorage.getItem("current_user") || "{}");
	const history = useHistory();

	const [name, setName] = useState();
	const [maxChoice, setMaxChoice] = useState("1");
	const [option, setOption] = useState();
	const [description, setDescription] = useState();
	const [courseid, setCourseid] = useState("");
	const [courseType, setCourseType] = useState(
		JSON.parse(localStorage.getItem("coursestype"))
	);
	console.log(
		"🚀 ~ file: NewCourse.js ~ line 21 ~ NewCourse ~ course",
		courseType
	);

	const handleNewCourseRule = async () => {
		console.log(
			"🚀 ~ file: NewCourse.js ~ line 45 ~ handleNewCourse ~ course.resid,",
			courseType.resid
		);
		console.log(
			"🚀 ~ file: NewCourse.js ~ line 46 ~ handleNewCourse ~ course.servicetype,",
			courseType.servicetype
		);
		console.log(
			"🚀 ~ file: NewCourse.js ~ line 50 ~ handleNewCourse ~ course.coursetypeid,",
			courseType.coursetypeid
		);

		console.log(name, maxChoice, option, description);

		const data = await NewCourserule(
			user.access_token,
			name,
			courseType.resid,
			courseType.servicetype,
			courseid,
			courseType.id,
			maxChoice,
			option,
			description
		);
		console.log("=> data", data);
		if (data.ret === 200) {
			alert("Updated");
		}
	};
	console.log("new courses rule");

	const options = [
		{
			value: "2",
			label: "2",
		},
		{
			value: "3",
			label: "3",
		},
		{
			value: "4",
			label: "4",
		},
		{
			value: "5",
			label: "5",
		},
		{
			value: "6",
			label: "6",
		},
		{
			value: "7",
			label: "7",
		},
		{
			value: "8",
			label: "8",
		},
		{
			value: "9",
			label: "9",
		},
		{
			value: "10",
			label: "10",
		},
	];

	return (
		<div className='h-80'>
			<div className='page-titles'>
				<Breadcrumb>
					<Breadcrumb.Item>
						<p onClick={() => history.goBack()}>Back</p>
					</Breadcrumb.Item>
				</Breadcrumb>
			</div>
			<div className='row'>
				<div className='col-lg-12'>
					<div className='card'>
						<div className='card-body '>
							<form>
								<div className='form-row'>
									<div className='form-group col-md-6'>
										<label htmlFor='name'>Name</label>
										<input
											type='text'
											className='form-control'
											id='name'
											placeholder='Name'
											onChange={(e) =>
												setName(e.target.value)
											}
										/>
									</div>
									<div className='form-group col-md-6'>
										<label htmlFor='maximumchoice'>
											Maximum Choice
										</label>
										<Form.Control
											as='select'
											// custom
											onChange={(e) =>
												setMaxChoice(e.target.value)
											}
										>
											<option selected>
												{maxChoice}
											</option>
											{options.map((item, index) => (
												<option value={item.value}>
													{item.value}
												</option>
											))}
										</Form.Control>
									</div>
									<div className='form-group col-md-6'>
										<label htmlFor='options'>Options</label>
										<input
											type='text'
											className='form-control'
											id='maximumchoice'
											onChange={(e) =>
												setOption(e.target.value)
											}
										/>
									</div>
									<div className='form-group col-md-6'>
										<label htmlFor='description'>
											Description
										</label>
										<input
											type='text'
											className='form-control'
											id='description'
											onChange={(e) =>
												setDescription(e.target.value)
											}
										/>
									</div>
								</div>
								<div className='d-flex  justify-content-center align-items-center mb-2 mt-2'>
									<Button
										variant='primary'
										onClick={handleNewCourseRule}
									>
										Add
									</Button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default NewCourseRule;
