import { Nav, Tab, Table } from "react-bootstrap";
import React, { useEffect, useState } from "react";

import { AdmingetReview } from "../../api/api";
import BootstrapTable from "react-bootstrap-table-next";
import { Link } from "react-router-dom";
import paginationFactory from "react-bootstrap-table2-paginator";

// import DeleteReview from "./DeleteReview";

// import PublishReview from "./PublishReview";

// import AllReview from './AllReview';

const Reviews = () => {
	const [reviews, setReviews] = useState([]);

	const user = JSON.parse(localStorage.getItem("current_user") || "{}");

	useEffect(() => {
		const getReviews = async () => {
			const data = await AdmingetReview(user.access_token);
			console.log(data.data);
			if (Array.isArray(data.data)) {
				setReviews(data.data);
			}
		};
		getReviews();
	}, []);

	const columns = [
		{ dataField: "resid", text: "Store ID" },
		{ dataField: "content", text: "Content" },
		{ dataField: "timestamp", text: "Time" },
		{ dataField: "servicetype", text: "Service Type" },
		{ dataField: "userid", text: "User ID" },
		{ dataField: "rating", text: "Rating" },
	];

	const options = {
		paginationSize: 4,
		pageStartIndex: 0,
		// alwaysShowAllBtns: true, // Always show next and previous button
		// withFirstAndLast: false, // Hide the going to First and Last page button
		hideSizePerPage: true, // Hide the sizePerPage dropdown always
		hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
		// firstPageText: "First",
		// prePageText: "Back",
		// nextPageText: "Next",
		// lastPageText: "Last",
		nextPageTitle: "First page",
		prePageTitle: "Pre page",
		firstPageTitle: "Next page",
		lastPageTitle: "Last page",
		// showTotal: true,
		// paginationTotalRenderer: customTotal,
		disablePageTitle: true,
		sizePerPage: 50,

		// onSizePerPage: 10,
	};

	return (
		<div className='row'>
			<Tab.Container defaultActiveKey='allreview'>
				<div className='col-xl-12'>
					<div className='card'>
						<div className='card-body px-4 py-3 py-md-2'>
							<div className='row align-items-center'>
								<div className='col-sm-12 col-md-7'>
									<Nav
										as='ul'
										className='nav nav-pills review-tab'
									>
										<Nav.Item as='li' className='nav-item'>
											<Nav.Link
												to='navpills-1'
												className='nav-link px-2 px-lg-3'
												aria-expanded='false'
												eventKey='allreview'
											>
												All Review
											</Nav.Link>
										</Nav.Item>
									</Nav>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className='col-xl-12'>
					<Tab.Content className='tab-content'>
						<Tab.Pane
							eventKey='allreview'
							id='navpills-1'
							className='tab-pane'
						>
							{reviews.length > 0 ? (
								<BootstrapTable
									keyField='id'
									data={reviews}
									columns={columns}
									pagination={paginationFactory(options)}
									// rowEvents={rowEvents}
									// {...paginationTableProps}
								/>
							) : (
								<p className='card-trext'>No Reviews</p>
							)}
						</Tab.Pane>
					</Tab.Content>
				</div>
			</Tab.Container>
		</div>
	);
};

export default Reviews;
