import { Button, Card, Col, Form, Modal, Nav, Row } from "react-bootstrap";
import {
	DeleteAdvertise,
	GetAdvertise,
	GetBarber,
	GetBeauty,
	GetBubbletea,
	GetCarRepairs,
	GetEntertainment,
	GetFitness,
	GetMassage,
	GetMovie,
	GetRestaurant,
	GetSupermarket,
	NewAdvertise,
	Upload,
} from "../../../../api/api";
import { Link, useHistory } from "react-router-dom";
import React, { useCallback, useEffect, useState } from "react";

import BootstrapTable from "react-bootstrap-table-next";
import PageTitle from "../../../../layouts/PageTitle";
import SearchAllSpend from "./components/SearchAllSpend";
import SearchSpend from "./components/SearchSpend";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import productData from "../productData";

const Advertisement = () => {
	//** Dynamic Key Single Place */
	const user = JSON.parse(localStorage.getItem("current_user") || "{}");
	const history = useHistory();
	const [picurl, setPicurl] = useState("");
	const [title, setTitle] = useState("");
	const [type, setType] = useState("");
	// const [servicetype, setServicetype] = useState("");
	const [resid, setResid] = useState("");
	const [jumpurl, setJumpurl] = useState("");
	const [imageurl, setImageurl] = useState("");
	const [ads, setAds] = useState([]);
	const [servicetype, setServicetype] = useState("");
	const [businesses, setBusinesses] = useState([]);
	const [store, setStore] = useState("");
	const [file, setFile] = useState(null);
	// const [creditPackage, setCreditPackage] = useState([]);
	// const [servicetype, setServicetype] = useState("");
	// const [cp, setCP] = useState();
	console.log(businesses);
	console.log(resid);

	useEffect(() => {
		async function getData() {
			const data = await GetAdvertise(user.access_token);
			console.log(data);
			setAds(data.data);
		}
		getData();
	}, []);

	const handleSelectServicetype = async (item) => {
		setServicetype(item);
		setResid([]);
		setStore("");

		switch (item) {
			case "0":
				//console.log('restaurant, Get id', id);
				const getRestaurant = await GetRestaurant(user.access_token);
				setBusinesses(getRestaurant.data);

				break;
			case "2":
				//console.log('Beauty, Get id', id);
				const getBeauty = await GetBeauty(user.access_token);
				setBusinesses(getBeauty.data);

				break;
			case "1":
				//console.log('entertainment, Get id', id);
				const getEntertainment = await GetEntertainment(
					user.access_token
				);
				setBusinesses(getEntertainment.data);

				break;
			case "3":
				//console.log('babble_tea, Get id', id);
				const getBabbleTea = await GetBubbletea(user.access_token);
				setBusinesses(getBabbleTea.data);

				break;
			case "6":
				//console.log('babble_tea, Get id', id);
				const getMovie = await GetMovie(user.access_token);
				setBusinesses(getMovie.data);

				break;
			case "7":
				//console.log('babble_tea, Get id', id);
				const getMassage = await GetMassage(user.access_token);
				setBusinesses(getMassage.data);

				break;
			case "8":
				//console.log('babble_tea, Get id', id);
				const getBarber = await GetBarber(user.access_token);
				setBusinesses(getBarber.data);

				break;
			case "9":
				//console.log('babble_tea, Get id', id);
				const getCarRepair = await GetCarRepairs(user.access_token);
				setBusinesses(getCarRepair.data);

				break;
			case "10":
				//console.log('babble_tea, Get id', id);
				const getSupermarket = await GetSupermarket(user.access_token);
				setBusinesses(getSupermarket.data);

				break;
			case "11":
				//console.log('babble_tea, Get id', id);
				const getFitness = await GetFitness(user.access_token);
				setBusinesses(getFitness.data);

				break;
		}
	};
	console.log(file);

	const handleNewAds = async () => {
		const addPic = await Upload(user.access_token, file);
		const data = await NewAdvertise(
			user.access_token,
			title,
			addPic.data.url,
			type,
			servicetype,
			resid,
			jumpurl,
			imageurl
		);
		console.log(data);
		window.location.reload();
	};

	const handleDeleteAds = async (id) => {
		console.log(id);
		const data = await DeleteAdvertise(user.access_token, id);
		console.log(data);
		window.location.reload();
	};

	const servicetypeOptions = [
		{
			value: "0",
			label: "Resturant",
		},
		{
			value: "1",
			label: "Entertainment",
		},
		{
			value: "2",
			label: "Beauty",
		},
		{
			value: "3",
			label: "Bubble Tea",
		},
		{
			value: "4",
			label: "coffee",
		},
		{
			value: "5",
			label: "dessert",
		},
		{
			value: "6",
			label: "Movie",
		},
		{
			value: "7",
			label: "Massage",
		},
		{
			value: "8",
			label: "Barber",
		},
		{
			value: "9",
			label: "Car Repair",
		},
		{
			value: "10",
			label: "Supermarket",
		},
		{
			value: "11",
			label: "Fitness",
		},
	];

	return (
		<div className='h-80'>
			<div className='row'>
				<div className='col-lg-12'>
					<div className='card'>
						<div className='card-body '>
							<label
								htmlFor='name'
								className='text-black font-w600'
							>
								Title <span className='required'>*</span>
							</label>
							<input
								type='text'
								label='title'
								className='form-control'
								name='title'
								placeholder='title'
								onChange={(e) => setTitle(e.target.value)}
							/>
							<label
								htmlFor='content'
								className='text-black font-w600'
							>
								type
							</label>
							<input
								type='text'
								label='type'
								className='form-control'
								// defaultValue="type"
								name='type'
								placeholder='type'
								onChange={(e) => setType(e.target.value)}
							/>

							<label
								htmlFor='keywords'
								className='text-black font-w600'
							>
								servicetype
							</label>
							<Form.Control
								as='select'
								// custom
								onChange={(e) =>
									handleSelectServicetype(e.target.value)
								}
							>
								<option></option>
								{servicetypeOptions.map((item) => (
									<option key={item.value} value={item.value}>
										{item.label}
									</option>
								))}
							</Form.Control>
							<label
								htmlFor='keywords'
								className='text-black font-w600'
							>
								resid
							</label>
							<Form.Control
								as='select'
								// custom
								onChange={(e) => setResid(e.target.value)}
							>
								<option></option>
								{Array.isArray(businesses) &&
									businesses.length > 0 &&
									businesses.map((item) => (
										<option key={item.id} value={item.id}>
											{item.name}
										</option>
									))}
							</Form.Control>
							<label
								htmlFor='keywords'
								className='text-black font-w600'
							>
								jumpurl
							</label>
							<input
								type='text'
								label='jumpurl'
								className='form-control'
								// defaultValue='keywords'
								name='jumpurl'
								onChange={(e) => setJumpurl(e.target.value)}
								// placeholder='keywords'
							/>
							<label
								htmlFor='keywords'
								className='text-black font-w600'
							>
								imageurl
							</label>
							<input
								type='text'
								label='imageurl'
								className='form-control'
								// defaultValue='keywords'
								name='imageurl'
								onChange={(e) => setImageurl(e.target.value)}
								// placeholder='keywords'
							/>
							<label
								htmlFor='keywords'
								className='text-black font-w600'
							>
								picurl
							</label>
							<input
								type='file'
								label='pic'
								className='form-control'
								onChange={(e) => setFile(e.target.files[0])}
								// defaultValue="pic"
								name='pic'
								placeholder='pic'
							/>
							<div className='d-flex  justify-content-center align-items-center mb-2 mt-2'>
								<Button
									variant='primary'
									onClick={handleNewAds}
								>
									Add
								</Button>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className='col-lg-12'>
				{Array.isArray(ads) &&
					ads.length > 0 &&
					ads.map((item) => (
						<div className='card'>
							<div className='card-body '>
								{item.tittle && (
									<h4 style={{ marginBottom: "20px" }}>
										Title: {item.tittle}
									</h4>
								)}
								{item.type && <p>Type: {item.type}</p>}
								{item.servicetype !== null &&
									item.servicetype !== undefined && (
										<p>Servicetype: {item.servicetype}</p>
									)}
								{item.resid !== null &&
									item.servicetype !== undefined && (
										<p>Resid: {item.resid}</p>
									)}
								{item.jumpurl && <p>Jumpurl: {item.jumpurl}</p>}
								{item.imageurl && (
									<p>Iamgeurl:{item.imageurl}</p>
								)}

								<div className='d-flex  justify-content-center align-items-center mb-2'>
									<Button
										variant='primary'
										onClick={() => handleDeleteAds(item.id)}
									>
										Delete
									</Button>
								</div>
							</div>
						</div>
					))}
			</div>
		</div>
	);
};

export default Advertisement;
