import {
	AdminnewwResuser,
	AdminqxResuser,
	GetBarber,
	GetBeauty,
	GetBubbletea,
	GetCarRepairs,
	GetEntertainment,
	GetFitness,
	GetMassage,
	GetMovie,
	GetRestaurant,
	GetSupermarket,
	IdgetUser,
	RsgetCredit,
	RsgetPackage,
	UidgetRequests,
	UserConsumption,
	UseridgetResuser,
} from "../../../../api/api";
import {
	Alert,
	Breadcrumb,
	Button,
	Form,
	Modal,
	Row,
	Table,
} from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import React, { useEffect, useState } from "react";
import paginationFactory, {
	PaginationListStandalone,
	PaginationProvider,
	PaginationTotalStandalone,
	SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";

import BootstrapTable from "react-bootstrap-table-next";
import PageTitle from "../../../../layouts/PageTitle";
import PopWorkOrder from "../../../Utilization/PopWorkOrder";
import avartar1 from "../../../../../images/avatar/1.png";
import moment from "moment";
import productData from "../productData";
import { setDate } from "date-fns/esm";

// import { Button } from "bootstrap";

const ConsumptionDetail = ({ match }) => {
	//** Dynamic Key Single Place */
	const history = useHistory();
	const [data, setData] = useState([]);
	const [paid, setPaid] = useState([]);
	const [userDetail, setUserDetail] = useState(null);
	const [show, setShow] = useState(false);
	const [servicetype, setServicetype] = useState("");
	const [businesses, setBusinesses] = useState([]);
	const [store, setStore] = useState("");
	const [credit, setCredit] = useState([]);
	const [pck, setPackage] = useState([]);
	const [quantity, setQuantity] = useState("");
	const [cp, setCP] = useState();
	const [showAlert, setShowAlert] = useState(false);
	const [isSuccessful, setIsSuccessful] = useState("");
	const [showRecords, setShowRecord] = useState(false);
	const [records, setRecords] = useState([]);
	const [showWorkOrder, setShowWorkOrder] = useState(false);

	const [orderID, setOrderID] = useState();
	const [userID, setUserID] = useState();
	const handleCloseAlert = () => setShowAlert(false);

	const handleClose = () => {
		setShow(false);
		setServicetype("");
		setBusinesses([]);
		setCredit([]);
		setPackage([]);
		setQuantity("");
		setCP();
	};
	const handleShow = () => setShow(true);
	const handleShowRecord = () => setShowRecord(true);
	const handleCloseRecords = () => setShowRecord(false);
	const handleCloseWorkOrder = () => setShowWorkOrder(false);
	const handleShowWorkOrder = (orderid, userid) => {
		setShowWorkOrder(true);
		setOrderID(orderid);
		setUserID(userid);
	};
	// let id;
	if (!history.location.data) {
		history.push("/");
		window.location.reload();
	}
	const id = history.location.data.id;

	const user = JSON.parse(localStorage.getItem("current_user") || "{}");

	useEffect(() => {
		const handleConsumption = async () => {
			// console.log(item);
			const data = await UserConsumption(user.access_token, id);
			// console.log(data.data);
			if (data) {
				setData(data.data);
			}
		};
		handleConsumption();

		const handlePaid = async () => {
			const data = await UseridgetResuser(user.access_token, id);

			if (data) {
				console.log(data.data);
				setPaid(data.data);
			}
		};
		handlePaid();
		const getUser = async () => {
			const data = await IdgetUser(user.access_token, id);

			if (data) {
				console.log(data.data);
				setUserDetail(data.data);
			}
		};
		getUser();

		const handleAccessRecord = async () => {
			const data = await UidgetRequests(user.access_token, id);
			setRecords(data.data);
		};
		handleAccessRecord();
		// getStore();
	}, []);

	const handleSelectServicetype = async (item) => {
		setServicetype(item);
		setBusinesses([]);
		setStore("");
		setCredit([]);
		setPackage([]);
		setQuantity("");
		setCP();

		switch (item) {
			case "0":
				//console.log('restaurant, Get id', id);
				const getRestaurant = await GetRestaurant(user.access_token);
				setBusinesses(getRestaurant.data);

				break;
			case "2":
				//console.log('Beauty, Get id', id);
				const getBeauty = await GetBeauty(user.access_token);
				setBusinesses(getBeauty.data);

				break;
			case "1":
				//console.log('entertainment, Get id', id);
				const getEntertainment = await GetEntertainment(
					user.access_token
				);
				setBusinesses(getEntertainment.data);

				break;
			case "3":
				//console.log('babble_tea, Get id', id);
				const getBabbleTea = await GetBubbletea(user.access_token);
				setBusinesses(getBabbleTea.data);

				break;
			case "6":
				//console.log('babble_tea, Get id', id);
				const getMovie = await GetMovie(user.access_token);
				setBusinesses(getMovie.data);

				break;
			case "7":
				//console.log('babble_tea, Get id', id);
				const getMassage = await GetMassage(user.access_token);
				setBusinesses(getMassage.data);

				break;
			case "8":
				//console.log('babble_tea, Get id', id);
				const getBarber = await GetBarber(user.access_token);
				setBusinesses(getBarber.data);

				break;
			case "9":
				//console.log('babble_tea, Get id', id);
				const getCarRepair = await GetCarRepairs(user.access_token);
				setBusinesses(getCarRepair.data);

				break;
			case "10":
				//console.log('babble_tea, Get id', id);
				const getSupermarket = await GetSupermarket(user.access_token);
				setBusinesses(getSupermarket.data);

				break;
			case "11":
				//console.log('babble_tea, Get id', id);
				const getFitness = await GetFitness(user.access_token);
				setBusinesses(getFitness.data);

				break;
		}
	};

	const handleSelectStore = async (item) => {
		setStore(item);
		const c = await RsgetCredit(
			user.access_token,
			item,
			servicetype == "1" ? "2" : servicetype == "2" ? "1" : servicetype
		);

		c.data.length > 0 && setCredit(c.data);
		const p = await RsgetPackage(
			user.access_token,
			item,
			servicetype == "1" ? "2" : servicetype == "2" ? "1" : servicetype
		);
		p.data.length > 0 && setPackage(p.data);
	};

	console.log(user.access_token);
	// const [money, setMoney] = useState("");
	function getRandom(min, max) {
		return Math.round(Math.random() * (max - min) + min);
	}

	const getCode = () => {
		let code = "";
		for (var i = 0; i < 6; i++) {
			code += String.fromCharCode(getRandom(65, 90)); //大写字母
		}

		return code;
	};

	const code = getCode();
	console.log(code);

	const handleSave = async () => {
		const cpData = JSON.parse(cp);
		// console.log(cpData.credit);
		const data = await AdminnewwResuser(
			user.access_token,
			userDetail.id,
			store,
			cpData.price,
			cpData.credit ? cpData.id : "",
			cpData.credit ? "" : cpData.id,
			cpData.name,
			code,
			quantity,
			servicetype == "1" ? "2" : servicetype == "2" ? "1" : servicetype
		);
		// console.log(data);
		handleClose();

		///data.data.result !== 0
		if (data.data.result !== 0) {
			setIsSuccessful("Successful!");
			setShowAlert(true);
		} else {
			setIsSuccessful("Failed!");
			setShowAlert(true);
		}
	};
	console.log(user);
	console.log(user.id);

	const handleCancel = async (id) => {
		const data = await AdminqxResuser(user.access_token, id, user.id);
		console.log(data);
	};

	//0为餐厅,1为娱乐,2为美容，3为奶茶
	const servicetypeOptions = [
		{
			value: "0",
			label: "Resturant",
		},
		{
			value: "1",
			label: "Entertainment",
		},
		{
			value: "2",
			label: "Beauty",
		},
		{
			value: "3",
			label: "Bubble Tea",
		},
		{
			value: "4",
			label: "coffee",
		},
		{
			value: "5",
			label: "dessert",
		},
		{
			value: "6",
			label: "Movie",
		},
		{
			value: "7",
			label: "Massage",
		},
		{
			value: "8",
			label: "Barber",
		},
		{
			value: "9",
			label: "Car Repair",
		},
		{
			value: "10",
			label: "Supermarket",
		},
		{
			value: "11",
			label: "Fitness",
		},
	];
	const numbers = [
		{
			value: "0",
			label: "0",
		},
		{
			value: "1",
			label: "1",
		},
		{
			value: "2",
			label: "2",
		},
		{
			value: "3",
			label: "3",
		},
		{
			value: "4",
			label: "4",
		},
		{
			value: "5",
			label: "5",
		},
		{
			value: "6",
			label: "6",
		},
		{
			value: "7",
			label: "7",
		},
		{
			value: "8",
			label: "8",
		},
		{
			value: "9",
			label: "9",
		},
		{
			value: "10",
			label: "10",
		},
	];

	const columns = [
		{ dataField: "service", text: "Service" },
		// { dataField: "avatar", text: "", formatter: imgFormatter },
		{ dataField: "ip", text: "IP" },
		{ dataField: "request_time", text: "Time" },
		{ dataField: "ret", text: "Ret" },
		{ dataField: "msg", text: "Msg" },
	];

	const options = {
		paginationSize: 4,
		pageStartIndex: 0,
		// alwaysShowAllBtns: true, // Always show next and previous button
		// withFirstAndLast: false, // Hide the going to First and Last page button
		hideSizePerPage: true, // Hide the sizePerPage dropdown always
		hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
		// firstPageText: "First",
		// prePageText: "Back",
		// nextPageText: "Next",
		// lastPageText: "Last",
		nextPageTitle: "First page",
		prePageTitle: "Pre page",
		firstPageTitle: "Next page",
		lastPageTitle: "Last page",
		// showTotal: true,
		// paginationTotalRenderer: customTotal,
		disablePageTitle: true,
		// paginationSize: 20,
		sizePerPage: 50,
	};

	console.log("servicetype", servicetype);
	// console.log(businesses);
	console.log("store", store);
	console.log(pck);
	console.log(credit);
	console.log("cp", cp);
	console.log(quantity);

	return (
		<div className='h-80'>
			<div className='page-titles'>
				<Breadcrumb>
					<Breadcrumb.Item>
						<p onClick={() => history.goBack()}>Back</p>
					</Breadcrumb.Item>
				</Breadcrumb>
			</div>
			<div className='card'>
				<div className='card-body'>
					<div className='row'>
						<div className='col-xl col-lg  col-md col-xxl col-sm'>
							{/* <div className='product-detail-content'> */}
							<div className=' pr d-flex justify-content-center align-items-center'>
								<h4>User Information</h4>
							</div>
							<hr />
							{userDetail && (
								<>
									<div className='d-flex  justify-content-center align-items-center mb-2'>
										<img
											className='rounded-circle img-fluid'
											src={
												userDetail.avatar
													? userDetail.avatar
													: avartar1
											}
											width='50px'
											height='50px'
											alt=''
										/>
									</div>
									<div className='profile-personal-info'>
										{/* <h4 className='text-primary mb-4'>
											Personal Information
										</h4> */}
										<div className='row mb-2'>
											<div className='col-3'>
												<h5 className='f-w-500'>
													{userDetail.nickname
														? "Nickname"
														: "User name"}
													<span className='pull-right'>
														:
													</span>
												</h5>
											</div>
											<div className='col-9'>
												<span>
													{userDetail.nickname
														? userDetail.nickname
														: userDetail.username}
												</span>
											</div>
										</div>
										<div className='row mb-2'>
											<div className='col-3'>
												<h5 className='f-w-500'>
													Id
													<span className='pull-right'>
														:
													</span>
												</h5>
											</div>
											<div className='col-9'>
												<span>{userDetail.id}</span>
											</div>
										</div>
										<div className='row mb-2'>
											<div className='col-3'>
												<h5 className='f-w-500'>
													Register time
													<span className='pull-right'>
														:
													</span>
												</h5>
											</div>
											<div className='col-9'>
												<span>
													{userDetail.register_time}
												</span>
											</div>
										</div>
										{userDetail.mobile && (
											<div className='row mb-2'>
												<div className='col-3'>
													<h5 className='f-w-500'>
														Mobile
														<span className='pull-right'>
															:
														</span>
													</h5>
												</div>
												<div className='col-9'>
													<span>
														{userDetail.mobile}
													</span>
												</div>
											</div>
										)}
										<div className='row mb-2'>
											<div className='col-3'>
												<h5 className='f-w-500'>
													Source
													<span className='pull-right'>
														:
													</span>
												</h5>
											</div>
											<div className='col-9'>
												<span>{userDetail.source}</span>
											</div>
										</div>

										{userDetail.city && (
											<div className='row mb-2'>
												<div className='col-3'>
													<h5 className='f-w-500'>
														City
														<span className='pull-right'>
															:
														</span>
													</h5>
												</div>
												<div className='col-9'>
													<span>
														{userDetail.city}
													</span>
												</div>
											</div>
										)}
									</div>
									<Row className=' d-flex justify-content-center align-items-center mb-2'>
										<Button onClick={handleShow}>
											Add Credit/Package
										</Button>
										<Modal show={show} onHide={handleClose}>
											<Modal.Header closeButton>
												<Modal.Title>
													Add Credit/Paclage
												</Modal.Title>
											</Modal.Header>
											<Modal.Body>
												<div className='basic-form'>
													<label htmlFor='state'>
														Select Servicetype
													</label>

													<Form.Control
														as='select'
														// custom
														onChange={(e) =>
															handleSelectServicetype(
																e.target.value
															)
														}
													>
														<option></option>
														{servicetypeOptions.map(
															(item) => (
																<option
																	key={
																		item.value
																	}
																	value={
																		item.value
																	}
																>
																	{item.label}
																</option>
															)
														)}
													</Form.Control>
												</div>
												{businesses.length > 0 && (
													<div className='basic-form'>
														<label htmlFor='state'>
															Select Business
														</label>

														<Form.Control
															as='select'
															// custom
															onChange={(e) =>
																handleSelectStore(
																	e.target
																		.value
																)
															}
														>
															<option></option>
															{Array.isArray(
																businesses
															) &&
																businesses.length >
																	0 &&
																businesses.map(
																	(item) => (
																		<option
																			key={
																				item.id
																			}
																			value={
																				item.id
																			}
																		>
																			{
																				item.name
																			}
																		</option>
																	)
																)}
														</Form.Control>
													</div>
												)}
												{(pck.length > 0 ||
													credit.length > 0) && (
													<div className='basic-form'>
														<label htmlFor='state'>
															Select
															Credit/Package
														</label>

														<Form.Control
															as='select'
															// custom
															onChange={(e) =>
																setCP(
																	e.target
																		.value
																)
															}
														>
															<option></option>
															{pck.length > 0 &&
																pck.map(
																	(item) => (
																		<option
																			key={
																				item.id
																			}
																			value={JSON.stringify(
																				item
																			)}
																		>
																			{
																				item.name
																			}
																		</option>
																	)
																)}
															{credit.length >
																0 &&
																credit.map(
																	(item) => (
																		<option
																			key={
																				item.id
																			}
																			value={JSON.stringify(
																				item
																			)}
																		>
																			{
																				item.name
																			}
																		</option>
																	)
																)}
														</Form.Control>
													</div>
												)}
												{cp && (
													<div className='basic-form'>
														<label htmlFor='state'>
															Select Quantity
														</label>

														<Form.Control
															as='select'
															// custom
															onChange={(e) =>
																setQuantity(
																	e.target
																		.value
																)
															}
														>
															{numbers.map(
																(item) => (
																	<option
																		key={
																			item.value
																		}
																		value={
																			item.value
																		}
																	>
																		{
																			item.label
																		}
																	</option>
																)
															)}
														</Form.Control>
													</div>
												)}
											</Modal.Body>
											<Modal.Footer>
												<Button
													variant='secondary'
													onClick={handleClose}
												>
													Close
												</Button>
												<Button
													variant='primary'
													onClick={handleSave}
												>
													Save
												</Button>
											</Modal.Footer>
										</Modal>

										<Modal
											show={showAlert}
											onHide={handleCloseAlert}
										>
											<Modal.Body>
												<h1>{isSuccessful}</h1>
											</Modal.Body>
										</Modal>

										<Button
											style={{ marginLeft: "10px" }}
											onClick={handleShowRecord}
										>
											Access record
										</Button>
										<Modal
											size='lg'
											show={showRecords}
											onHide={handleCloseRecords}
										>
											<Modal.Header closeButton>
												<Modal.Title>
													Access Records
												</Modal.Title>
											</Modal.Header>
											<Modal.Body
												style={{ overflowX: "auto" }}
											>
												{Array.isArray(records) &&
												records.length > 0 ? (
													<BootstrapTable
														keyField='id'
														data={records}
														columns={columns}
														pagination={paginationFactory(
															options
														)}
														// rowEvents={
														// 	rowEvents
														// }
														// {...paginationTableProps}
													/>
												) : (
													<p className='card-trext'>
														No Records
													</p>
												)}
											</Modal.Body>
										</Modal>
										<Button
											onClick={handleShowWorkOrder}
											style={{ marginLeft: "10px" }}
										>
											Work Order
										</Button>
										<PopWorkOrder
											show={showWorkOrder}
											handleClose={handleCloseWorkOrder}
											item={userDetail}
										/>
									</Row>

									{/* <Row className='d-flex  justify-content-center align-items-center mb-2'>
										Id: {userDetail.id}{" "}
									</Row>
									<Row className='d-flex  justify-content-center align-items-center mb-2'>
										Source: {userDetail.source}{" "}
									</Row> */}
								</>
							)}
						</div>
						{/* </div> */}
						{/* </div> */}
					</div>
				</div>
			</div>

			<div className='row'>
				<div className='col-lg-12'>
					<h4>Consumed Vouchers</h4>

					<div className='card'>
						{data.length > 0 && Array.isArray(data) ? (
							data.map((item) => (
								<div className='card-body'>
									<div className='row'>
										<div className='col-xl col-lg  col-md col-xxl col-sm'>
											<div className='product-detail-content w-100'>
												<h4>{item.name}</h4>
												<p>
													Amount:{" "}
													{(
														item.amount / 100
													).toFixed(2)}
												</p>
												<p>
													Date:
													{moment(
														item.timestamp
													).format("DD-MM-YYYY")}
												</p>

												<hr />
											</div>
										</div>
										{/* </div> */}
									</div>
								</div>
							))
						) : (
							// ))
							// <div className='card'>
							<div className='card-body'>
								<div className='row'>
									<div className='col-xl col-lg  col-md col-xxl col-sm'>
										{/* <div className='product-detail-content'> */}
										<div className='pr d-flex justify-content-center align-items-center'>
											<h4>No Consumption Record</h4>
											<hr />
										</div>
										{/* </div> */}
										{/* </div> */}
									</div>
								</div>
							</div>
						)}
					</div>
				</div>
			</div>

			<div className='row mt-4'>
				<div className='col-lg-12'>
					<h4>Paid Vouchers</h4>

					<div className='card'>
						{paid.length > 0 && Array.isArray(paid) ? (
							paid.map((item) => (
								<div className='card-body'>
									<div className='row'>
										<div className='col-xl col-lg col-md col-xxl col-sm'>
											<div className='product-detail-content w-100'>
												{/* <div className='new-arrival-content pr'> */}
												<h4>
													{item.typename}
													{/* {item.content}) */}
												</h4>
												<p>
													Amount:{" "}
													{(item.money / 100).toFixed(
														2
													)}
												</p>
												<p>
													Date:<span> </span>{" "}
													{moment(
														item.timestamp
													).format("DD-MM-YYYY")}
												</p>
												<p>
													Buy number: {item.buynumber}
												</p>
												<p>
													Used number:{" "}
													{item.usednumber}
												</p>
												<Button
													onClick={() =>
														handleShowWorkOrder(
															item.id,
															item.userid
														)
													}
													style={{
														marginRight: "10px",
													}}
												>
													Work Order
												</Button>
												<PopWorkOrder
													show={showWorkOrder}
													handleClose={
														handleCloseWorkOrder
													}
													orderID={orderID}
													userID={userID}
												/>
												{item.usednumber == 0 ? (
													<Button
														onClick={() =>
															handleCancel(
																item.id
															)
														}
													>
														Cancel voucher
													</Button>
												) : (
													""
												)}
												<hr />
												{/* </div> */}
											</div>
										</div>
									</div>
								</div>
							))
						) : (
							// <div className='card'>
							<div className='card-body'>
								<div className='row'>
									<div className='col-xl col-lg  col-md col-xxl col-sm'>
										{/* <div className='product-detail-content'> */}
										<div className=' pr d-flex justify-content-center align-items-center'>
											<h4>No Paid Record</h4>
											<hr />
										</div>
										{/* </div> */}
									</div>
								</div>
								{/* </div> */}
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default ConsumptionDetail;
