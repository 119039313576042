import { Button, Card, Col, Form, ListGroup, Modal, Nav, Row, Tab, Table, Tabs } from 'react-bootstrap';
import {
	CityidgetRestaurant,
	CourseidgetCourse,
	DeleteCourse,
	DeleteCourserule,
	DeleteCoursetype,
	GetBeauty,
	GetBubbletea,
	GetCarRepairs,
	GetEntertainment,
	GetFitness,
	GetMassage,
	GetMovie,
	GetRestaurant,
	GetSupermarket,
	IdgetCourse,
	IdgetRestaurant,
	IdupCourserule,
	IdupRestaurant3,
	NewCourse,
	NewCourserule,
	NewCoursetype,
	ResidgetCourse,
	ResidgetCourserule,
	ResidgetCoursetype,
	UpCourse,
	UpCoursetype,
} from '../../api/api';
// import Table from '@material-ui/core/Table';
import { IconButton, Tooltip } from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import React, { useCallback, useEffect, useState } from 'react';

import BootstrapTable from 'react-bootstrap-table-next';
import Courses from './Courses';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

const tabData = [
	{
		name: 'Home',
		icon: 'home',
		content:
			'Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts. Separated they live in Bookmarksgrove.',
	},
	{
		name: 'Profile',
		icon: 'user',
		content:
			"Raw denim you probably haven't heard of them jean shorts Austin. Nesciunt tofu stumptown aliqua, retro synth master cleanse. Mustache cliche tempor.      ",
	},
	{
		name: 'Contact',
		icon: 'phone',
		content:
			'Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts. Separated they live in Bookmarksgrove.',
	},

	{
		name: 'Message',
		icon: 'envelope',
		content:
			"Raw denim you probably haven't heard of them jean shorts Austin. Nesciunt tofu stumptown aliqua, retro synth master cleanse. Mustache cliche tempor.      ",
	},
];
const CourseManagement = () => {
	//** Dynamic Key Single Place */
	const user = JSON.parse(localStorage.getItem('current_user') || '{}');
	const currentStore = JSON.parse(localStorage.getItem('current_store') || '{}');
	console.log('currentStore', currentStore);
	const history = useHistory();
	const [englishName, setEnglishName] = useState('');

	const [chineseName, setChineseName] = useState('');

	const [file, setFile] = useState('');
	const [businesses, setBusinesses] = useState([]);
	const [store, setStore] = useState('');
	const [coursestype, setCoursesType] = useState([]);
	const [courses, setCourses] = useState([]);
	const [servicetype, setServicetype] = useState('');
	const [storeName, setStoreName] = useState(currentStore.name);
	const [idCourse, setIdCourse] = useState();

	const [ruleName, setRuleName] = useState();
	const [maxChoice, setMaxChoice] = useState('1');
	const [option, setOption] = useState();
	const [description, setDescription] = useState();
	const [name, setName] = useState();
	const [type, setType] = useState();
	const [price, setPrice] = useState();
	const [price1, setPrice1] = useState();
	const [recommend, setRecommend] = useState();
	const [content, setContent] = useState('');
	const [pic1, setPic1] = useState('');
	const [pic2, setPic2] = useState('');
	const [pic3, setPic3] = useState('');
	const [pic4, setPic4] = useState('');
	const [pic5, setPic5] = useState('');
	const [pic6, setPic6] = useState('');
	const [order, setOrder] = useState('');

	useEffect(() => {
		handleIdGetCourses(JSON.stringify(currentStore));
	}, []);

	console.log(businesses);
	const handleGetRes = async () => {
		const getRestaurant = await GetRestaurant(user.access_token);
		setBusinesses(getRestaurant.data);
	};

	useEffect(() => {
		if (user.member_level === 255) {
			handleGetRes();
		} else {
			cityGetRes();
		}
	}, []);
	const cityGetRes = async () => {
		const storeData = await CityidgetRestaurant(user.access_token, user.cityid);
		setBusinesses(storeData.data);
	};

	//Handle Open Modal for edit course type
	const [showCourseType, setShowCourseType] = useState(false);
	const [courseTypeDetial, setCourseTypeDetial] = useState('');
	const handleCloseCourseType = () => setShowCourseType(false);
	const handleShowCourseType = (item) => {
		setShowCourseType(true);
		setCourseTypeDetial(item);
	};

	const handleNewCourseType = async () => {
		const newCourse = await NewCoursetype(user.access_token, store, servicetype, englishName, chineseName, file);
		console.log(newCourse);
		// if (data.ret === 200) {
		// 	alert("added");
		// } else {
		// 	alter("failed");
		// }
		window.location.reload();
	};

	const handleUpdateType = async (id) => {
		console.log(id);
		const data = await UpCoursetype(user.access_token, id, store, servicetype, englishName, chineseName, file);
		console.log(data);
		if (data.ret === 200) {
			alert('Updated');
		} else {
			alert('failed');
		}
		handleCloseCourseType();
		window.location.reload();
	};

	const handleDeleteType = async (id) => {
		const data = await DeleteCoursetype(user.access_token, id);
		handleCloseCourseType();
		window.location.reload();
	};
	const handleDeleteCourse = async (id) => {
		const data = await DeleteCourse(user.access_token, id);
		window.location.reload();
	};

	const handleDeleteCourserule = async (id) => {
		const data = await DeleteCourserule(user.access_token, id);
		window.location.reload();
	};
	const res = localStorage.getItem('savedRes');
	const [triggerUpdate, setTriggerUpdate] = useState(false);
	useEffect(() => {
		console.log('=> res', res);
		if (res) {
			// setStore(data.id);
			// setStoreName(data.name);
			const handleIdGetCourses = async () => {
				// console.log("=> item", item);
				// localStorage.setItem("savedRes", item);

				const data = JSON.parse(res);
				setStore(data.id);
				setStoreName(data.name);
				const idGetCoursesType = await ResidgetCoursetype(
					user.access_token,
					data.id,
					0 // servicetype
				);

				setCoursesType(idGetCoursesType.data);

				const idGetCourses = await ResidgetCourse(
					user.access_token,
					data.id,
					0 //servicetype
				);

				setCourses(idGetCourses.data);

				const idGetCoursesrule = await ResidgetCourserule(
					user.access_token,
					data.id,
					0 //servicetype
				);

				setIdCourse(idGetCoursesrule.data);
				// console.log(store);
			};
			handleIdGetCourses();
		}
	}, [triggerUpdate]);

	const handleIdGetCourses = async (item) => {
		console.log('=> item', item);
		localStorage.setItem('savedRes', item);

		const data = JSON.parse(item);
		setStore(data.id);
		setStoreName(data.name);
		const idGetCoursesType = await ResidgetCoursetype(user.access_token, data.id, servicetype);

		setCoursesType(idGetCoursesType.data);

		const idGetCourses = await ResidgetCourse(user.access_token, data.id, servicetype);

		setCourses(idGetCourses.data);

		const idGetCoursesrule = await ResidgetCourserule(user.access_token, data.id, servicetype);

		setIdCourse(idGetCoursesrule.data);
		// console.log(store);
	};

	const handleNewCourserule = () => {
		localStorage.setItem('coursestype', JSON.stringify(coursestype));
		history.push('./new-courserule');
	};
	const handleNewCourse = (item) => {
		localStorage.setItem('coursestype', JSON.stringify(item));
		history.push('./new-course');
	};
	const handleCourses = (item) => {
		localStorage.setItem('coursestype', JSON.stringify(item));

		const getCourses = courses.filter((i) => i.coursetypeid === item.id);
		console.log('=> courses', courses);
		localStorage.setItem('getCourses', JSON.stringify(getCourses));
		history.push('./courses');
	};
	// console.log("=> courses", courses);

	const handleUpdateCourse = async (item) => {
		console.log(item);
		const data = await UpCourse(
			user.access_token,
			item.id,
			item.resid,
			name ? name : item.name,
			type ? type : item.type,
			price ? price : item.price,
			price1 ? price1 : item.price1,
			recommend ? recommend : item.tuijian,
			content ? content : item.content,
			pic1 ? pic1 : item.pic1,
			pic2 ? pic2 : item.pic2,
			pic3 ? pic3 : item.pic3,
			pic4 ? pic4 : item.pic4,
			pic5 ? pic5 : item.pic5,
			pic6 ? pic6 : item.pic6,
			order ? order : item.order,
			item.servicetype,
			item.coursetypeid
		);
		console.log('🚀 ~ file: CourseManagement.js ~ line 207 ~ handleUpdateCourse ~ data', data);
	};

	const handleUpdate = async (item) => {
		console.log(item);
		const data = await IdupCourserule(
			user.access_token,
			item.id,
			name ? name : item.name,
			item.resid,
			item.servicetype,
			item.courseid,
			item.coursetypeid,
			maxChoice ? maxChoice : item.maximumchoice,
			option ? option : item.options,
			description ? description : item.description
		);
		console.log('🚀 ~ file: CourseManagement.js ~ line 207 ~ handleUpdateCourse ~ data', data);
	};

	//handle discount
	const [discount, setDiscount] = useState({
		discounttime1: '',
		discountprecent1: '',
		discounttime2: '',
		discountprecent2: '',
		discounttime3: '',
		discountprecent3: '',
	});
	// console.log('=> discount', discount);

	const handleChangeDiscount = (e) => {
		setDiscount({
			...discount,
			[e.target.name]: e.target.value,
		});
	};
	const handleDiscount = async () => {
		const data = await IdupRestaurant3(
			user.access_token,
			store,
			discount.discounttime1,
			discount.discountprecent1,
			discount.discounttime2,
			discount.discountprecent2,
			discount.discounttime3,
			discount.discountprecent3
		);
		console.log('=> data', data);
	};

	console.log(courses);

	const [showCourseList, setShowCourseList] = useState(false);
	const [courseList, setCourseList] = useState(false);

	const handleOpenCourseList = (item) => {
		const getCourses = courses.filter((i) => i.coursetypeid === item.id);
		setShowCourseList(true);
		setCourseList(getCourses);
	};
	const handleCloseCourseList = () => {
		console.log('aaaaa');

		setShowCourseList(false);
	};

	return (
		<div className='h-80'>
			<div className='row'>
				<div className='col-lg-12'>
					<div className='card'>
						<div className='card-body '>
							{user.resid ? (
								<div className='d-flex form-row justify-content-center align-items-center'>
									<h3>{currentStore?.name}</h3>
								</div>
							) : (
								<>
									<label htmlFor='state'>Select Business(选择商家)</label>

									<Form.Control
										as='select'
										// custom
										onChange={(e) => handleIdGetCourses(e.target.value)}>
										<option></option>
										{Array.isArray(businesses) &&
											businesses.length > 0 &&
											businesses.map((item) => (
												<option key={item.id} value={JSON.stringify(item)}>
													{item.name}
												</option>
											))}
									</Form.Control>
								</>
							)}
						</div>
					</div>
				</div>
			</div>
			<>
				<Row>
					<Col xl={6}>
						<Card>
							<Card.Header>
								<Card.Title>{storeName}</Card.Title>
							</Card.Header>
							<Card.Body>
								{/* <!-- Nav tabs --> */}
								<div className='default-tab'>
									<Tab.Container defaultActiveKey='course_type'>
										<Nav as='ul' className='nav-tabs'>
											<Nav.Item as='li'>
												<Nav.Link eventKey='course_type'>Course Type(菜品分类)</Nav.Link>
											</Nav.Item>
											{/* TODO: */}
											{/* <Nav.Item as='li'>
												<Nav.Link eventKey='discount'>Discount(添加折扣)</Nav.Link>
											</Nav.Item> */}
										</Nav>
										<Tab.Content className='pt-4'>
											<Tab.Pane eventKey='course_type'>
												{storeName && storeName.length > 0 && (
													<>
														<h3> 添加菜品分类</h3>

														<div className='row m-6 p-4'>
															<label htmlFor='english-name'>English Name</label>
															<input
																type='text'
																label='english-name'
																className='form-control'
																name='english-name'
																placeholder='English Name'
																onChange={(e) => setEnglishName(e.target.value)}
															/>
															<label htmlFor='chinese-name'>中文名</label>
															<input
																type='text'
																label='chinese-name'
																className='form-control'
																name='chinese-name'
																placeholder='chinese Name'
																onChange={(e) => setChineseName(e.target.value)}
															/>

															{/* <label htmlFor='pic'>Picture</label>
																		<input
																			type='file'
																			label='pic'
																			className='form-control'
																			onChange={(e) => setFile(e.target.files[0])}
																			// defaultValue="pic"
																			name='pic'
																			placeholder='pic'
																		/> */}

															<div className='d-flex  justify-content-center align-items-center mb-2 mt-2'>
																<Button variant='primary' onClick={handleNewCourseType}>
																	Add(添加菜品分类)
																</Button>
															</div>
														</div>

														<Table striped>
															<thead>
																<tr>
																	<th>No.</th>
																	<th>English Name(分类英文)</th>
																	<th>CN Name(分类中文)</th>
																	<th>Settings</th>
																</tr>
															</thead>
															{coursestype &&
																coursestype.length > 0 &&
																coursestype.map((item, index) => (
																	<tbody key={item + index}>
																		<tr>
																			<td
																				className='cursor-pointer'
																				onClick={() =>
																					handleOpenCourseList(item)
																				}>
																				{index + 1}
																			</td>
																			<td
																				className='cursor-pointer'
																				onClick={() =>
																					handleOpenCourseList(item)
																				}>
																				{item.enname}
																			</td>
																			<td
																				className='cursor-pointer'
																				onClick={() =>
																					handleOpenCourseList(item)
																				}>
																				{item.cnname}
																			</td>

																			<td>
																				<Tooltip title='Edit'>
																					<IconButton
																						onClick={() =>
																							handleShowCourseType(item)
																						}>
																						<i className='fa fa-pencil-square-o' />
																					</IconButton>
																				</Tooltip>

																				<Modal
																					show={showCourseType}
																					onHide={handleCloseCourseType}>
																					<Modal.Header closeButton>
																						<Modal.Title>
																							Edit Course
																							Type(编辑菜品分类)
																						</Modal.Title>
																					</Modal.Header>
																					<Modal.Body>
																						<form>
																							<div className='row w-100'>
																								<div
																									className='form-group row'
																									style={{
																										margin: '0px 10px',
																									}}>
																									<label htmlFor='english-name'>
																										English
																										Name(分类英文)
																									</label>
																									<input
																										type='text'
																										label='english-name'
																										className='form-control '
																										name='english-name'
																										defaultValue={
																											courseTypeDetial.enname
																										}
																										// placeholder='English Name'
																										onChange={(e) =>
																											setEnglishName(
																												e.target
																													.value
																											)
																										}
																									/>
																								</div>
																								<div
																									className='form-group row'
																									style={{
																										margin: '0px 10px',
																									}}>
																									<label htmlFor='chinese-name'>
																										CN
																										Name(分类中文)
																									</label>
																									<input
																										type='text'
																										label='chinese-name'
																										className='form-control '
																										defaultValue={
																											courseTypeDetial.cnname
																										}
																										name='chinese-name'
																										// placeholder='chinese Name'
																										onChange={(e) =>
																											setChineseName(
																												e.target
																													.value
																											)
																										}
																									/>
																								</div>
																								{/* <div
																			class='form-group row'
																			style={{
																				margin: '0px 10px',
																			}}>
																			<label htmlFor='pic'>Picture(图片)</label>
																			<input
																				type='file'
																				label='pic'
																				className='form-control'
																				onChange={(e) =>
																					setFile(e.target.files[0])
																				}
																				// defaultValue="pic"
																				name='pic'
																				placeholder='pic'
																			/>
																		</div> */}
																							</div>

																							<div className='d-flex form-row justify-content-center align-items-center mb-2 mt-2'></div>
																						</form>
																					</Modal.Body>
																					<Modal.Footer>
																						<Button
																							variant='primary'
																							onClick={() =>
																								handleUpdateType(
																									courseTypeDetial.id
																								)
																							}
																							style={{
																								margin: '10px',
																								zIndex: '100',
																							}}>
																							Update(更新)
																						</Button>

																						<Button
																							variant='primary'
																							onClick={() =>
																								handleDeleteType(
																									courseTypeDetial.id
																								)
																							}
																							style={{
																								margin: '10px',
																							}}>
																							Delete(删除)
																						</Button>
																					</Modal.Footer>
																				</Modal>
																				<Button
																					variant='primary'
																					onClick={() =>
																						handleNewCourse(item)
																					}
																					style={{ margin: '5px' }}>
																					<i
																						className='fa fa-plus-circle mr-1'
																						aria-hidden='true'
																					/>
																					Add Course (添加菜品)
																				</Button>
																				{/* <Button
															variant='primary'
															onClick={() => handleOpenCourseList(item)}
															// style={{ margin: "10px" }}
														>
															All Courses (查看菜品)
														</Button> */}
																			</td>
																		</tr>
																	</tbody>
																))}
														</Table>
													</>
												)}
												<Courses
													showCourseList={showCourseList}
													handleCloseCourseList={handleCloseCourseList}
													courseList={courseList}
													triggerUpdate={triggerUpdate}
													setTriggerUpdate={setTriggerUpdate}
												/>
											</Tab.Pane>
											<Tab.Pane eventKey='discount'>
												{user.member_level >= 200 && (
													<>
														<h3> 添加折扣</h3>

														<div className='row m-6 p-4'>
															<label htmlFor='discounttime1'>discounttime1 </label>
															<input
																type='text'
																label='discounttime1'
																className='form-control'
																name='discounttime1'
																placeholder='第一种折扣消费次数'
																onChange={handleChangeDiscount}
															/>
															<label htmlFor='discountprecent1'>discountprecent1</label>
															<input
																type='text'
																label='discountprecent1'
																className='form-control'
																name='discountprecent1'
																placeholder='第一次折扣百分比'
																onChange={handleChangeDiscount}
															/>
															<label htmlFor='discounttime2'>discounttime2 </label>
															<input
																type='text'
																label='discounttime2'
																className='form-control'
																name='discounttime2'
																placeholder='第二种折扣消费次数'
																onChange={handleChangeDiscount}
															/>
															<label htmlFor='discountprecent2'>discountprecent2</label>
															<input
																type='text'
																label='discountprecent2'
																className='form-control'
																name='discountprecent2'
																placeholder='第二次折扣百分比'
																onChange={handleChangeDiscount}
															/>
															<label htmlFor='discounttime3'>discounttime3 </label>
															<input
																type='text'
																label='discounttime3'
																className='form-control'
																name='discounttime3'
																placeholder='第三种折扣消费次数'
																onChange={handleChangeDiscount}
															/>
															<label htmlFor='discountprecent3'>discountprecent3</label>
															<input
																type='text'
																label='discountprecent3'
																className='form-control'
																name='discountprecent3'
																placeholder='第三次折扣百分比'
																onChange={handleChangeDiscount}
															/>

															<div className='d-flex  justify-content-center align-items-center mb-2 mt-2'>
																<Button variant='primary' onClick={handleDiscount}>
																	Add(添加折扣)
																</Button>
															</div>
														</div>
													</>
												)}
											</Tab.Pane>
										</Tab.Content>
									</Tab.Container>
								</div>
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</>
		</div>
	);
};

export default CourseManagement;
