import { Button, Spinner, Table, TableCell } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import React, { useCallback, useEffect, useState } from "react";

import { GetUserfeedback } from "../../api/api";
import PopWorkOrder from "../Utilization/PopWorkOrder";

const Feedback = () => {
	const [feedback, setFeedback] = useState([]);
	const [showWorkOrder, setShowWorkOrder] = useState(false);

	const [FeedbackID, setFeedbackID] = useState();
	const [userID, setUserID] = useState();
	const [feedbackContent, setFeedbackContent] = useState();

	const user = JSON.parse(localStorage.getItem("current_user") || "{}");

	useEffect(() => {
		const getFeedback = async () => {
			const data = await GetUserfeedback(user.access_token);
			console.log(data.data);
			setFeedback(data.data);
		};
		getFeedback();
	}, []);
	const history = useHistory();

	const handleOnClick = useCallback(
		(item) => {
			// console.log("click", item);
			history.push({
				pathname: `/consumption-detail/`,
				data: { id: item.userid },
			});
			// console.log("aaaa", item);
		},
		[history]
	);

	const handleCloseWorkOrder = () => setShowWorkOrder(false);
	const handleShowWorkOrder = (e, feedbackid, userid, content) => {
		e.stopPropagation();
		setShowWorkOrder(true);
		setFeedbackID(feedbackid);
		setUserID(userid);
		setFeedbackContent(content);
	};

	return (
		<div className='col-xl align-self-start'>
			<div className='row'>
				<div className='col-lg-12'>
					<div className='card'>
						<div className='card-body'>
							{/* <h5 class='card-title'>Restauarnt</h5> */}

							<Table
								className='table-hover'
								style={{ display: "block", overflow: "auto" }}
							>
								<tr>
									<th style={{ width: "5%" }}>ID</th>
									<th style={{ width: "10%" }}>User ID</th>
									<th style={{ width: "60%" }}>Content</th>
									<th style={{ width: "10%" }}>Time</th>
									<th style={{ width: "5%" }}>emotion</th>
									<th style={{ width: "10%" }}>Work Order</th>
								</tr>
								{Array.isArray(feedback) &&
								feedback.length > 0 ? (
									feedback.map((item, i) => (
										<tr className='cursor-pointer '>
											<td>{i + 1}</td>
											<td
												onClick={() =>
													handleOnClick(item)
												}
											>
												{item.userid}
											</td>
											<td>
												<p className='card-trext'>
													{item.content}
												</p>
											</td>
											<td>{item.timestamp}</td>
											<td>{item.emotion}</td>
											<td>
												<Button
													onClick={(e) =>
														handleShowWorkOrder(
															e,
															item.id,
															item.userid,
															item.content
														)
													}
												>
													Work Order
												</Button>
												<PopWorkOrder
													show={showWorkOrder}
													handleClose={
														handleCloseWorkOrder
													}
													feedbackID={FeedbackID}
													userID={userID}
													feedbackContent={
														feedbackContent
													}
												/>
											</td>
										</tr>
									))
								) : (
									<p className='card-trext'>No Feedback</p>
								)}
							</Table>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Feedback;
